import { createSlice } from "@reduxjs/toolkit";

const ExtractEquipmentSlice = createSlice({
    name: "extractEquipments",
    initialState: {
        data: []
    },
    reducers: {
        setData: (state, action) => {
            let tmp = action.payload.map((item, index) => {
              return { id: index, ...item };
            });
            state.data = tmp;
        }
    }
});

export const { setData } = ExtractEquipmentSlice.actions;
export default ExtractEquipmentSlice.reducer;