import { lazy } from "react";
import ProtectedRoute from "../components/login/protected-route";
import { Navigate } from "react-router-dom";

// project imports
import HomePage from "../pages/home";
// const HomePage = lazy(() => import("../pages/home"));
const Solutions = lazy(() => import("../pages/solutions"));
const Contact = lazy(() => import("../pages/contact"));
const QuiSommesNous = lazy(() => import("../pages/qui-sommes-nous"));
const Whatsnext = lazy(() => import("../pages/whats-next"));

const MainRoutes = {
  path: "/",
  element: "",
  children: [
    {
      path: "/",
      element: (
        <ProtectedRoute roles={["baseauth"]}>
          <HomePage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/solutions",
      element: (
        <ProtectedRoute roles={["baseauth"]}>
          <Solutions />
        </ProtectedRoute>
      ),
    },
    {
      path: "/contact",
      element: (
        <ProtectedRoute roles={["baseauth"]}>
          <Contact />
        </ProtectedRoute>
      ),
    },
    {
      path: "/qui_sommes_nous",
      element: (
        <ProtectedRoute roles={["baseauth"]}>
          <QuiSommesNous />
        </ProtectedRoute>
      ),
    },
    { 
      path: "/cest_quoi_la_suite",
      element: (
        <ProtectedRoute roles={["baseauth"]}>
          <Whatsnext />
        </ProtectedRoute>
      ),
    },
    { 
      path: "/cest_quoi_la_suite/:project",
      element: (
        <ProtectedRoute roles={["baseauth"]}>
          <Whatsnext />
        </ProtectedRoute>
      ),
    },
    {
      path: "/services",
      element: (
        <Navigate to="/#services" />
      ),
    },
  ],
};
export default MainRoutes;
