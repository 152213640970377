import React, { useEffect,useState } from "react";
import FilesDisplayList from "./files-display-list";
import FileListWindow from "./file-list-window";
import { useDispatch, useSelector } from "react-redux";
import AddFileModal from './add-file-modal'
import DeleteFileModal from './delete-file-modal'
import config from "../../../../config";
import {  useNavigate, Navigate, useOutletContext } from "react-router-dom";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";
import { updateLightsFiles } from "../../../../store/features/PYM/GeoSlice";
import {Divider} from "@nextui-org/divider";

export default function FileIesLdt() {
  const [isLoading, setIsLoading, loadingMessage, setLoadingMessage] = useOutletContext();
  const { launch_request } = useAzureFuncApp();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const projectInfos = useSelector((state) => state.PYM.ProjectInfos);
  const projectGeoData = useSelector((state) => state.PYM.projectGeoData);

  const [fileList, setFileList] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState(undefined);
  const [wantDeleteFile, setWantDeleteFile] = React.useState(undefined);

  const [showModal,setShowModal] = useState(false);
  const [showDeleteModal,setShowDeleteModal] = useState(false);

  useEffect(() => {
    getLightFiles().then((result) => {
      if (result !== undefined) {
        setFileList(result)
      }
    } 
  );
  }, [])
        
  async function handleConfirmModal(needMap,fileName,blobPath) {
    if(needMap){
      navigate('/solux-map/accueil', {
        state: { needPlot: true, plotPath:blobPath }
      });
      }
    else{
      const response = await fetch(blobPath);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${fileName}`
      link.click();
    }
  }

  const handleAddFile = (event) => {
    setShowModal(true);
  }

  const handleDeleteFile = (item) => {
    setWantDeleteFile(item);
    setShowDeleteModal(true);
  }

  const handleDeleteFileInPreview = (item) => {
    setWantDeleteFile(selectedFile);
    setShowDeleteModal(true);
  }


  const handleNotDeleteFile = (event) => {
    setShowDeleteModal(false);
  }


  const handleFileSelected = (event) => {
    let selectedId = event.target.dataset.key;
    let selectedFileById = fileList.find(x => x.id.toString() === selectedId)
    setSelectedFile(selectedFileById);
  }

  async function getLightFiles() {
    const payload = {
      functionName: "getLightFiles",
      projectPath: projectInfos.project_path || "",
    };

    setLoadingMessage("Récupération des fichiers d'éclairage...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
    setIsLoading(false);
    if (status_code === 200) {
      return result.filesList;
    }
  }



  async function addFilesInList(filenames) {
    const payload = {
      functionName: "addLightFiles",
      projectPath: projectInfos.project_path || "",
      blobPaths: filenames,
    };

    setLoadingMessage("Récupération des fichiers d'éclairage...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
    setIsLoading(false);
    if (status_code === 200) {
      let files = result.filesList;
      if (selectedFile !== undefined) {
        setSelectedFile(undefined);
      }

      setFileList(fl => [...fl, ...files]);
      dispatch(updateLightsFiles([...fileList,...files].map(x=> x.filename)));
    }
  }

  async function deleteFile(item) {
    let blobPath = item.blobPath
    const payload = {
      functionName: "deleteLightFile",
      projectPath: projectInfos.project_path || "",
      blobPath: blobPath,
    };

    setLoadingMessage("Suppression du fichier...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
    setIsLoading(false);
    if (status_code === 200) {
      if (selectedFile !== undefined && selectedFile.blobPath === blobPath) {
        setSelectedFile(undefined);
      }

      setFileList(fl => [...fl].filter(x => x.blobPath !== blobPath));
      dispatch(updateLightsFiles(fileList.filter(x => x.blobPath !== blobPath).map(x => x.filename)));

    }

    setShowDeleteModal(false);
  }

  return (
    <>
        <div className="lg:col-span-6 xl:col-span-4 flex flex-col py-4 max-w-[20%]">
          <FilesDisplayList
            fileList={fileList}
            
            onAddFile={handleAddFile}
            onDeleteFile={handleDeleteFile}
            onFileSelected={handleFileSelected} 
          />
          <DeleteFileModal isOpen = {showDeleteModal} onConfirm = {deleteFile}  onDecline={handleNotDeleteFile} itemToDelete={wantDeleteFile} />

        </div>
        <Divider orientation="vertical"/>
        <div className="lg:col-span-6 xl:col-span-5 flex flex-col w-full h-full p-2">
          <FileListWindow
            selectedFile={selectedFile}
            deleteFile={handleDeleteFileInPreview}
          />
        </div>
      <AddFileModal 
        isOpen={showModal}
        onConfirm = {handleConfirmModal}
        onDecline={()=>{setShowModal(false);}}
        blobPath={`${projectInfos.project_path}/inputs/light_files/`}
        addFilesInList={addFilesInList}/>
    </>
  )
}