// https://mokole.com/palette.html

const Colors = [
    {
        name: "forestgreen",
        value: "#228b22"
    },
    {
        name: "maroon2",
        value: "#7f0000"
    },
    {
        name: "midnightblue",
        value: "#191970"
    },
    {
        name: "olive",
        value: "#808000"
    },
    {
        name: "darkcyan",
        value: "#008b8b"
    },
    {
        name: "peru",
        value: "#cd853f"
    },
    {
        name: "yellowgreen",
        value: "#9acd32"
    },
    {
        name: "darkseagreen",
        value: "#8fbc8f"
    },
    {
        name: "purple",
        value: "#800080"
    },
    {
        name: "maroon3",
        value: "#b03060"
    },
    {
        name: "red",
        value: "#ff0000"
    },
    {
        name: "darkorange",
        value: "#ff8c00"
    },
    {
        name: "dimgray",
        value: "#696969"
    },
    {
        name: "gold",
        value: "#ffd700"
    },
    {
        name: "lawngreen",
        value: "#7cfc00"
    },
    {
        name: "springgreen",
        value: "#00ff7f"
    },
    {
        name: "crimson",
        value: "#dc143c"
    },
    {
        name: "aqua",
        value: "#00ffff"
    },
    {
        name: "deepskyblue",
        value: "#00bfff"
    },
    {
        name: "blue",
        value: "#0000ff"
    },
    {
        name: "purple3",
        value: "#a020f0"
    },
    {
        name: "lightcoral",
        value: "#f08080"
    },
    {
        name: "orchid",
        value: "#da70d6"
    },
    {
        name: "thistle",
        value: "#d8bfd8"
    },
    {
        name: "dodgerblue",
        value: "#1e90ff"
    },
    {
        name: "khaki",
        value: "#f0e68c"
    },
    {
        name: "lightgreen",
        value: "#90ee90"
    },
    {
        name: "deeppink",
        value: "#ff1493"
    },
    {
        name: "mediumslateblue",
        value: "#7b68ee"
    },
    {
        name: "black",
        value: "#000000"
    }
];

export const LightColors = [
    { name: "Light Blue", value: "#aecbeb" },
    { name: "Peach", value: "#f1c9a6" },
    { name: "Light Green", value: "#9fcd93" },
    { name: "Light Goldenrod", value: "#ddc670" },
    { name: "Light Steel Blue", value: "#abc1f1" },
    { name: "Light Cyan", value: "#b5dad0" },
    { name: "Light Sea Green", value: "#8ebdba" },
    { name: "Light Khaki", value: "#d8ddb0" },
    { name: "Light Aquamarine", value: "#93d8cf" },
    { name: "Light Orchid", value: "#cba3dc" },
    { name: "Light Sky Blue", value: "#c4dbf2" },
    { name: "Light Turquoise", value: "#96cbd0" },
    { name: "Light Pink", value: "#e19dd3" },
    { name: "Light Coral", value: "#e29775" },
    { name: "Light Olive", value: "#c9c8a5" },
    { name: "Light Periwinkle", value: "#d3d9e9" },
    { name: "Light Tan", value: "#e1b76a" },
    { name: "Light Brown", value: "#d9a585" },
    { name: "Light Rose", value: "#f3c5dc" },
    { name: "Light Mint", value: "#9fd2c2" }
];

export default Colors;