"use client";

import React, { useState } from 'react';
import { useEffect } from 'react';
import { Divider, Spacer } from '@nextui-org/react';

// Components
import WidgetNavigator from "../../../components/widgets/navigator";
import { ContactWidget, FeedbackWidget, HelpWidget, HomeWidget, SettingsWidget } from "../../../components/widgets/widgets";

// Hooks
import useTicket from './api/hooks/useTicket';
import useBPU from './api/hooks/useBPU';

import UploadButton from './UploadButton';
import ArticleSelection from './ArticleSelection';
import ReportEditor from './ReportEditor';
import TicketForm from './TicketForm';

export default function Intelia() {

    useEffect(() => {
        document.title = 'Diane - Intelia';
    }, []);

    const {
        ticketForm,
        setTicketForm,
        getTicket,
        isGettingTicket,

        ticket,
        setTicket,
        analyseTicket,
        isAnalyzingTicket,
        
        ticketAnalysis,
    } = useTicket();
    
    const {
        bpu,
        updateBPU,
        getBPU,
        isUpdatingBPU
    } = useBPU();



    const handleExcelExport = (file) => {
        if (!file) return;
        console.log("File: ", file);
        updateBPU(file);
    }

    return (
        <div className='flex flex-col w-full h-full p-4 items-center overflow-y-auto'>
            <div className="relative flex flex-col w-full max-w-[80%] gap-6 p-6 rounded-lg shadow-lg bg-white">
                <div className="flex items-center flex-col">
                    <h1 className="text-4xl font-bold text-center">IntelIA</h1>
                    <div className='absolute top-6 right-7'>
                        <UploadButton handleExcelExport={handleExcelExport} isLoading={isUpdatingBPU}/>
                    </div>
                </div>
                <Divider y={2} />
                <div className="flex flex-col gap-4 p-4 rounded-lg shadow-lg border">
                    <TicketForm 
                        ticketForm={ticketForm}
                        setTicketForm={setTicketForm}
                        getTicket={getTicket}
                        getBPU={getBPU}
                        isGettingTicket={isGettingTicket}  
                    />
                </div>
                {ticket &&
                    <div className="flex flex-col gap-4 p-4 rounded-lg shadow-lg border">
                        <ReportEditor ticket={ticket} setTicket={setTicket} analyseTicket={analyseTicket} isAnalyzingTicket={isAnalyzingTicket} />
                    </div>
                }
                {ticketAnalysis && (
                    <div className="flex flex-col gap-4 p-4 rounded-lg shadow-lg border">
                        <ArticleSelection ticketAnalysis={ticketAnalysis} bpu={bpu} />
                    </div>
                )}

            </div>
            <WidgetNavigator>
                {/* <SettingsWidget /> */}
                <ContactWidget />
                <HomeWidget />
                <FeedbackWidget />
                <HelpWidget tutoVideoUrl={undefined} />
            </WidgetNavigator>
        </div>
    );
}
