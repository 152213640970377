import React from "react";
import {
  ScrollShadow,
  Listbox,
  ListboxItem,
  Input,
} from "@nextui-org/react";
import {Icon} from "@iconify/react";

import {cn} from "../../../../utils/cn";

const RoadDisplayList = React.forwardRef(({page,folderSelected, selectedKey, setSelectedKey,folderList, ...props}, ref) => {
  const [filterValue, setFilterValue] = React.useState("");
  const hasSearchFilter = Boolean(filterValue);
  const filteredItems = React.useMemo(() => {
    let filteredFolderList = [...folderList];
    if (hasSearchFilter) {
      filteredFolderList = filteredFolderList.filter((folder) =>
      folder.name.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }
    // folderList.map((folder, index) => ({id: folder.id, label: folder.name, value: folder}));
    return filteredFolderList;
  }, [folderList,filterValue]);
  
  // console.log('folderSelected',folderSelected)
  // const [selectedKeys, setSelectedKeys] = React.useState(folderSelected.id);
  // console.log('selectedKeys',selectedKeys)

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setFilterValue(value);
    } else {
      setFilterValue("");
    }
  }, []);

  return (
    <div ref={ref} {...props}>
        {/* <FileExplorerHeader className="hidden sm:flex" page={page} /> */}
        <div className="mb-6 flex flex-col gap-4 px-3 sm:px-6">
          <div>
              <Input
                isClearable
                aria-label="Search"
                labelPlacement="outside"
                placeholder="Rechercher..."
                radius="md"
                startContent={
                  <Icon
                    className="text-default-500 [&>g]:stroke-[2px]"
                    icon="solar:magnifer-linear"
                    width={18}
                  />
                }
                variant="bordered"
                value={filterValue}
              onClear={() => setFilterValue("")}
              onValueChange={onSearchChange}
              />
          </div>
        </div>
        <ScrollShadow hideScrollBar  className="flex h-full max-h-[calc(100vh-196px)] flex-col gap-6 overflow-y-auto px-3">
          <Listbox
            classNames={{
              base: "p-0",
            }}
            // items={filteredItems}
            variant="flat"
            emptyContent={"Aucun fichier disponible."}
            selectionMode="single"
            selectedKeys={[selectedKey]}
            onSelectionChange={(keys) =>{
              if (Array.from(keys).length > 0) {
                setSelectedKey(Array.from(keys)[0]);
              }
            }}
            hideSelectedIcon
          >
            {filteredItems.map((item) => (
              <ListboxItem
                key={item.id}
                className={cn("mb-2 px-4", {
                  "!bg-default-100": item.id == selectedKey,
                })}
                textValue={item.name}
              >
                <div className="flex items-center gap-2 py-1">
                  <div className="ml-2 min-w-0 flex-1">
                    <div className="text-small font-semibold text-default-foreground">
                      {item.name}
                    </div>
                  </div>
                </div>
              </ListboxItem>
            ))}
          </Listbox>
        </ScrollShadow>
    </div>
  );
});

RoadDisplayList.displayName = "RoadDisplayList";

export default RoadDisplayList;
