import React from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  ScrollShadow,
} from "@nextui-org/react";
import { Icon } from "@iconify/react";

import FileExplorerHeader from "./file-explorer-header";
import FileItem from "./file-list-item";
import { cn } from "../../../../utils/cn";

import JSZip from "jszip";

const FileListWindow = React.forwardRef(
  ({ folderSelected, handleImageClick,...props }, ref) => {

    async function downloadFolder() {
      const zip = new JSZip();
      let filesToDownload = folderSelected[0].files_path;
      let filesNamesToDownload = folderSelected[0].files_name;
      let zipName = folderSelected[0].name;

      for (var i = 0; i < filesToDownload.length; i++) {
        const response = await fetch(filesToDownload[i]);
        const blob = await response.blob();

        zip.file(filesNamesToDownload[i], blob);
      }
      const zipData = await zip.generateAsync({
        type: "blob",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(zipData);
      link.download = `${zipName}.zip`
      link.click();
    }
    async function deleteFolder(){
    };
    
    function handleDropDownAction(key) {
      switch (key) {
        case 'download':
          return downloadFolder();
        case 'delete':
          return deleteFolder();
        default:
          return;
      }
    }

    return (
      <div ref={ref} {...props}>

        <div className="w-full h-full flex flex-col sm:border-default-200 lg:border-l-small xl:border-r-small overflow-auto">
          <FileExplorerHeader className="hidden sm:flex lg:hidden" />
          <div className="h-17 flex items-center gap-2 border-y-small border-default-200 p-3 sm:p-4 lg:border-t-0">
            <div className="w-full">
              <div className="text-small font-semibold">{folderSelected.length === 0 ? "Rendus" : folderSelected[0].name}</div>
            </div>
            <div className="flex-end flex cursor-pointer">
              <Dropdown placement="bottom-end">
                <DropdownTrigger>
                  <Button isIconOnly className="min-w-6 text-default-500" variant="light">
                    <Icon icon="solar:menu-dots-bold" width={24} />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  onAction={(key) => { handleDropDownAction(key) }}
                >
                  <DropdownItem key="download">
                    Télecharger
                  </DropdownItem>
                  {/* <DropdownItem key="archiver">Archiver</DropdownItem>
                  <DropdownItem key="delete" className="text-danger">
                    Supprimer
                  </DropdownItem> */}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="flex w-full overflow-visible">
            <ScrollShadow className="flex max-h-[calc(100vh-220px)] flex-col gap-6 px-6 py-4 lg:max-h-[calc(100vh-162px)]">
            </ScrollShadow>
          </div>

          <ScrollShadow hideScrollBar >
            <div className="mx-2 mt-auto flex flex-col">
              <div className={cn("my-auto grid max-w-7xl grid-cols-1 gap-5 p-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5")}>
                {folderSelected.length !== 0 && folderSelected.map((folder) => (
                  folder.files_name.map((fileName, index) => {
                    return <FileItem key={fileName} name={fileName} imageSrc={folder.files_path[index]} handleImageClick={handleImageClick} />;
                  })
                )).flat()}
              </div>
            </div>
          </ScrollShadow>
        </div>
      </div>
    );
  },
);

FileListWindow.displayName = "FileListWindow";

export default FileListWindow;
