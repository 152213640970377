const ComingSoon = ({ ...otherProps }) => {
    return (
        <svg
            version="1.1"
            id="svg2"
            width= "1em"
            height="1em"
            viewBox="0 0 2933.3333 2933.3333"
            xmlns="http://www.w3.org/2000/svg"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            aria-hidden="true"
            role="presentation"
            {...otherProps}
        >
       <g
          id="g8"
          transform="matrix(1.3333333,0,0,-1.3333333,0,2933.3333)">
         <g
            id="g10"
            transform="scale(0.1)">
           <path
              d="m 11000,2258.6 c -4080.62,0 -7400.21,3319.6 -7400.21,7400.2 0,4080.5 3319.59,7400.2 7400.21,7400.2 4080.6,0 7400.2,-3319.7 7400.2,-7400.2 0,-4080.6 -3319.6,-7400.2 -7400.2,-7400.2 z M 4375.88,15032.9 c -47.13,46.3 -73.21,108.8 -73.21,176.1 0,67.2 26.08,129.7 75.09,178.3 l 800.92,803.9 c 99.29,99 260.48,99 358.51,0.6 l 336.99,-338.9 C 5452.16,15503 5067.57,15110 4724.49,14682.3 Z M 10034.8,18346 h 1930.4 v -703.4 c -316.7,38 -638.3,59.9 -965.2,59.9 -326.9,0 -648.5,-21.9 -965.2,-59.9 z m -643.54,1608.8 c 0,177.3 144.22,321.7 321.75,321.7 H 12287 c 177.5,0 321.7,-144.4 321.7,-321.7 v -643.5 c 0,-177.4 -144.2,-321.8 -321.7,-321.8 H 9713.01 c -177.53,0 -321.75,144.4 -321.75,321.8 z m 7096.64,-3763.6 c 99,98.6 259.6,99.1 359.2,0 l 804.4,-804.5 c 46.8,-46.5 72.2,-109.7 72.2,-177.7 0,-68.1 -25.4,-131.2 -72.2,-177.9 l -364,-364.1 c -342.3,428.9 -726.3,822.9 -1147.9,1173.9 z m 2555.8,-6532.4 c 0,1659.3 -505.2,3202.7 -1369.6,4484.9 l 432.3,432.3 c 168.1,168.3 260.8,392.9 260.8,633 0,240 -92.7,464.7 -260.8,632.8 l -804.3,804.4 c -350.1,349.7 -919.1,350 -1269.8,-0.7 l -408.5,-410.8 c -889.9,627.6 -1911.1,1081 -3015.1,1306 v 864.6 c 373.8,133.2 643.5,487 643.5,906 v 643.5 c 0,532.2 -432.9,965.2 -965.2,965.2 H 9713.01 c -532.27,0 -965.25,-433 -965.25,-965.2 v -643.5 c 0,-419 269.79,-772.8 643.5,-906 v -864.6 C 8293.57,17316.9 7277.67,16867.5 6391.2,16245.4 l -398.41,400.8 c -350.34,349.8 -919.68,350 -1269.4,-0.5 l -799.34,-802.2 c -170.62,-168.4 -264.88,-393.7 -264.88,-634.5 0,-240.9 94.26,-466.2 262.68,-632.2 l 414.83,-417.1 C 3465.7,12874.4 2956.29,11325 2956.29,9658.8 c 0,-4435.3 3608.36,-8043.7 8043.71,-8043.7 4435.4,0 8043.7,3608.4 8043.7,8043.7"
              id="path14" />
           <path
              d="m 7840.02,1391.1 c -3402.24,1300.2 -5688.1,4622.6 -5688.1,8267.7 0,88.9 -71.95,160.9 -160.88,160.9 -88.92,0 -160.87,-72 -160.87,-160.9 0,-3777.4 2369.12,-7220.8 5894.85,-8568.1 18.85,-7.2 38.33,-10.7 57.5,-10.7 64.73,0 125.68,39.3 150.19,103.4 31.73,82.9 -9.74,175.9 -92.69,207.7"
              id="path16" />
           <path
              d="m 20009,9819.7 c -89,0 -160.9,-72 -160.9,-160.9 0,-3645.1 -2285.9,-6967.5 -5688.1,-8267.7 -83,-31.8 -124.4,-124.8 -92.7,-207.7 24.5,-64.1 85.5,-103.4 150.2,-103.4 19.2,0 38.6,3.5 57.5,10.7 3525.7,1347.3 5894.8,4790.7 5894.8,8568.1 0,88.9 -71.9,160.9 -160.8,160.9"
              id="path18" />
           <path
              d="m 17096.9,9337.1 c -75.6,-1442.7 -651.1,-2751.9 -1559.6,-3760.6 l -442.2,442.2 -455,-455 442.2,-442.2 C 14073.6,4213 12764.4,3637.5 11321.7,3561.9 v 627.2 h -643.4 V 3561.9 C 9235.57,3637.5 7926.39,4213 6917.66,5121.5 l 442.24,442.2 -454.97,455 -442.24,-442.2 C 5554.16,6585.2 4978.72,7894.4 4903.08,9337.1 h 627.19 v 643.5 h -627.19 c 75.64,1442.6 651.08,2751.8 1559.61,3760.6 l 442.24,-442.1 454.97,454.9 -442.28,442.1 c 1008.73,908.6 2317.91,1484 3760.68,1559.6 v -627.2 h 643.4 v 627.2 c 1442.8,-75.6 2752,-651 3760.7,-1559.6 l -442.3,-442.1 455,-454.9 442.2,442.1 c 908.5,-1008.8 1484,-2318 1559.6,-3760.6 h -627.2 V 9337.1 Z M 11000,16415.5 c -3725.56,0 -6756.72,-3031 -6756.72,-6756.7 0,-3725.5 3031.16,-6756.7 6756.72,-6756.7 3725.6,0 6756.7,3031.2 6756.7,6756.7 0,3725.7 -3031.1,6756.7 -6756.7,6756.7"
              id="path20" />
           <path
              d="m 7111.05,10370.2 c 107.15,-62.6 234.09,-94 380.83,-94 121.6,0 229.68,23.6 324.89,70.4 95.2,46.8 171.86,111.5 229.68,194.2 58.13,82.6 92.07,177.2 101.8,283.4 l 0.95,11.6 h -307.3 l -2.2,-10.3 c -13.2,-60.1 -35.81,-110.6 -67.55,-152.7 -32.05,-42.2 -71.64,-74.2 -118.77,-96.5 -47.45,-22.3 -101.18,-33.3 -161.5,-33.3 -79.82,0 -149.57,20.7 -208.95,61.9 -59.39,41.5 -105.26,100.5 -137.63,177.2 -32.36,76.7 -48.39,168.1 -48.39,273.7 v 0.6 c 0,105.1 16.03,195.4 48.39,271.2 32.37,75.7 77.92,134.1 136.99,175.4 59.08,41.4 128.83,62.1 209.59,62.1 62.52,0 117.19,-11.5 164.64,-34.4 47.13,-22.9 86.09,-54.8 116.57,-95.8 30.47,-41 51.84,-89.3 63.47,-144.9 l 4.4,-17.7 h 307.29 l -1.26,10.5 c -9.42,106.2 -43.36,200.6 -100.86,283.2 -57.81,82.8 -134.16,147.7 -229.36,195 -95.21,47.1 -203.29,70.9 -324.89,70.9 -146.74,0 -273.68,-31.1 -380.83,-93.4 -107.46,-62.2 -190.09,-151.1 -248.53,-266.7 -58.45,-115.6 -87.67,-254.4 -87.67,-416 v -1.3 c 0,-161.8 29.22,-300.7 87.67,-416.6 58.44,-116 141.07,-205.5 248.53,-267.7"
              id="path22" />
           <path
              d="m 8666.7,10782.7 c -33.31,76.4 -49.65,166.6 -49.65,270.9 v 2.2 c 0,104.6 16.34,195.1 49.65,271.1 32.68,76.2 79.8,134.8 140.45,176.1 60.96,41.4 132.27,62.1 214.29,62.1 81.69,0 153.01,-20.7 213.35,-62.1 60.63,-41.3 107.13,-100.2 140.13,-176.5 32.99,-76.4 49.64,-166.7 49.64,-270.7 v -2.2 c 0,-103.4 -16.34,-193.6 -49.01,-269.9 -32.68,-76.4 -78.87,-135.8 -139.19,-177.6 -60.02,-42.1 -131.66,-63.1 -214.92,-63.1 -82.95,0 -154.28,20.7 -214.6,62.5 -60.65,41.8 -107.46,100.6 -140.14,177.2 z m 740.59,-411.6 c 108.08,63.2 191.66,152.7 250.73,268.7 59.07,116.2 88.29,253.8 88.29,413.8 v 2.2 c 0,159.6 -29.22,297.2 -88.29,412.8 -59.07,115.8 -142.96,205.2 -251.37,268.4 -108.09,63.1 -236.59,94.9 -385.21,94.9 -147.99,0 -276.51,-31.5 -385.22,-94.5 -108.72,-62.8 -192.62,-152.2 -252,-268.1 -59.39,-116.1 -89.23,-253.9 -89.23,-413.5 v -2.2 c 0,-160 29.53,-297.9 88.6,-414.2 59.08,-116.5 142.97,-206.1 251.68,-268.9 108.72,-62.9 237.54,-94.3 386.17,-94.3 149.24,0 277.76,31.8 385.85,94.9"
              id="path24" />
           <path
              d="m 11255.1,11318.1 v -1015.8 h 281.2 v 1503.5 h -364.4 l -426.1,-1080.6 h -6.3 l -427.3,1080.6 h -363.54 v -1503.5 h 280.24 v 1015.8 h 9.3 l 405.5,-1015.8 h 197.9 l 405.5,1015.8 h 8"
              id="path26" />
           <path
              d="m 12101,11805.8 h -314.5 v -1503.5 h 314.5 v 1503.5"
              id="path28" />
           <path
              d="m 12664.7,11285.9 h 6.4 l 681.4,-983.6 h 272.7 v 1503.5 h -313.6 v -977.2 h -7 l -678.6,977.2 h -274.9 v -1503.5 h 313.6 v 983.6"
              id="path30" />
           <path
              d="m 14569.4,10871.3 h 342.8 l -0.9,-21 c -2.2,-61.9 -18.6,-115.7 -49.1,-161.8 -30.4,-46.2 -71.9,-82 -123.8,-107.5 -52.1,-25.4 -112.4,-38 -180.3,-38 -83.3,0 -155.6,20.7 -216.8,61.9 -61,41.5 -108.4,100.8 -141.7,178.1 -33.3,77.6 -50,170.3 -50,277.8 v 1.3 c 0,106 15.7,196.6 47.5,271.7 31.4,75 76.9,132.3 136.3,171.9 59.4,39.6 130.4,59.4 213.1,59.4 84.8,0 156.4,-20.3 214.6,-61 58.4,-40.6 100.2,-97 125,-169.3 l 3.2,-11.5 h 316.7 l -3.2,15.7 c -16.6,94.4 -53.7,178.7 -111.8,252.7 -58.1,73.9 -133.2,132.4 -225.6,175.4 -92.4,43.2 -198.9,64.8 -319.9,64.8 -146.4,0 -273.7,-31 -381.4,-92.9 -107.5,-61.7 -190.4,-150.2 -248.9,-265 -58.4,-115 -87.7,-253.1 -87.7,-414.5 v -0.6 c 0,-163.4 29.6,-303.5 88.3,-420.1 58.5,-116.6 142.1,-206.4 250.5,-268.6 108.4,-62.6 237.2,-94 386.4,-94 139.9,0 259.3,26.4 358.6,79.2 99.2,52.8 175.6,128.2 228.7,226.2 53.1,97.8 79.5,215.3 79.5,351.9 v 173.2 h -650.1 v -235.4"
              id="path32" />
           <path
              d="m 7848.18,8778.4 -226.54,45.9 c -122.23,24.5 -212.09,57.5 -269.59,98.3 -57.19,41.2 -85.78,95.5 -85.78,163.7 v 1.6 c 0,50.3 14.45,94.9 43.68,133.8 29.22,39 71.32,69.5 126.62,91.2 55.31,21.6 120.97,32.6 197.01,32.6 74.15,0 139.19,-11 195.44,-32.6 56.24,-21.7 102.11,-52.2 137,-91.8 35.18,-39.6 55.92,-87.3 62.84,-143.6 l 3.13,-17.9 h 421.98 l -1.25,26.7 c -6.91,125.4 -43.99,234.7 -111.23,328.1 -67.24,93.3 -160.56,165.9 -280.58,217.7 -120.03,51.8 -262.37,77.9 -427.33,77.9 -158.04,0 -298.18,-27.3 -420.72,-81.7 -122.23,-54.3 -218.69,-129.7 -288.76,-226.5 -70.07,-96.8 -105.57,-210.2 -106.51,-340.6 v -1.6 c 0,-160.9 53.41,-291.9 160.55,-393.1 107.15,-101.1 265.82,-172.5 476.34,-213.9 l 224.97,-45.9 c 132.29,-26.7 226.87,-60.7 283.73,-101.5 56.57,-41.2 85.16,-97.7 85.16,-169.7 v -1.5 c 0,-54.4 -16.35,-101.8 -49.02,-143 -32.37,-40.9 -78.55,-72.6 -138.57,-94.6 -59.7,-22.3 -131.02,-33.3 -213.97,-33.3 -80.75,0 -153.33,11 -217.75,33.3 -64.1,22 -116.26,53.1 -156.16,93.3 -39.9,39.9 -64.73,87.7 -74.78,143 l -2.83,16 h -422.29 l 1.57,-23.5 c 7.85,-131.4 48.07,-243.5 120.66,-336.9 72.57,-93.3 171.87,-164.9 297.87,-214.9 125.68,-49.6 273.04,-74.8 442.09,-74.8 179.72,0 333.99,27.4 462.82,82.3 128.83,54.7 227.49,132.3 295.98,232.6 68.81,100.2 103.06,219 103.06,356 v 1.5 c 0,167.8 -52.16,300.1 -156.16,396.2 -104.32,96.2 -268.33,166.6 -492.68,211.2"
              id="path34" />
           <path
              d="m 10306.9,8603.4 c 0,-147.1 -23.3,-275 -69.5,-383.4 -46.5,-108.7 -112.5,-192.9 -197.6,-252.6 -85.48,-59.7 -187.6,-89.5 -306.06,-89.5 -117.51,0 -218.99,29.5 -305.09,88.9 -85.78,59.1 -152.39,143 -199.21,251.7 -46.81,108.7 -70.38,236.9 -70.38,384.9 v 3.1 c 0,149 23.57,277.8 70.38,385.9 46.82,108.1 113.74,191.3 200.15,250.1 86.09,58.7 187.59,88.3 304.15,88.3 116.57,0 217.75,-29.6 303.56,-88.3 86.1,-58.8 152.4,-142.4 199.2,-251.1 47.1,-108.4 70.4,-236.9 70.4,-384.9 z m -25.2,971.8 c -153.9,89.6 -336.49,134.8 -547.96,134.8 -210.2,0 -392.75,-44.9 -547.34,-134.1 -154.28,-89.6 -274,-216.5 -358.21,-381.5 -84.52,-165 -126.62,-360.7 -126.62,-587.9 v -3.1 c 0,-226.9 41.79,-423.3 125.69,-588.5 84.2,-165.6 203.29,-292.9 357.88,-382.1 154.59,-89.6 337.46,-134.2 548.6,-134.2 212.41,0 395.26,45 548.96,134.8 153.3,89.9 272.1,217.1 356,382.1 83.9,165 126,361 126,587.9 v 3.1 c 0,227.2 -42.1,422.9 -126,587.3 -83.9,164.3 -203,291.6 -357,381.4"
              id="path36" />
           <path
              d="m 12588.9,8603.4 c 0,-147.1 -23.2,-275 -69.4,-383.4 -46.5,-108.7 -112.5,-192.9 -197.6,-252.6 -85.5,-59.7 -187.6,-89.5 -306.1,-89.5 -117.5,0 -219,29.5 -305.1,88.9 -85.7,59.1 -152.4,143 -199.2,251.7 -46.8,108.7 -70.4,236.9 -70.4,384.9 v 3.1 c 0,149 23.6,277.8 70.4,385.9 46.8,108.1 113.8,191.3 200.2,250.1 86.1,58.7 187.6,88.3 304.1,88.3 116.6,0 217.8,-29.6 303.6,-88.3 86,-58.8 152.3,-142.4 199.2,-251.1 47.1,-108.4 70.3,-236.9 70.3,-384.9 z m -25.1,971.8 c -153.9,89.6 -336.5,134.8 -548,134.8 -210.2,0 -392.7,-44.9 -547.3,-134.1 -154.3,-89.6 -274,-216.5 -358.2,-381.5 -84.5,-165 -126.6,-360.7 -126.6,-587.9 v -3.1 c 0,-226.9 41.7,-423.3 125.6,-588.5 84.3,-165.6 203.3,-292.9 357.9,-382.1 154.6,-89.6 337.5,-134.2 548.6,-134.2 212.4,0 395.3,45 549,134.8 153.3,89.9 272.1,217.1 356,382.1 83.8,165 125.9,361 125.9,587.9 v 3.1 c 0,227.2 -42.1,422.9 -125.9,587.3 -83.9,164.3 -203,291.6 -357,381.4"
              id="path38" />
           <path
              d="m 14699.8,8283.5 h -10 l -964.7,1389.4 h -390.9 V 7535.7 h 445.6 v 1398.2 h 9.5 l 968,-1398.2 h 388 v 2137.2 h -445.5 V 8283.5"
              id="path40" />
         </g>
       </g>
     </svg>
    );
};

export default ComingSoon;