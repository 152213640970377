import React from "react";
const ChevronDownIcon = ({ strokeWidth = 1.5, ...otherProps }) => {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      focusable="false"
      height="1em"
      role="presentation"
      viewBox="0 0 24 24"
      width="1em"
      {...otherProps}
    >
      <path
        d="m19.92 8.95-6.52 6.52c-.77.77-2.03.77-2.8 0L4.08 8.95"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export const DianeTransitionIAIcon = (props) => {
  return <svg viewBox="0 0 79.265968 79.771652" version="1.1" id="svg5"
   xmlns="http://www.w3.org/2000/svg"
      {...props}>
  <g id="layer1" transform="translate(-65.021237,-106.09723)">
    <g aria-label="Transition IA" transform="matrix(0.03435216,0,0,0.03622834,105.17019,248.29895)" id="text5664"
      style={{
        fontSize: "302.821px",
        fontFamily: "FS Nokio",
        fillOpacity: 1
      }}>
      <path
        d="m -847.25677,-1912.8043 q -5.14796,0 -8.17617,-3.6339 -1.51411,-1.8169 -2.42257,-3.6338 -0.90846,-1.817 -0.90846,-4.2395 0,-4.8452 3.33103,-8.7818 3.02821,-3.6339 8.17617,-3.6339 h 127.48764 q 5.14796,0 8.78181,3.6339 3.63385,3.6338 3.63385,8.479 0,4.8451 -3.63385,8.4789 -3.63385,3.6339 -8.47899,3.6339 h -51.47957 v 175.6362 q 0,4.8451 -3.63385,8.479 -3.63385,3.6338 -8.47899,3.6338 -4.84514,0 -8.47899,-3.6338 -3.63385,-3.6339 -3.63385,-8.7818 v -175.6362 z"
        id="path283" style={{
          fill: "#e20025",
          fillOpacity: 1
        }} />
      <path
        d="m -692.21206,-1864.353 q 0,-2.4225 0.30282,-4.2395 0.60564,-2.1197 2.42257,-3.9366 1.81692,-1.817 3.63385,-2.4226 2.11975,-0.6056 4.54231,-0.6056 5.14796,0 8.78181,3.6338 3.63386,3.6339 3.63386,8.479 v 8.1762 q 10.90155,-9.6903 23.62004,-15.1411 13.0213,-5.4508 28.16235,-5.4508 5.14796,0 8.78181,3.6339 3.63385,3.6338 3.63385,8.479 0,4.8451 -3.63385,8.479 -3.63385,3.6338 -8.47899,3.6338 -10.90156,0 -19.98619,3.9367 -9.69027,4.2395 -16.35233,10.9015 -6.96488,6.9649 -10.90156,16.3524 -3.93667,9.0846 -3.93667,19.9862 v 63.5924 q 0,4.8451 -3.63385,8.479 -3.63386,3.6338 -8.47899,3.6338 -4.84514,0 -8.47899,-3.6338 -3.63385,-3.6339 -3.63385,-8.7818 z"
        id="path285" style={{
          fill: "#e20025",
          fillOpacity: 1
        }} />
      <path
        d="m -466.30761,-1743.5274 q -9.9931,9.3875 -23.62004,14.8382 -13.32413,5.4508 -28.16236,5.4508 -15.44387,0 -29.37363,-6.0564 -13.32413,-5.7536 -23.92286,-16.3523 -10.29592,-10.2959 -16.35234,-24.2257 -5.7536,-13.3241 -5.7536,-29.6765 0,-15.7467 6.05642,-29.6764 6.05642,-13.9298 16.35234,-24.2257 10.29591,-10.2959 24.22568,-16.3523 13.92976,-6.0565 29.67646,-6.0565 16.35233,0 29.67646,5.7536 13.92976,6.0565 24.22568,16.3524 10.59873,10.5987 16.35233,23.9228 6.05642,13.9298 6.05642,29.3737 v 63.5924 q 0,4.8451 -3.63385,8.479 -3.63385,3.6338 -8.47899,3.6338 -4.84513,0 -8.47899,-3.6338 -3.63385,-3.6339 -3.63385,-8.7818 v -8.1762 z m -52.08522,-3.331 q 9.9931,0 19.98619,-4.2395 9.38745,-3.9367 16.35234,-10.9016 6.66206,-6.662 10.90155,-16.3523 4.2395,-9.9931 4.2395,-19.9862 0,-10.9015 -3.93668,-19.9862 -4.23949,-9.3874 -10.90155,-16.3523 -6.96489,-6.6621 -16.35234,-10.9016 -9.99309,-4.2395 -19.98619,-4.2395 -10.90155,0 -19.98618,3.9367 -9.69027,4.2395 -16.35234,10.9016 -6.96488,6.9649 -10.90155,16.3523 -3.93668,9.0846 -3.93668,19.9862 0,9.9931 4.2395,19.9862 3.93667,9.6903 10.90155,16.3523 6.66207,6.9649 16.35234,10.9016 4.84513,2.1197 9.69027,3.331 5.14796,1.2113 10.29592,1.2113 z"
        id="path287" style={{
          fill: "#e20025",
          fillOpacity: 1
        }} />
      <path
        d="m -280.37532,-1800.7606 q 0,-3.9366 -0.90846,-9.3874 -0.90847,-5.4508 -2.42257,-8.7818 -2.11975,-4.8451 -4.54232,-9.3875 -2.11974,-4.5423 -5.45077,-7.8733 -3.33104,-3.331 -7.87335,-6.3593 -4.23949,-3.331 -9.08463,-5.4507 -4.84514,-2.1198 -11.5072,-2.1198 -6.35924,-0.3028 -11.5072,-0.3028 -10.90155,0 -19.98619,3.9367 -9.69027,4.2395 -16.35233,10.9015 -6.96488,6.9649 -10.90156,16.3524 -3.93667,9.0846 -3.93667,19.9862 v 63.5924 q 0,4.8451 -3.63385,8.479 -3.63385,3.6338 -8.47899,3.6338 -4.84514,0 -8.47899,-3.6338 -3.63385,-3.6339 -3.63385,-8.7819 v -127.1848 q 0,-5.1479 3.63385,-8.7818 3.63385,-3.6338 8.78181,-3.6338 5.14796,0 8.78181,3.6338 3.63385,3.6339 3.63385,8.479 v 8.1762 q 10.90156,-9.6903 23.62004,-15.1411 13.02131,-5.4508 28.16236,-5.4508 16.35233,0 29.67646,5.7536 13.92976,6.0565 24.22568,16.3524 10.59873,10.5987 16.35233,23.9228 6.05642,13.9298 6.05642,29.3737 v 63.5924 q 0,4.8451 -3.63385,8.479 -3.63385,3.6338 -8.47899,3.6338 -4.84514,0 -8.47899,-3.6338 -3.63385,-3.6339 -3.63385,-8.7818 z"
        id="path289" style={{
          fill: "#e20025",
          fillOpacity: 1
        }} />
      <path
        d="m -211.33197,-1725.0553 q -4.84514,0 -8.47899,-3.6339 -3.63385,-3.6338 -3.63385,-8.1761 0,-5.148 3.63385,-8.7818 3.63385,-3.6339 8.78181,-3.6339 h 65.10652 q 8.17617,0 13.92977,-5.7536 5.7536,-5.7536 5.7536,-13.9297 0,-8.1762 -5.7536,-13.9298 -5.7536,-5.7536 -13.92977,-5.7536 h -33.31031 q -9.08463,0 -17.2608,-3.6339 -8.17617,-3.6338 -13.92977,-9.3874 -5.7536,-5.7536 -9.38745,-13.9298 -3.63385,-8.1761 -3.63385,-17.2608 0,-9.0846 3.63385,-17.2608 3.63385,-8.1761 9.38745,-13.9297 5.7536,-5.7536 13.92977,-9.3875 8.17617,-3.6338 17.2608,-3.6338 h 47.24008 q 5.14795,0 8.78181,3.6338 3.63385,3.6339 3.63385,8.479 0,4.8451 -3.63385,8.479 -3.63386,3.6338 -8.47899,3.6338 h -47.24008 q -8.17617,0 -13.92977,5.7536 -5.7536,5.7536 -5.7536,13.9298 0,8.1762 5.7536,13.9298 5.7536,5.7536 13.92977,5.7536 h 33.31031 q 9.08463,0 17.2608,3.6338 8.17617,3.6339 13.92977,9.3875 5.75359,5.7536 9.38745,13.9297 3.63385,8.1762 3.63385,17.2608 0,9.0847 -3.63385,17.2608 -3.63386,8.1762 -9.38745,13.9298 -5.7536,5.7536 -13.92977,9.3874 -8.17617,3.6339 -17.2608,3.6339 h -65.10652 z"
        id="path291" style={{
          fill: "#e20025",
          fillOpacity: 1
        }} />
      <path
        d="m -41.146434,-1923.4031 q 0,6.0565 -3.936673,9.9931 -3.936673,3.9367 -9.993094,3.9367 -6.05642,0 -9.993093,-3.9367 -3.936673,-3.9366 -3.936673,-9.9931 0,-6.0564 3.936673,-9.9931 3.936673,-3.9366 9.993093,-3.9366 6.056421,0 9.993094,3.9366 3.936673,3.9367 3.936673,9.9931 z m -25.739786,186.235 v -127.1849 q 0,-4.8451 3.633852,-8.479 3.633852,-3.6338 8.478988,-3.6338 4.845137,0 8.478989,3.6338 3.633852,3.6339 3.633852,8.7819 v 127.1848 q 0,5.1479 -3.633852,8.7818 -3.633852,3.6338 -8.78181,3.6338 -5.147957,0 -8.781809,-3.6338 -3.02821,-3.0282 -3.02821,-8.479 z"
        id="path293" style={{
          fill: "#e20025",
          fillOpacity: 1
        }} />
      <path
        d="m 16.389748,-1769.8728 q 0,9.0846 5.753599,14.8382 6.359242,6.3593 15.141051,6.3593 4.239494,0 7.267704,-1.5141 3.331032,-1.5141 6.662063,-4.8452 3.331031,-3.331 8.176167,-3.331 4.845136,0 8.78181,3.331 3.633852,3.6339 3.633852,8.479 0,4.8451 -3.633852,8.479 -6.056421,6.0564 -14.232588,9.6903 -8.176168,3.6338 -17.563619,3.6338 -9.387451,0 -17.563619,-3.6338 -8.176167,-3.6339 -14.2325875,-9.6903 -6.0564203,-6.0564 -9.6902724,-14.2326 -3.6338522,-8.1762 -3.6338522,-17.8664 v -154.4387 q 0,-5.148 3.6338522,-8.7819 3.6338521,-3.6338 8.7818094,-3.6338 5.1479572,0 8.7818095,3.6338 3.633852,3.6339 3.633852,8.479 v 48.4514 h 33.310312 q 5.147957,0 8.781809,3.6338 3.633852,3.6339 3.633852,8.479 0,4.8452 -3.633852,8.479 -3.633852,3.6339 -8.478988,3.6339 H 16.389748 v 81.7616 z"
        id="path295" style={{
          fill: "#e20025",
          fillOpacity: 1
        }} />
      <path
        d="m 127.82781,-1923.4031 q 0,6.0565 -3.93667,9.9931 -3.93668,3.9367 -9.9931,3.9367 -6.05642,0 -9.99309,-3.9367 -3.936674,-3.9366 -3.936674,-9.9931 0,-6.0564 3.936674,-9.9931 3.93667,-3.9366 9.99309,-3.9366 6.05642,0 9.9931,3.9366 3.93667,3.9367 3.93667,9.9931 z m -25.73979,186.235 v -127.1849 q 0,-4.8451 3.63386,-8.479 3.63385,-3.6338 8.47898,-3.6338 4.84514,0 8.47899,3.6338 3.63385,3.6339 3.63385,8.7819 v 127.1848 q 0,5.1479 -3.63385,8.7818 -3.63385,3.6338 -8.78181,3.6338 -5.14795,0 -8.78181,-3.6338 -3.02821,-3.0282 -3.02821,-8.479 z"
        id="path297" style={{
          fill: "#e20025",
          fillOpacity: 1
        }} />
      <path
        d="m 228.97012,-1725.0553 q -7.87335,0 -15.14105,-1.2113 -7.26771,-1.2113 -14.23259,-4.2395 -6.96488,-3.0282 -13.0213,-6.3592 -5.7536,-3.6339 -10.90156,-8.7818 -10.29591,-10.2959 -16.35233,-24.2257 -5.7536,-13.3241 -5.7536,-29.6765 0,-15.7467 6.05642,-29.6764 6.05642,-13.9298 16.35233,-24.2257 10.29592,-10.2959 24.22568,-16.3523 13.92977,-6.0565 29.67646,-6.0565 16.35234,0 29.67646,5.7536 13.92977,6.0565 24.22568,16.3524 10.59874,10.5987 16.35234,23.9228 6.05642,13.9298 6.05642,29.3737 0,16.0495 -5.7536,29.3736 -5.7536,13.3241 -16.35234,23.9229 -10.59873,10.5987 -23.92286,16.3523 -13.32412,5.7536 -29.37363,5.7536 z m -0.60564,-22.1059 q 10.90155,0 19.98618,-3.9367 9.38746,-3.9367 16.35234,-10.9016 6.66206,-6.662 10.90156,-16.3523 4.23949,-9.9931 4.23949,-19.9862 0,-10.9015 -3.93667,-19.9862 -4.2395,-9.3874 -10.90156,-16.3523 -6.96488,-6.6621 -16.35234,-10.9016 -9.99309,-4.2395 -19.98618,-4.2395 -10.90156,0 -19.98619,3.9367 -9.69027,4.2395 -16.35233,10.9016 -6.96489,6.9649 -10.90156,16.3523 -3.93667,9.0846 -3.93667,19.9862 0,9.9931 4.23949,19.9862 3.93667,9.6903 10.90156,16.3523 6.66206,6.9649 16.35233,10.9016 4.84514,2.1197 9.08463,3.331 4.54232,0.9085 9.69027,0.9085 z"
        id="path299" style={{
          fill: "#e20025",
          fillOpacity: 1
        }} />
      <path
        d="m 463.95931,-1800.7606 q 0,-3.9366 -0.90847,-9.3874 -0.90846,-5.4508 -2.42257,-8.7818 -2.11974,-4.8451 -4.54231,-9.3875 -2.11975,-4.5423 -5.45078,-7.8733 -3.33103,-3.331 -7.87335,-6.3593 -4.23949,-3.331 -9.08463,-5.4507 -4.84513,-2.1198 -11.50719,-2.1198 -6.35925,-0.3028 -11.5072,-0.3028 -10.90156,0 -19.98619,3.9367 -9.69027,4.2395 -16.35233,10.9015 -6.96489,6.9649 -10.90156,16.3524 -3.93667,9.0846 -3.93667,19.9862 v 63.5924 q 0,4.8451 -3.63386,8.479 -3.63385,3.6338 -8.47898,3.6338 -4.84514,0 -8.47899,-3.6338 -3.63386,-3.6339 -3.63386,-8.7819 v -127.1848 q 0,-5.1479 3.63386,-8.7818 3.63385,-3.6338 8.78181,-3.6338 5.14795,0 8.78181,3.6338 3.63385,3.6339 3.63385,8.479 v 8.1762 q 10.90155,-9.6903 23.62004,-15.1411 13.0213,-5.4508 28.16235,-5.4508 16.35234,0 29.67646,5.7536 13.92977,6.0565 24.22568,16.3524 10.59874,10.5987 16.35234,23.9228 6.05642,13.9298 6.05642,29.3737 v 63.5924 q 0,4.8451 -3.63386,8.479 -3.63385,3.6338 -8.47898,3.6338 -4.84514,0 -8.47899,-3.6338 -3.63385,-3.6339 -3.63385,-8.7818 z"
        id="path301" style={{
          fill: "#e20025",
          fillOpacity: 1
        }} />
      <path
        d="m 651.10301,-1736.5625 q 0,4.8451 -3.63385,8.479 -3.63385,3.6338 -8.47899,3.6338 -4.84513,0 -8.47898,-3.6338 -3.63386,-3.6339 -3.63386,-8.7818 v -187.749 q 0,-5.148 3.63386,-8.7819 3.63385,-3.6338 8.78181,-3.6338 4.54231,0 8.17616,3.6338 3.63385,3.6339 3.63385,8.479 v 187.7491 z"
        id="path303" style={{
          fill: "#e20025",
          fillOpacity: 1
        }} />
      <path
        d="m 708.03363,-1788.6477 v 52.9937 q 0,4.8451 -3.63385,8.479 -3.63386,3.6338 -8.47899,3.6338 -4.84514,0 -8.47899,-3.6338 -3.63385,-3.6339 -3.63385,-8.7819 v -124.1566 q 0,-15.7467 6.05642,-29.6764 6.05642,-13.9298 16.35233,-24.2257 10.29592,-10.2959 24.22568,-16.3523 13.92977,-6.0565 29.67646,-6.0565 16.35234,0 29.67646,5.7536 13.92977,6.0565 24.22568,16.3524 10.59874,10.5987 16.35234,23.9228 6.05642,13.9298 6.05642,29.3737 v 124.1566 q 0,4.8451 -3.63385,8.479 -3.63386,3.6338 -8.47899,3.6338 -4.84514,0 -8.47899,-3.6338 -3.63385,-3.6339 -3.63385,-8.7818 v -51.4796 z m 104.17043,-70.8601 q 0,-3.9367 -1.51411,-10.2959 -1.21128,-6.3593 -2.72539,-9.6903 -2.11974,-4.8452 -4.54231,-9.0846 -2.11975,-4.2395 -5.45078,-7.5706 -3.33103,-3.331 -7.87335,-6.3592 -4.23949,-3.331 -9.08463,-5.4508 -4.84513,-2.1197 -10.90155,-2.4226 -5.7536,-0.6056 -10.90156,-0.6056 -10.90156,0 -19.98619,3.9367 -9.69027,4.2395 -16.35233,10.9015 -6.96489,6.9649 -10.90156,16.3524 -3.93667,9.0846 -3.93667,19.9862 v 48.4513 h 104.17043 v -48.4513 z"
        id="path305" style={{
          fill: "#e20025",
          fillOpacity: 1
        }} />
    </g>
    <g aria-label="DIANE" transform="matrix(1.0107697,0,0,0.98934503,0,0.19999971)" id="text265"
      style={{
        fontSize: "21.61px",
        fontFamily: 'FS Nokio',
        fill: "#004589",
        strokeWidth: 0.405189,
        fillOpacity: 1
      }}>
      <path
        d="m 67.353839,158.20244 h 4.7542 q 3.84658,0 6.28851,1.98812 2.46354,1.98812 2.46354,5.57538 0,3.56565 -2.39871,5.57538 -2.3771,1.98812 -6.15885,1.98812 h -4.94869 q -1.79363,0 -2.42032,-0.49703 -0.60508,-0.51864 -0.60508,-2.42032 v -9.2923 q 0,-1.92329 0.60508,-2.39871 0.60508,-0.51864 2.42032,-0.51864 z m 0,3.4576 v 8.2118 q 0,0.28093 0,0.38898 0.02161,0.0864 0.10805,0.17288 0.10805,0.0864 0.32415,0.0864 h 4.38683 q 2.67964,0 4.12751,-1.21016 1.46948,-1.21016 1.46948,-3.54404 0,-2.31227 -1.49109,-3.52243 -1.46948,-1.23177 -4.17073,-1.23177 h -4.322 q -0.30254,0 -0.36737,0.12966 -0.06483,0.10805 -0.06483,0.51864 z"
        style={{
          fontFamily: "Lawyer Gothic",
          fill: "#004589",
          fillOpacity: 1
        }} id="path317" />
      <path
        d="m 90.317411,173.19572 h -7.3474 q -1.5127,0 -1.5127,-1.40465 0,-1.40465 1.5127,-1.40465 h 2.161 v -9.5084 h -2.161 q -1.5127,0 -1.5127,-1.40465 0,-1.40465 1.5127,-1.40465 h 7.3474 q 1.5127,0 1.5127,1.40465 0,1.40465 -1.5127,1.40465 h -2.161 v 9.5084 h 2.161 q 1.5127,0 1.5127,1.40465 0,1.40465 -1.5127,1.40465 z"
        style={{
          fontFamily: "Lawyer Gothic",
          fill: "#004589",
          fillOpacity: 1
        }} id="path319" />
      <path
        d="m 107.04189,172.35699 -1.31821,-2.48515 h -9.05466 l -1.296641,2.48515 q -0.6483,1.18855 -1.64236,1.18855 -0.60508,0 -1.03728,-0.41059 -0.41059,-0.41059 -0.41059,-0.99406 0,-0.45381 0.34576,-1.0805 l 5.942798,-11.2372 q 0.561883,-1.05889 1.16699,-1.44787 0.626703,-0.38898 1.469493,-0.38898 0.82118,0 1.40465,0.38898 0.60508,0.38898 1.21016,1.44787 l 5.98597,11.2372 q 0.32415,0.60508 0.32415,1.0805 0,0.58347 -0.41059,0.99406 -0.41059,0.41059 -1.01567,0.41059 -1.03728,0 -1.66397,-1.18855 z m -2.78769,-5.29445 -3.04701,-5.81309 -3.047081,5.81309 z"
        style={{
          fontFamily: "Lawyer Gothic",
          fill: "#004589",
          fillOpacity: 1
        }} id="path321" />
      <path
        d="m 110.99957,172.03284 v -12.5338 q 0,-0.69152 0.41059,-1.10211 0.41059,-0.41059 1.10211,-0.41059 0.56186,0 0.97245,0.23771 0.41059,0.23771 0.97245,0.84279 l 8.8601,9.5084 v -9.0762 q 0,-0.69152 0.41059,-1.10211 0.41059,-0.41059 1.10211,-0.41059 0.69152,0 1.10211,0.41059 0.41059,0.41059 0.41059,1.10211 v 12.5338 q 0,0.69152 -0.41059,1.10211 -0.41059,0.41059 -1.10211,0.41059 -0.54025,0 -0.97245,-0.23771 -0.4322,-0.25932 -0.97245,-0.84279 l -8.8601,-9.5084 v 9.0762 q 0,0.69152 -0.41059,1.10211 -0.41059,0.41059 -1.10211,0.41059 -0.69152,0 -1.10211,-0.41059 -0.41059,-0.41059 -0.41059,-1.10211 z"
        style={{
          fontFamily: "Lawyer Gothic",
          fill: "#004589",
          fillOpacity: 1
        }} id="path323" />
      <path
        d="m 139.29223,167.06254 h -7.7796 v 2.8093 q 0,0.28093 0,0.38898 0.0216,0.0864 0.10805,0.17288 0.10805,0.0864 0.32415,0.0864 h 9.2923 q 1.5127,0 1.5127,1.40465 0,1.40465 -1.5127,1.40465 h -9.7245 q -1.79363,0 -2.42032,-0.49703 -0.60508,-0.51864 -0.60508,-2.42032 v -9.2923 q 0,-1.92329 0.60508,-2.39871 0.60508,-0.51864 2.42032,-0.51864 h 9.7245 q 1.5127,0 1.5127,1.40465 0,1.40465 -1.5127,1.40465 h -9.2923 q -0.30254,0 -0.36737,0.12966 -0.0648,0.10805 -0.0648,0.51864 v 2.5932 h 7.7796 q 1.5127,0 1.5127,1.40465 0,1.40465 -1.5127,1.40465 z"
        style={{
          fontFamily: "Lawyer Gothic",
          fill: "#004589",
          fillOpacity: 1
        }} id="path325" />
    </g>
    <path
      d="m 82.621392,110.40206 v 4.30474 h 11.176281 c 12.245777,0.0267 14.037187,0.18717 16.791157,1.49731 2.16574,1.06949 4.86623,3.76998 5.93573,5.9892 1.09624,2.19248 1.73794,5.29402 1.52404,7.08544 -0.80213,6.28331 -5.37424,11.17628 -11.39018,12.13883 -0.98929,0.16043 -6.417,0.29411 -12.860747,0.29411 H 82.621392 v 4.17106 4.14431 l 13.181593,-0.0803 c 14.545215,-0.10695 14.384785,-0.10695 18.288455,-1.95185 4.91972,-2.29942 8.34212,-5.72182 10.64154,-10.64151 1.28339,-2.67376 1.76468,-4.67908 1.9251,-7.88758 0.48128,-9.09075 -4.0641,-16.89811 -12.08536,-20.77504 -4.62559,-2.2727 -4.99991,-2.29944 -19.43817,-2.45986 l -12.513158,-0.13369 z"
      id="path355" style={{
        fill: "#e20025",
        stroke: "none",
        strokeWidth: 0.0267376,
        fillOpacity: 1
      }} />
  </g>
</svg>
}

const DianeIcon = ({ size = 32, ...props }) => (
  <img
    src={require("../assets/images/Logo_DIANE_Court.png")}
    alt="Logo DIANE"
    width={size}
    height={size}
    {...props}
  />
);

export const CloseFilledIcon = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    height="1em"
    role="presentation"
    viewBox="0 0 24 24"
    width="1em"
    {...props}
  >
    <path
      d="M12 2a10 10 0 1010 10A10.016 10.016 0 0012 2zm3.36 12.3a.754.754 0 010 1.06.748.748 0 01-1.06 0l-2.3-2.3-2.3 2.3a.748.748 0 01-1.06 0 .754.754 0 010-1.06l2.3-2.3-2.3-2.3A.75.75 0 019.7 8.64l2.3 2.3 2.3-2.3a.75.75 0 011.06 1.06l-2.3 2.3z"
      fill="currentColor"
    />
  </svg>
);

const VerticalDotsIcon = ({ size = 24, width, height, ...props }) => {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      focusable="false"
      height={size || height}
      role="presentation"
      viewBox="0 0 24 24"
      width={size || width}
      {...props}
    >
      <path
        d="M12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
        fill="currentColor"
      />
    </svg>
  );
};

const DeleteIcon = (props) => {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      focusable="false"
      height="1em"
      role="presentation"
      viewBox="0 0 20 20"
      width="1em"
      {...props}
    >
      <path
        d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="M7.08331 4.14169L7.26665 3.05002C7.39998 2.25835 7.49998 1.66669 8.90831 1.66669H11.0916C12.5 1.66669 12.6083 2.29169 12.7333 3.05835L12.9166 4.14169"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="M15.7084 7.61664L15.1667 16.0083C15.075 17.3166 15 18.3333 12.675 18.3333H7.32502C5.00002 18.3333 4.92502 17.3166 4.83335 16.0083L4.29169 7.61664"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="M8.60834 13.75H11.3833"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="M7.91669 10.4167H12.0834"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </svg>
  );
};

const ActualizeIcon = (props) => {
  return (
  <svg
    aria-hidden="true"
    focusable="false"
    viewBox="0 0 512 512"
    height="1em"
    role="presentation"
    width="1em"
    {...props}
  >
    <path
      d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160 352 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l111.5 0c0 0 0 0 0 0l.4 0c17.7 0 32-14.3 32-32l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1L16 432c0 17.7 14.3 32 32 32s32-14.3 32-32l0-35.1 17.6 17.5c0 0 0 0 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.8c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352l34.4 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L48.4 288c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>);
};

const EditIcon = (props) => {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      focusable="false"
      height="1em"
      role="presentation"
      viewBox="0 0 20 20"
      width="1em"
      {...props}
    >
      <path
        d="M11.05 3.00002L4.20835 10.2417C3.95002 10.5167 3.70002 11.0584 3.65002 11.4334L3.34169 14.1334C3.23335 15.1084 3.93335 15.775 4.90002 15.6084L7.58335 15.15C7.95835 15.0834 8.48335 14.8084 8.74168 14.525L15.5834 7.28335C16.7667 6.03335 17.3 4.60835 15.4583 2.86668C13.625 1.14168 12.2334 1.75002 11.05 3.00002Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
      />
      <path
        d="M9.90833 4.20831C10.2667 6.50831 12.1333 8.26665 14.45 8.49998"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
      />
      <path
        d="M2.5 18.3333H17.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
      />
    </svg>
  );
};

const SearchIcon = (props) => {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      focusable="false"
      height="1em"
      role="presentation"
      viewBox="0 0 24 24"
      width="1em"
      {...props}
    >
      <path
        d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M22 22L20 20"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

const PlusIcon = ({ size = 24, width, height, ...props }) => {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      focusable="false"
      height={size || height}
      role="presentation"
      viewBox="0 0 24 24"
      width={size || width}
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path d="M6 12h12" />
        <path d="M12 18V6" />
      </g>
    </svg>
  );
};

const MailIcon = (props) => {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      focusable="false"
      height="1em"
      role="presentation"
      viewBox="0 0 24 24"
      width="1em"
      {...props}
    >
      <path
        d="M17 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V8.5C22 5 20 3.5 17 3.5ZM17.47 9.59L14.34 12.09C13.68 12.62 12.84 12.88 12 12.88C11.16 12.88 10.31 12.62 9.66 12.09L6.53 9.59C6.21 9.33 6.16 8.85 6.41 8.53C6.67 8.21 7.14 8.15 7.46 8.41L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91L16.53 8.41C16.85 8.15 17.33 8.2 17.58 8.53C17.84 8.85 17.79 9.33 17.47 9.59Z"
        fill="currentColor"
      />
    </svg>
  );
};

const LockIcon = (props) => {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      focusable="false"
      height="1em"
      role="presentation"
      viewBox="0 0 24 24"
      width="1em"
      {...props}
    >
      <path
        d="M12.0011 17.3498C12.9013 17.3498 13.6311 16.6201 13.6311 15.7198C13.6311 14.8196 12.9013 14.0898 12.0011 14.0898C11.1009 14.0898 10.3711 14.8196 10.3711 15.7198C10.3711 16.6201 11.1009 17.3498 12.0011 17.3498Z"
        fill="currentColor"
      />
      <path
        d="M18.28 9.53V8.28C18.28 5.58 17.63 2 12 2C6.37 2 5.72 5.58 5.72 8.28V9.53C2.92 9.88 2 11.3 2 14.79V16.65C2 20.75 3.25 22 7.35 22H16.65C20.75 22 22 20.75 22 16.65V14.79C22 11.3 21.08 9.88 18.28 9.53ZM12 18.74C10.33 18.74 8.98 17.38 8.98 15.72C8.98 14.05 10.34 12.7 12 12.7C13.66 12.7 15.02 14.06 15.02 15.72C15.02 17.39 13.67 18.74 12 18.74ZM7.35 9.44C7.27 9.44 7.2 9.44 7.12 9.44V8.28C7.12 5.35 7.95 3.4 12 3.4C16.05 3.4 16.88 5.35 16.88 8.28V9.45C16.8 9.45 16.73 9.45 16.65 9.45H7.35V9.44Z"
        fill="currentColor"
      />
    </svg>
  );
};

const SolarCloudDownloadBroken = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="2em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
      >
        <path strokeLinejoin="round" d="M12 22v-6m0 6l2-2m-2 2l-2-2" />
        <path d="M22 13.353c0 2.343-1.444 4.353-3.5 5.207M14.381 8.027a5.765 5.765 0 0 1 1.905-.321c.654 0 1.283.109 1.87.309m-11.04 2.594a4.351 4.351 0 0 0-.83-.08C3.919 10.53 2 12.426 2 14.765c0 1.896 1.261 3.501 3 4.041m2.116-8.197a5.577 5.577 0 0 1-.354-1.962C6.762 5.528 9.32 3 12.476 3c2.94 0 5.361 2.194 5.68 5.015m-11.04 2.594a4.29 4.29 0 0 1 1.55.634m9.49-3.228A5.73 5.73 0 0 1 20.463 9.5" />
      </g>
    </svg>
  );
};
const SolarCloudUploadBroken = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="2em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
      >
        <path strokeLinejoin="round" d="M12 16v6m0-6l2 2m-2-2l-2 2" />
        <path d="M22 13.353c0 2.343-1.444 4.353-3.5 5.207M14.381 8.027a5.765 5.765 0 0 1 1.905-.321c.654 0 1.283.109 1.87.309m-11.04 2.594a4.351 4.351 0 0 0-.83-.08C3.919 10.53 2 12.426 2 14.765c0 1.896 1.261 3.501 3 4.041m2.116-8.197a5.577 5.577 0 0 1-.354-1.962C6.762 5.528 9.32 3 12.476 3c2.94 0 5.361 2.194 5.68 5.015m-11.04 2.594a4.29 4.29 0 0 1 1.55.634m9.49-3.228A5.73 5.73 0 0 1 20.463 9.5" />
      </g>
    </svg>
  );
};

const EyeIcon = (props) => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    height="1em"
    role="presentation"
    viewBox="0 0 20 20"
    width="1em"
    {...props}
  >
    <path
      d="M12.9833 10C12.9833 11.65 11.65 12.9833 10 12.9833C8.35 12.9833 7.01666 11.65 7.01666 10C7.01666 8.35 8.35 7.01666 10 7.01666C11.65 7.01666 12.9833 8.35 12.9833 10Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M9.99999 16.8916C12.9417 16.8916 15.6833 15.1583 17.5917 12.1583C18.3417 10.9833 18.3417 9.00831 17.5917 7.83331C15.6833 4.83331 12.9417 3.09998 9.99999 3.09998C7.05833 3.09998 4.31666 4.83331 2.40833 7.83331C1.65833 9.00831 1.65833 10.9833 2.40833 12.1583C4.31666 15.1583 7.05833 16.8916 9.99999 16.8916Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);

const ChevronIcon = (props) => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    height="1em"
    role="presentation"
    viewBox="0 0 24 24"
    width="1em"
    {...props}
  >
    <path
      d="M15.5 19l-7-7 7-7"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export {
  ChevronDownIcon,
  ChevronIcon,
  DeleteIcon,
  ActualizeIcon,
  EditIcon,
  EyeIcon,
  SearchIcon,
  PlusIcon,
  MailIcon,
  LockIcon,
  DianeIcon,
  VerticalDotsIcon,
  SolarCloudDownloadBroken,
  SolarCloudUploadBroken,
};
