import { useNavigate } from 'react-router-dom';
import { useEntraUser } from '../../EntraUserProvider';
import { useMsal } from '@azure/msal-react';
import { Spinner } from '@nextui-org/react';
import { Suspense, useCallback } from 'react';
import PageMaintenance from '../../pages/errors/maintenance';
import CustomErrorBoundary from '../errors/custom-error-boundary';

const ProtectedRoute = ({ children, roles=[], isMaintenance=false }) => {
    const navigate = useNavigate();
    const { userData, userRoles, isLoading } = useEntraUser();
    const { instance } = useMsal();

    const getPage = useCallback(() => {
        // Check if user is authenticated
        if (!userData) {
            // Redirect to login page
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
            return null;
        }
        
        // Check if user has the required role
        if (!roles.some(r => userRoles.includes(r))) {
            // Redirect to unauthorized page
            navigate('/403', { replace: true });
            return null;
        }


        return (
            <Suspense
                fallback={
                    <div className="flex justify-center align-middle items-center h-full w-full">
                        <Spinner color="danger" label="Chargement en cours..." />
                    </div>
                }  
          >
            {(isMaintenance && !userRoles.includes("Plateforme.FullAccess")) && <PageMaintenance />}
            {!(isMaintenance && !userRoles.includes("Plateforme.FullAccess")) && children}
            </Suspense>);
    }, [isLoading, userData, userRoles, roles]);

    // If user is authenticated and has the right role, render children
    
    return <div className='flex justify-center align-middle h-full w-full'>
        {isLoading && <Spinner color="danger" label="Chargement de l'utilisateur..." />}
        <CustomErrorBoundary>
            {!isLoading && getPage()}
        </CustomErrorBoundary>
    </div>;
};

export default ProtectedRoute;
