import {PaginationItemValue} from "@nextui-org/use-pagination";
import {useCallback, useMemo} from "react";
import {useLocale} from "@react-aria/i18n";
import {forwardRef} from "@nextui-org/system";
import {PaginationItemType} from "@nextui-org/use-pagination";
import {ChevronIcon, EllipsisIcon, ForwardIcon} from "@nextui-org/shared-icons";
import {clsx, dataAttr} from "@nextui-org/shared-utils";
import { PaginationCursor, PaginationItem, usePagination } from "@nextui-org/react";

const CustomPagination = forwardRef((initProps, ref) => {
    const { visiblePages, onChange, ...props } = initProps;
    
    const pages = useMemo(() => {
        let p = undefined;
        let tot = props.total ?? 1;
        if (visiblePages === undefined) {
            p = Array.from({ length: tot }, (_, i) => i + 1);
        }
        else {
            p = visiblePages.map(x => x+1).filter(x => x >= 1 && x <= tot);
        }
        
        return p;
    }, [visiblePages, props.total]);

    props.onChange = (pageIndex) => {
        onChange(pages[pageIndex - 1]);
    };
    
    const {
        Component,
        dotsJump,
        slots,
        classNames,
        total,
        range,
        loop,
        activePage,
        disableCursorAnimation,
        disableAnimation,
        renderItem: renderItemProp,
        onNext,
        onPrevious,
        setPage,
        getItemAriaLabel,
        getItemRef,
        getBaseProps,
        getWrapperProps,
        getItemProps,
        getCursorProps,
    } = usePagination({...props, ref, total: pages.length});


    const {direction} = useLocale();

    const isRTL = direction === "rtl";

    const renderItem = useCallback(
        (value, index) => {
        const isBefore = index < range.indexOf(activePage);

        if (renderItemProp && typeof renderItemProp === "function") {
            let page = typeof value == "number" ? value : index;

            if (value === PaginationItemType.NEXT) {
            page = activePage + 1;
            }

            if (value === PaginationItemType.PREV) {
            page = activePage - 1;
            }

            if (value === PaginationItemType.DOTS) {
            page = isBefore
                ? activePage - dotsJump >= 1
                ? activePage - dotsJump
                : 1
                : activePage + dotsJump <= total
                ? activePage + dotsJump
                : total;
            }

            const itemChildren = {
            [PaginationItemType.PREV]: <ChevronIcon />,
            [PaginationItemType.NEXT]: (
                <ChevronIcon
                className={slots.chevronNext({
                    class: classNames?.chevronNext,
                })}
                />
            ),
            [PaginationItemType.DOTS]: (
                <>
                <EllipsisIcon className={slots?.ellipsis({class: classNames?.ellipsis})} />
                <ForwardIcon
                    className={slots?.forwardIcon({class: classNames?.forwardIcon})}
                    data-before={dataAttr(isBefore)}
                />
                </>
            ),
            };

            return renderItemProp({
            value,
            index,
            key: `${value}-${index}`,
            page,
            total,
            children: typeof value === "number" ? value : itemChildren[value],
            activePage,
            dotsJump,
            isBefore,
            isActive: value === activePage,
            isPrevious: value === activePage - 1,
            isNext: value === activePage + 1,
            isFirst: value === 1,
            isLast: value === total,
            onNext,
            onPrevious,
            setPage,
            onPress: () => setPage(page),
            ref: typeof value === "number" ? (node) => getItemRef(node, value) : undefined,
            className: slots.item({class: classNames?.item}),
            getAriaLabel: getItemAriaLabel,
            });
        }

        if (value === PaginationItemType.PREV) {
            return (
            <PaginationItem
                key={PaginationItemType.PREV}
                className={slots.prev({
                class: classNames?.prev,
                })}
                data-slot="prev"
                getAriaLabel={getItemAriaLabel}
                isDisabled={!loop && activePage === (isRTL ? total : 1)}
                value={value}
                onPress={onPrevious}
            >
                <ChevronIcon />
            </PaginationItem>
            );
        }
        if (value === PaginationItemType.NEXT) {
            return (
            <PaginationItem
                key={PaginationItemType.NEXT}
                className={slots.next({
                class: clsx(classNames?.next),
                })}
                data-slot="next"
                getAriaLabel={getItemAriaLabel}
                isDisabled={!loop && activePage === (isRTL ? 1 : total)}
                value={value}
                onPress={onNext}
            >
                <ChevronIcon
                className={slots.chevronNext({
                    class: classNames?.chevronNext,
                })}
                />
            </PaginationItem>
            );
        }

        if (value === PaginationItemType.DOTS) {
            return (
            <PaginationItem
                key={PaginationItemType.DOTS + isBefore}
                className={slots.item({
                class: clsx(classNames?.item, "group"),
                })}
                data-slot="item"
                getAriaLabel={getItemAriaLabel}
                value={value}
                onPress={() =>
                isBefore
                    ? setPage(activePage - dotsJump >= 1 ? activePage - dotsJump : 1)
                    : setPage(activePage + dotsJump <= total ? activePage + dotsJump : total)
                }
            >
                <EllipsisIcon className={slots?.ellipsis({class: classNames?.ellipsis})} />
                <ForwardIcon
                className={slots?.forwardIcon({class: classNames?.forwardIcon})}
                data-before={dataAttr(isRTL ? !isBefore : isBefore)}
                />
            </PaginationItem>
            );
        }

        return (
            <PaginationItem {...getItemProps({value})} key={value} getAriaLabel={getItemAriaLabel}>
            {pages[value-1]}
            </PaginationItem>
        );
        },
        [
        isRTL,
        activePage,
        dotsJump,
        getItemProps,
        loop,
        range,
        renderItemProp,
        slots,
        classNames,
        total,
        ],
    );

    return (
        <Component {...getBaseProps()}>
        <ul {...getWrapperProps()}>
            {!disableCursorAnimation && !disableAnimation && <PaginationCursor {...getCursorProps()} activePage={pages[activePage-1]} />}
            {range.map(renderItem)}
        </ul>
        </Component>
    );
});

export default CustomPagination;