import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import store from "./store/store";
import { Provider } from "react-redux";
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './auth-config';

import { DarkModeProvider } from "@rbnd/react-dark-mode";
import { MsalProvider } from "@azure/msal-react";
import { NextUIProvider } from "@nextui-org/react";

const msalInstance = new PublicClientApplication(msalConfig);
const root = createRoot(document.getElementById("root"));

root.render(
    <DarkModeProvider>
      <NextUIProvider>
        <Provider store={store}>
          <MsalProvider instance={msalInstance}>
              <App />
          </MsalProvider>
        </Provider>
      </NextUIProvider>
    </DarkModeProvider>
);
