import { useContext } from "react";
import { CanvasContext } from "../../../../components/polygon-editor/contexts/canvas-context";
import { CanvasEventContext } from "../../../../components/polygon-editor/contexts/canvas-event-context";

export const SymbolsProvider = ({ 
  addSymbol,
  removeSymbol,
  children
}) => {
    const { canvasRef } = useContext(CanvasContext);

    const onShapeAdded = (shape) => {
      if (shape && !shape.isBackground) {
        shape.bringToFront();
        addSymbol(shape);
      }
    };
    
    const onShapeRemoved = (shape) => {
      if (shape && !shape.isBackground) {
        removeSymbol(shape);
      }
    };
    
    const onShapeSelected = (shape) => {
     
    };
    
    const onShapeDeselected = (shape) => {

    };
    
    const onShapeClicked = (shape) => {

    };

    return (
      <CanvasEventContext.Provider
        value={{
            onShapeAdded,
            onShapeRemoved,
            onShapeSelected,
            onShapeDeselected,
            onShapeClicked
        }}
      >
        {children}
      </CanvasEventContext.Provider>
    );
  };