import { createSlice } from "@reduxjs/toolkit";

const C2CAdminSlice = createSlice({
  name: "company",
  initialState: {
    company: [],
  },
  reducers: {
    addCompany(state, action) {
      state.company.push(action.payload);
    },
    updateCompany(state, action) {
      state.company.push(action.payload);
    },
  },
});

export const { addCompany, updateCompany } = C2CAdminSlice.actions;
export default C2CAdminSlice.reducer;
