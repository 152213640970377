import { Button, Chip, cn, Divider, Input, Radio, RadioGroup } from "@nextui-org/react";
import { unitMapping } from "./const";
import { useCallback, useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import AlertYesNo from "../../../components/modals/AlertYesNo";
import BPUAutocomplete from "./BPUAutocomplete";

const headers = ["bpu_type", "article_id", "article_name", "article_long_designation", "price", "quantite", "quantite_unit"];

export default function ArticleSelection({ ticketAnalysis, bpu }) {

    const [selectedArticles, setSelectedArticles] = useState([]);

    // Initialize selected articles based on ticket analysis
    useEffect(() => {
        if (ticketAnalysis && ticketAnalysis.line_info) {
            const initialSelectedArticles = ticketAnalysis.line_info.map(line => {
                const firstOption = line.options && line.options.length > 0 ? line.options[0] : null;
                return {
                    ...line,
                    selectedOption: firstOption,
                    quantite: line.quantite || 1,
                };
            });
            setSelectedArticles(initialSelectedArticles);
        } else {
            setSelectedArticles([]);
        }
    }, [ticketAnalysis]);

    // Handle selection change for each article using radio buttons
    const handleSelectionChange = (lineIndex, selectedOption) => {
        setSelectedArticles(prevState => {
            const newState = [...prevState];
            if (selectedOption.selectedBPUArticle) {
                newState[lineIndex] = {
                    ...prevState[lineIndex],
                    selectedBPUArticle: selectedOption.selectedBPUArticle
                };
            } else {
                newState[lineIndex] = {
                    ...prevState[lineIndex],
                    selectedOption: selectedOption,
                };
            }
            return newState;
        });
    };

    const handleQuantityChange = (lineIndex, quantite) => {
        setSelectedArticles(prevState => {
            const newState = [...prevState];
            newState[lineIndex] = {
                ...prevState[lineIndex],
                quantite: quantite,
                selectedOption: newState[lineIndex]?.selectedOption,
            };
            return newState;
        });
    };

    const handleRemoveArticle = (lineIndex) => {
        const removeArticle = (index) => {
            setSelectedArticles(prevState => {
                const newState = [...prevState];
                newState.splice(index, 1);
                return newState;
            });
        };

        AlertYesNo({
            title: 'Supprimer ?',
            body: 'Voulez-vous vraiment supprimer cet article ?',
            onYes: () => removeArticle(lineIndex),
            yesText: 'Supprimer',
            yesButtonProps: { color: 'danger' },
            noText: 'Annuler',
            noButtonProps: { color: 'default' },
        });
    };

    const handleAddArticle = () => {
        setSelectedArticles(prevState => {
            const newState = [...prevState];
            newState.push({
                article: 'Article manuel',
                quantite: 1,
                selectedOption: { description: 'Autres', isOtherOption: true },
            });
            return newState;
        });
    };

    const generateCSV = () => {
        const rows = selectedArticles
            .filter(article => article && article.selectedOption)
            .filter(article => !article.selectedOption.isOtherOption || article.selectedBPUArticle)
            .map(article => {
                let option = article.selectedOption;
                
                if (option.isOtherOption && article.selectedBPUArticle) {
                    option = article.selectedBPUArticle;
                }
                
                const bpu_type = option.code_contrat === 'ENTREPRISE' ? 'ENTREPRISE' : 'CONTRAT';
            
                return [
                    bpu_type,
                    option.article_id || '',
                    option.description || '',
                    option.description_longue || '',
                    option.montant || '',
                    article.quantite || 1,
                    option.unite || '',
                ];
            });
            
        const csvContent = [headers, ...rows]
            .map(e => e.join(','))
            .join('\n');
        
        navigator.clipboard.writeText(csvContent)
            .then(() => {
                console.log('CSV copied to clipboard');
            })
            .catch(err => {
                console.error('Could not copy text: ', err);
            });
    };

    return (
        <>
            <div className="flex justify-between items-center">
                <div className="flex flex-col">
                    <h2 className="text-2xl font-bold">Résultat de l'analyse</h2>
                    <p>{ticketAnalysis?.line_info?.length ? `${ticketAnalysis.line_info.length} articles identifiés` : "Aucun article détecté"}</p>
                </div>
                <AddArticleButton onClick={handleAddArticle} />
            </div>
            <Divider className="mb-4" />
            <div className="grid grid-cols-2 gap-4">
                {selectedArticles.map((article, index) => (
                    <Article
                        key={index}
                        article={article}
                        bpu={bpu}
                        index={index}
                        handleQuantityChange={handleQuantityChange}
                        handleRemoveArticle={handleRemoveArticle}
                        handleSelectionChange={handleSelectionChange}
                    />
                ))}
            </div>
            <div className="flex pt-4 justify-center">
                <ClipboardButton onClick={generateCSV} />
            </div>
        </>
    );
}

const AddArticleButton = ({ onClick }) => (
    <Button
        onClick={onClick}
        variant="flat"
        color="default"
        className="flex items-center gap-2"
    >
        <Icon icon="mdi:plus" width={24} height={24} />
        Ajouter un article
    </Button>
)

const Article = ({ index, article, bpu, handleQuantityChange, handleRemoveArticle, handleSelectionChange }) => {
    const handleRadioChange = useCallback((selectedOption) => {
        handleSelectionChange(index, selectedOption);
    }, [handleSelectionChange, index]);

    const handleAutocompleteChange = useCallback((key) => {
        const selectedArticle = bpu.find(item => String(item.id) === key);
        
        if (selectedArticle) {
            const formattedOption = {
                description: selectedArticle.label,
                code_contrat: selectedArticle.value.code_contrat,
                article_id: selectedArticle.id,
                montant: selectedArticle.value.montant,
                unite: selectedArticle.value.unite,
                description_longue: selectedArticle.value.description_longue,
                isOtherOption: true  // Keep this flag true
            };
            
            handleSelectionChange(index, {
                selectedBPUArticle: formattedOption
            });
        }
    }, [handleSelectionChange, index, bpu, article.selectedOption]);

    return (
        <div className="p-4 bg-content1 rounded-lg shadow-lg border border-content2">
            <ArticleHeader
                index={index}
                article={article}
                handleQuantityChange={handleQuantityChange}
                handleRemoveArticle={handleRemoveArticle}
            />
            <Divider className="my-2" />
            <ArticleRadioGroup
                article={article}
                bpu={bpu}
                selectedValue={article.selectedOption?.description || ''}
                handleRadioChange={handleRadioChange}
                handleAutocompleteChange={handleAutocompleteChange}
            />
        </div>
    );
};

const ArticleHeader = ({ article, index, handleQuantityChange, handleRemoveArticle }) => {
    return (
        <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold truncate capitalize" title={article.article}>{article.article}</h3>
            <div className="flex items-center">
                <QuantityInput
                    value={article.quantite || 1}
                    onChange={(value) => handleQuantityChange(index, value)}
                />
                <DeleteButton handleDelete={() => handleRemoveArticle(index)} />
            </div>
        </div>
    );
};

const QuantityInput = ({ value, onChange }) => {
    const handleDecrease = () => {
        if (value > 1) {
            onChange(value - 1);
        }
    };

    const handleIncrease = () => {
        onChange(value + 1);
    };

    const handleInputChange = (e) => {
        const newValue = parseInt(e.target.value, 10);
        if (!isNaN(newValue) && newValue >= 1) {
            onChange(newValue);
        }
    };

    return (
        <div className="flex items-center">
            <button onClick={handleDecrease} className="p-2">
                <Icon icon="mdi:minus" width={24} height={24} />
            </button>
            <input
                type="number"
                min="1"
                value={value}
                onChange={handleInputChange}
                className="mx-2 w-12 text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            />
            <button onClick={handleIncrease} className="p-2">
                <Icon icon="mdi:plus" width={24} height={24} />
            </button>
        </div>
    );
};

const DeleteButton = ({ handleDelete }) => (
    <button onClick={() => handleDelete()} className="p-2 ml-2">
        <Icon icon="solar:trash-bin-trash-outline" width={24} height={24} />
    </button>
)

const ArticleRadioGroup = ({ article, bpu, selectedValue, handleRadioChange, handleAutocompleteChange }) => {
    const articleOptions = article.options || [];
    const allOptions = [...articleOptions,  { 
        description: 'Autres', 
        id: 'autres', 
        isOtherOption: true 
    }];

    return (
        <RadioGroup
            value={selectedValue}
            onValueChange={(value) => {
                if (value === 'Autres') {
                    const autresOption = { 
                        description: 'Autres', 
                        id: 'autres', 
                        isOtherOption: true 
                    };
                    handleRadioChange(autresOption);
                } else {
                    // For standard options
                    const selectedOption = allOptions.find(option => option.description === value);
                    handleRadioChange(selectedOption);
                }
            }}
        >
            {allOptions.map((option, index) => (
                <ArticleRadioContainer key={index} option={option}>
                    { option.description !== 'Autres' ? (
                        <ArticleRadioContent option={option}/>
                    ) : (
                        <ArticleOtherRadioContent 
                            index={index} 
                            bpu={bpu} 
                            handleAutocompleteChange={handleAutocompleteChange} 
                            isDisabled={selectedValue !== 'Autres'}
                            selectedBPUArticle={article.selectedOption?.selectedBPUArticle}
                        />
                    )}
                </ArticleRadioContainer>
            ))}
        </RadioGroup>
    );
};

const ArticleRadioContainer = ({ option, children }) => {
    return (
        <Radio
            value={option.description}
            size="sm"
            classNames={{
                base: cn(
                    "flex-row-reverse justify-between max-w-full",
                    "m-0 bg-content1 hover:bg-content2",
                    "cursor-pointer rounded-lg gap-4 p-2 border-2 border-transparent",
                    "data-[selected=true]:border-primary"
                ),
                labelWrapper: cn("w-full pr-4"),
            }}
        >
            {children}
        </Radio>
    );
};

const ArticleRadioContent = ({ option }) => (
    <>
        <div className="flex justify-between items-center">
            {/* Left Section */}
            <span className="text-sm font-medium text-content-primary truncate capitalize">
                {option.description}
            </span>
            {/* Right Section */}
            <div className="flex items-center gap-4">
                <div className="flex items-center gap-1">
                    <span className="text-md font-medium">
                        {option.montant} {option.devise || "EUR"}
                    </span>
                    <span className="text-sm text-gray-600">
                        / {unitMapping[option.unite]}
                    </span>
                </div>
                <Chip
                    size="sm"
                    variant="flat"
                    color={option.code_contrat === "ENTREPRISE" ? "success" : "primary"}
                    className="font-bold"
                >
                    {option.code_contrat === "ENTREPRISE" ? "BPU Entreprise" : "BPU Contrat"}
                </Chip>
            </div>
        </div>
        {option.description_longue && (
            <>
                <Divider className="my-2" />
                <p className="text-xs text-default-400 mt-1">
                    {option.description_longue}
                </p>
            </>
        )}
    </>
);

const ArticleOtherRadioContent = ({index, bpu, handleAutocompleteChange, isDisabled}) => {
    return (
        <div className="flex justify-between items-center">
            <BPUAutocomplete
                isDisabled={isDisabled}
                bpu={bpu}
                handleAutocompleteChange={handleAutocompleteChange}
            />
        </div>
    )}

const ClipboardButton = ({ onClick }) => {
    return (
        <Button 
            onClick={onClick} 
            variant='flat'
            startContent={<Icon icon="solar:clipboard-outline" height={20} width={20} />}
        >
            Copier dans le presse-papiers
        </Button>
    );
}