import GeoRasterLayer from "georaster-layer-for-leaflet";
import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import chroma from 'chroma-js'; 
import { useSelector } from "react-redux";


var parseGeoraster = require("georaster");

const GeotiffLayer = ({ url,imageBounds }) => {
    const map = useMap()
    const projectGeoData = useSelector((state) => state.PYM.ProjectInfos);
    const cmap = projectGeoData.cmap ;

    fetch(url)
    .then(response => response.arrayBuffer())
    .then(arrayBuffer => {
      parseGeoraster(arrayBuffer).then(georaster => {
        console.log("georaster:", georaster);
  
        /*
            GeoRasterLayer is an extension of GridLayer,
            which means can use GridLayer options like opacity.
  
            Just make sure to include the georaster option!
  
            Optionally set the pixelValuesToColorFn function option to customize
            how values for a pixel are translated to a color.
  
            https://leafletjs.com/reference.html#gridlayer
        */
        const colorScale = chroma.scale(['gray', 'yellow']).domain([0, 10]);


        // // Fonction pour mapper les valeurs à des couleurs, rendant les NaN invisibles
        function valueToColor(value) {
          if (isNaN(value)) {
            return 'rgba(0,0,0,0)';
          } 
          else {
            for (let i = 0; i < cmap.length; i++) {
              if (value >= cmap[i].from && value <= cmap[i].to) {
                return cmap[i].color;
              }
            }
        }}
        
        const layerOptions = {
          georaster,
          pixelValuesToColorFn: valueToColor,
          resolution: 256 // Optionnel: ajustez pour améliorer les performances
        };

        var layer = new GeoRasterLayer(layerOptions);
        layer.addTo(map);
        map.fitBounds(layer.getBounds());
  
    });
  } );
    return null; 
};

export default GeotiffLayer;
