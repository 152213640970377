import { combineReducers } from "redux";
import ProjectVFSlice from "./ProjectVFSlice";
import ArrayBufferSlice from "./ArrayBufferSlice";
import ExtractEquipmentsSlice from "./ExtractEquipmentsSlice";
import ComparedEquipmentsSlice from "./ComparedEquipmentsSlice";

const OMIAReducer = combineReducers({
  ProjectVF: ProjectVFSlice,
  ArrayBuffer: ArrayBufferSlice,
  ExtractEquipments: ExtractEquipmentsSlice,
  ComparedEquipments: ComparedEquipmentsSlice
});

export default OMIAReducer;
