import { createSlice } from "@reduxjs/toolkit";

const ArrayBufferSlice = createSlice({
  name: "arrayBuffer",
  initialState: {
    pages: [],
  },
  reducers: {
    setArrayBuffer: (state, action) => {
      state.pages = action.payload;
    },
    clearArrayBuffer: (state) => {
      state.pages = [];
    },
  },
});

export const { setArrayBuffer, clearArrayBuffer } = ArrayBufferSlice.actions;
export default ArrayBufferSlice.reducer;
