import { createSlice } from "@reduxjs/toolkit";

const Decompte = createSlice({
  name: "decompte",
  initialState: {
    symbols:  [],
    metres:  [],
  },
  reducers: {
    setHits: (state, action) => {
      let { hits, metres } = action.payload;
      state.symbols = hits;
      state.metres = metres;
    },

    addCount: (state, action) => {
      state.symbols.push(action.payload);
    },

    removeHits: (state, action) => {
      state.symbols = state.symbols.filter(x=>!action.payload.includes(x.id))
    },

    addMetre: (state, action) => {
      state.metres.push(action.payload);
    },

    removeMetre: (state, action) => {
      state.metres = state.metres.filter(x=>!action.payload.includes(x.id))
    },
    
  },
});

export const { addCount, setHits, removeHits, addMetre, removeMetre } = Decompte.actions;
export default Decompte.reducer;
