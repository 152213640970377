import { useState } from "react";
import Alert from "../../../../../components/modals/Alert";
import { INTELIA_API_URL } from "../../const"

// import { EXEMPLE_TICKET, EXEMPLE_TICKET_ANALYSIS } from "../../exemples"

export default function useTicket() {
    const [isGettingTicket, setIsGettingTicket] = useState(false);
    const [isAnalyzingTicket, setIsAnalyzingTicket] = useState(false);
    const [ticketForm, setTicketForm] = useState({
        localTicketCode: "",
    });
    const [ticket, setTicket] = useState(null);
    const [ticketAnalysis, setTicketAnalysis] = useState(null);

    const getTicket = async () => {
        setIsGettingTicket(true);
        setTicket(null);
        setTicketAnalysis(null);
        try {
            if (ticketForm.localTicketCode.length === 0) {
                Alert("Erreur", "Le numéro de DS est requis");
                return;
            }
            if (ticketForm.localTicketCode.length < 12) {
                Alert("Erreur", "Le numéro de DS doit au moins contenir 12 caractères");
                return;
            }
            const response = await fetch(`${INTELIA_API_URL}/v2/ticket/${ticketForm.localTicketCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            
            const data = await response.json()
            // const data = EXEMPLE_TICKET
            if(Object.keys(data.data).length === 0) {
                Alert("Erreur", "Le ticket n'a pas été trouvé. Veuillez vérifier le numéro de DS.")
            }
            
            setTicket((prev) => ({ ...prev, ...data.data[0] }));
            return { ...ticket, ...data.data[0] }
        }
        catch (error) {
            Alert("Erreur", "Une erreur s'est produite lors de la récupération du ticket. merci de contacter le support DIANE.")
            console.error(error)
        }
        finally {
            setIsGettingTicket(false);
        }
    }

    const analyseTicket = async () => {
        setIsAnalyzingTicket(true);
        setTicketAnalysis(null);
        try {
            if (!ticket.ticketTechnicianRemarks || ticket.ticketTechnicianRemarks.length === 0) {
                Alert("Erreur", "Le compte rendu est requis pour analyser la demande de service.");
                return;
            }
            const editedTicket = {
                ...ticket,
                companyCode: parseInt("95" + ticketForm.localTicketCode[2] + ticketForm.localTicketCode[3])
            }
            console.log("Edited Ticket: ", editedTicket)

            const response = await fetch(`${INTELIA_API_URL}/v2/extraction`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedTicket)
            }
            )
            const data = await response.json()
            // const data = EXEMPLE_TICKET_ANALYSIS
            
            setTicketAnalysis(data)
            console.log("Ticket Analysis: ", data)
            return data
        } catch (error) {
            Alert("Erreur", "Une erreur s'est produite lors de l'analyse du ticket. merci de contacter le support DIANE.")
        }
        finally {
            setIsAnalyzingTicket(false);
        }
    }

    return {
        ticketForm,
        setTicketForm,

        ticket,
        setTicket,
        getTicket,
        
        ticketAnalysis,
        setTicketAnalysis,
        analyseTicket,

        isGettingTicket,
        isAnalyzingTicket
    }
}