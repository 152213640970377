import React, { cloneElement, forwardRef, useCallback, useMemo, useState } from "react";
import './widgets.css';
import { button } from "@nextui-org/react";

const WidgetNavigator = ({
  children
}) => {
  const [isOpened, setIsOpened] = useState(false);

    return (<>
      {children !== undefined && children.length === undefined && 
        cloneElement(children, {
          id: `hamburger`,
          classNames: {
            icon: "!text-white !scale-[1.8]"
          }
        })
      }
      {children !== undefined && children.length >= 2 && 
        <div id="hamburger" className={isOpened ? "show" : ""} onClick={() => {
          setIsOpened(!isOpened);
        }}>
          <div id="wrapper">
            <span className="icon-bar" id="one"></span>
            <span className="icon-bar" id="two"></span>
            <span className="icon-bar" id="thr"></span>
          </div>
        </div>}
        {children !== undefined && children.length >= 2 && children.slice(0, 5).map((c, i) => <Layer key={i} index={i} isOpened={isOpened} total={children.length}>{c}</Layer>)}
    </>);
};
const Layer = forwardRef((props, ref) => {
  const elem = cloneElement(props.children, {
      classNames: {
        button: (props.isOpened ? "nav show" : "nav")
      },
      id: `nav-${Math.max(2, Number.parseInt(props.total))}-${Number.parseInt(props.index)+1}`,
      tooltipPlacement: Number.parseInt(props.index)+1 >= 4 || (Number.parseInt(props.index)+1 >= 3 && (Number.parseInt(props.total) === 3 || Number.parseInt(props.total) === 4)) ? "top" : "left",
      ref: ref
  });
  return elem;
});

export default WidgetNavigator;