import { Card } from "@nextui-org/card";
import { Button } from "@nextui-org/react";
import BrokenPageImage from "./broken-page-image";
import WidgetNavigator from "../widgets/navigator";
import { FeedbackWidget, HomeWidget } from "../widgets/widgets";

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
    const handleReload = () => {
        window.location.reload(); // Reloads the current page
      };
    
      return (
        <div className="flex flex-col items-center justify-start pt-6 h-full overflow-hidden">
            <BrokenPageImage className="scale-125" />
            <div className="text-center">
                <h1 className="text-black font-bold text-3xl">
                    Ouuups ! Quelque chose s'est mal passé.
                </h1>
                <h2 className="text-gray-600 mt-2">
                    Il semble qu'une erreur soit survenue. Pas de panique, vous pouvez essayer de recharger la page !
                    <br />L'erreur a été transmise à nos développeurs.
                </h2>
                <Button
                    auto
                    color="secondary"
                    shadow
                    className="mt-6 shadow-lg"
                    onClick={handleReload}
                >
                    Recharger la page
                </Button>
            </div>
            <WidgetNavigator>
                <HomeWidget />
                <FeedbackWidget />
            </WidgetNavigator>
        </div>
      );
};