import { createSlice } from "@reduxjs/toolkit";
  
const AuthSlice = createSlice({
    name: 'auth',
    initialState: {
        userRoles: null,
        permissions: [],
    },
    reducers: {
        setUserRoles: (state, action) => {
            state.userRoles = action.payload;
        },
        setPermissions: (state, action) => {
            state.permissions = action.payload;
        },
    },
});

export const { setUserRoles, setPermissions } = AuthSlice.actions;
export default AuthSlice.reducer;
