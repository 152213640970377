import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./error-fallback";
import useAzureFuncApp from "../../hooks/azure/useAzureFuncApp";
import config from "../../config";

const CustomErrorBoundary = ({ children }) => {
    const { launch_request } = useAzureFuncApp();

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                console.log("Reset triggered");
            }}
            onError={async (error, info) => {
                // Send GitHub project error
                var payload = {
                  error_received: error.toString(),
                  stacktrace: error.stack,
                  url: window.location.href,
                  current_page: window.location.href,
                };
                launch_request(config.apiErrorReportUri, payload, {
                    alertError: false
                });
            }}
        >
            {children}
        </ErrorBoundary>
    );
};

export default CustomErrorBoundary;