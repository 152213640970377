import React from "react";
import { Image, Skeleton} from "@nextui-org/react";

import {cn} from "../../../../utils/cn";
const extensions = ['tif','xlsx','json','pdf']
function getExtensionImage(extension){
  switch (extension) {
    case 'tif':
      return '/images/files/tiff.png';
    case 'xlsx':
      return  '/images/files/xlsx.png';
    case 'json':
      return  '/images/files/json.png';
    default:
      return  '/images/files/pdf.png'
  }  }


const FileItem = React.forwardRef(
  (
    {name, isLoading, imageSrc,handleImageClick, removeWrapper, className, ...props},
    ref,
  ) => {
    let [fileName,extension] = name.split('.');
    
    return (
      <div
        ref={ref}
        className={cn(
          "relative flex w-full flex-none flex-col gap-3",
          {
            "rounded-none bg-background shadow-none": removeWrapper,
          },
          className,
        )}
        {...props}
      >
        <Image
          isBlurred
          alt={fileName}
          className="aspect-square w-full"
          src={extensions.includes(extension) ? getExtensionImage(extension) : imageSrc}
          onClick={(event)=>{handleImageClick(event,name,imageSrc)}}
        />

        <div className="mt-1 flex flex-col gap-2 px-1">
          {isLoading ? (
            <div className="my-1 flex flex-col gap-3">
              <Skeleton className="w-3/5 rounded-lg">
                <div className="h-3 w-3/5 rounded-lg bg-default-200" />
              </Skeleton>
              <Skeleton className="mt-3 w-4/5 rounded-lg">
                <div className="h-3 w-4/5 rounded-lg bg-default-200" />
              </Skeleton>
              <Skeleton className="mt-4 w-2/5 rounded-lg">
                <div className="h-3 w-2/5 rounded-lg bg-default-300" />
              </Skeleton>
            </div>
          ) : (
            <>
              <div className="flex items-start justify-center gap-1">
                <h3 className="text-small font-medium text-default-700">{fileName}</h3>
              </div>
            </>
          )}
        </div>
      </div>
    );
  },
);

FileItem.displayName = "FileItem";

export default FileItem;
