import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: [],
  },
  reducers: {
    addUser(state, action) {
      state.user.push(action.payload);
    },
    updateUser(state, action) {
      state.user.push(action.payload);
    },
    // Ajoutez d'autres reducers au besoin
  },
});

export const { addUser, updateUser } = userSlice.actions;
export default userSlice.reducer;
