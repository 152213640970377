import { createSlice } from "@reduxjs/toolkit";

const PDF2RevitReducer = createSlice({
  name: "configuration",
  initialState: {
    images: [],
    reperes: [],
    calibrationScale: 1,
    displayPDFEditor: false,
  },
  reducers: {
    setImages: (state, action) => {
      state.images = action.payload;
      state.reperes = Array(state.images.length).fill({1 : undefined, 2 : undefined});
    },

    addPointStore: (state, action) => {
      let { repere_id, position, page } = action.payload;
      state.reperes[page-1][repere_id] = position;
    },

    setDisplayPDFEditor: (state, action) => {
      state.displayPDFEditor = action.payload;
    },

  },
});

export const { setImages, addPointStore, setDisplayPDFEditor } = PDF2RevitReducer.actions;
export default PDF2RevitReducer.reducer;
