import { combineReducers } from "redux";
import enterpriseSlice from "./formEntrepriseSlice";
import userSlice from "./formUserSlice";

const VEFTIDFReducer = combineReducers({
  enterprise: enterpriseSlice,
  user: userSlice,
});

export default VEFTIDFReducer;
