import { createSlice } from "@reduxjs/toolkit";

const ProjectVFSlice = createSlice({
  name: "form_new_project",
  initialState: {
    form_new_project: [],
    project_name: ""
  },
  reducers: {
    add_new_project: (state, action) => {
      state.form_new_project.push(action.payload);
    },
    set_project_name: (state, action) => {
      state.project_name = action.payload;
    }
  },
});

export const { add_new_project, set_project_name } = ProjectVFSlice.actions;
export default ProjectVFSlice.reducer;
