import React, { Suspense } from "react";
import { Spinner } from "@nextui-org/react";

import LeafletMap from "../pages/services/Pym/map";
// import Calculs from "../pages/services/Pym/Calculs/Calculs";
 import Calculs from "../pages/services/Pym/calculs/index"
import Luminaire from "../pages/services/Pym/Luminaire";
import Voiries from "../pages/services/Pym/voiries";
import FileExplorer from "../pages/services/Pym/file-explorer";
import FileIesLdt from "../pages/services/Pym/file-ies-ldt";

import ProtectedRoute from "../components/login/protected-route";
import Definition from "../pages/services/Pym/definition";
import Optimisation from "../pages/services/Pym/optimisation";

const PYM = React.lazy(() => import("../pages/services/Pym/index"));
const PymMap = React.lazy(() => import("../pages/services/Pym/side-bar/index"));
const Settings = React.lazy(() =>
  import("../pages/services/Pym/settings/settings")
);

const router = {
  path: "/",
  element: "",
  children: [
    {
      path: "/solux",
      element: (
        <ProtectedRoute roles={["Plateforme.Service5", "Plateforme.FullAccess"]}>
            <PYM />
        </ProtectedRoute>
      ),
    },
    {
      path: "/solux-map",
      element: (
        <ProtectedRoute roles={["Plateforme.Service5", "Plateforme.FullAccess"]}>
          <PymMap />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "accueil",
          element: <LeafletMap />,
        },
        {
          path: "luminaires",
          element: "",
          children: [
            {
              path: ":source",
              element: <Luminaire />,
            },
          ],
        },
        {
          path: "calculs",
          element: <Calculs />,
        },
        {
          path: "voiries",
          element: <Voiries />,
        },

        {
          path: "optimisation",
          element: <Optimisation/>,
        },
        {
          path: "verification",
          element: <div>ICI C LES VERIFS</div>,
        },
        {
          path: "definition",
          element: <Definition/>,
        },
        {
          path: "fichiers",
          element: <FileExplorer/>,
        },
        {
          path: "fichiers-ies-ldt",
          element: <FileIesLdt/>,
        },
        {
          path: "settings",
          element: <Settings />,
        },
      ],
    },
  ],
};

export default router;
