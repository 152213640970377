import { combineReducers } from "redux";
import ProjectInfoSlice from "./project-info-slice";
import Configuration from "./configuration";
import Decompte from "./decompte";

const ComptageReducer = combineReducers({
  ProjectInfos: ProjectInfoSlice,
  Configuration: Configuration,
  Decompte: Decompte,
});

export default ComptageReducer;
