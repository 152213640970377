import { useDispatch, useSelector } from 'react-redux';
import {
  selectImageHeight,
  selectImagePreview,
  selectImageWidth,
  setImageHeight,
  setImagePreview,
  setImageWidth,
} from '../../../../store/components/PolygonEditor/ImageSlice';
import styles from './imageView.module.css';
import { useContext, useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import { CanvasContext } from '../../contexts/canvas-context';
import useDimensions from '../../hooks/dimensions';

/**
 * ImageView component for the image app editor.
 *
 * This component contains the canvas where images are displayed and manipulated.
 * On load, it initializes the canvas with a blank canvas and sets the `canvasRef` value in the CanvasContext.
 * When a user uploads an image, it sets that image as the canvas background and resizes the canvas to fill 80% of the window width.
 *
 * @returns A React component that renders the canvas element.
 */
export default function ImageView({
  backgroundColor="#D3D3D3"
}) {
  const imagePreview = useSelector(selectImagePreview);
  const { canvasRef, getCanvasAtResolution } = useContext(CanvasContext);
  const dispatch = useDispatch();
  const { width, height } = useDimensions();
  const imageHeight = useSelector(selectImageHeight);
  const imageWidth = useSelector(selectImageWidth);
  const containerRef = useRef();

  /**
   * Effect to resize the canvas when the image preview is updated or the window width changes.
   */
  useEffect(() => {
    if (containerRef?.current) {
      const containerWidth = containerRef?.current.clientWidth;
      const containerHeight = containerRef?.current.clientHeight;
      getCanvasAtResolution(containerWidth, containerHeight);
    }
    
  }, [width, height, imageHeight, imageWidth, imagePreview]);

  /**
   * Effect to initialize the Fabric.js canvas on component mount.
   */
  useEffect(() => {
    if (canvasRef && !canvasRef.current) {
      canvasRef.current = new fabric.Canvas('canvas', {
        backgroundColor: backgroundColor
      });
      canvasRef.current.preserveObjectStacking = true;
      canvasRef.current.hoverCursor = 'move';  // Sets a move cursor for hover over any object
      canvasRef.current.defaultCursor = 'default';  // Sets the default canvas cursor
      
      canvasRef.current.setWidth(window.innerWidth * 0.8);
      canvasRef.current.setHeight(window.innerHeight - 100);
      dispatch(setImagePreview(''));
    }

    if (containerRef?.current) {
      const containerWidth = containerRef?.current.clientWidth;
      const containerHeight = containerRef?.current.clientHeight;
      getCanvasAtResolution(containerWidth, containerHeight);
    }
  }, []);

  /**
   * Effect to set the uploaded image as the canvas background and resize the canvas.
   */
  useEffect(() => {
    if (canvasRef?.current && imagePreview) {
      fabric.Image.fromURL(imagePreview, (img) => {
        canvasRef.current?.setBackgroundImage(img, canvasRef.current.renderAll.bind(canvasRef.current));
        var image = new Image();
        image.src = imagePreview;

        image.onload = function () {
          dispatch(setImageHeight(image.height));
          dispatch(setImageWidth(image.width));
          const containerWidth = window.innerWidth * 0.8; // 80% of window width
          const imageAspectRatio = image.width / image.height;
          const canvasWidth = containerWidth > image.width ? image.width : containerWidth;
          const canvasHeight = canvasWidth / imageAspectRatio;
          canvasRef.current.setWidth(canvasWidth);
          canvasRef.current.setHeight(canvasHeight);
          canvasRef.current.renderAll();
          canvasRef.current.setBackgroundImage(imagePreview, canvasRef.current.renderAll.bind(canvasRef.current), {
            top: 0,
            left: 0,
            originX: 'left',
            originY: 'top',
            backgroundImageOpacity: 1,
            scaleX: 1,
            scaleY: 1,
          });
        };
      });
    }
  }, [imagePreview]);

  return (
    <div className="w-full h-full" ref={containerRef}>
      <canvas id="canvas" className={styles.baseImage} />
    </div>
  );
}
