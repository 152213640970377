import React from "react";

import {cn} from "../../../../utils/cn";

const FileExplorerHeader = React.forwardRef(
  ({page, paginate, onOpen, className, ...props}, ref) => {
    return (
      <header
        className={cn("flex w-full items-center justify-between px-3 py-3 sm:px-6", className)}
        {...props}
        ref={ref}
      >
      </header>
    );
  },
);

FileExplorerHeader.displayName = "FileExplorerHeader";

export default FileExplorerHeader;
