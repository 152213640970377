import { createSlice } from "@reduxjs/toolkit";

const ProjectInfoSlice = createSlice({
  name: "project_infos",
  initialState: {
    project_path:"",
    project_name: "",
    creator_name: "",
    status: "",
    creation_date: "",
    software: "",
    epsg: "",
    cmap:"",
    axes_colors:"",
  },
  reducers: {
    setPymProjectInfos: (state, action) => {
      const { project_path,project_name, creator_name, status, creation_date, software, epsg,cmap,axes_colors} = action.payload;
      state.project_path = project_path;
      state.project_name = project_name;
      state.creator_name = creator_name;
      state.status = status;
      state.creation_date = creation_date;
      state.software = software;
      state.epsg = epsg;
      state.cmap=cmap;
      state.axes_colors=axes_colors;
    },
    updateCMapData: (state, action) => {
      state.cmap = action.payload;
    },
    updateAxesColors: (state, action) => {
      state.axes_colors = action.payload;
    },
  },
});

export const { setPymProjectInfos,updateCMapData, updateAxesColors} = ProjectInfoSlice.actions;
export default ProjectInfoSlice.reducer;
