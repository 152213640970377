import { Button,  Modal, ModalBody, ModalContent, ModalFooter, ModalHeader,Image} from "@nextui-org/react";
import { Icon } from "@iconify/react";

export default function FileFocusModal({ isOpen,onConfirm,onDecline,srcPath,nameModal,blobPath }) {
    let needMap = nameModal.includes('.tif')
    return (
        <Modal 
        isOpen = {isOpen}
        onClose={onDecline}
        backdrop='blur'
        size="2xl"
        placement = "center"> 
        <ModalContent>
            <ModalHeader className="flex items-center gap-1">
                <Icon icon="solar:magnifer-zoom-in-broken"  style={{ color: '#8ecae6' }} />
                {nameModal}
            </ModalHeader>
            <ModalBody>
                <Image
                    className="aspect-square w-full "
                    src={srcPath}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="danger" variant="light" onClick={onDecline}>
                    Retour
                </Button>
                <Button color="primary" onClick={() => {onConfirm(needMap,nameModal,blobPath)}} >
                    {needMap ? "Voir sur la carte":"Télecharger"}
                </Button>
            </ModalFooter>
    </ModalContent>
    </Modal>
    )
}