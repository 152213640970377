import { useMemo, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import GlassInput from "../inputs/glass-input";
import GlassTextarea from "../inputs/glass-text-are";
import { Button } from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";
import useAzureFuncApp from "../../hooks/azure/useAzureFuncApp";
import useAzureBlobs from "../../hooks/azure/useAzureBlobs";
import { useEntraUser } from "../../EntraUserProvider";
import config from "../../config";
import Alert from "../modals/Alert";

export default function ContactComponent({ showHome=true, setIsLoading=undefined, setLoadingMessage=undefined, closeEvent=undefined }) {
    const navigate = useNavigate();
    const { launch_request } = useAzureFuncApp();  
    const { uploadFile } = useAzureBlobs();
    const { userData } = useEntraUser();
    const [formData, setFormData] = useState({
      name: userData?.lastname || '',
      prenom: userData?.firstname || '',
      email: userData?.email || '',
      objet: '',
      message: '',
      file: null
    });

    const sendDisabled = useMemo(() => {
      // return (
      //   formData.name === undefined || formData.name.trim() === ""
      //   || formData.prenom === undefined || formData.prenom.trim() === ""
      //   || formData.email === undefined || formData.email.trim() === ""
      //   || formData.objet === undefined || formData.objet.trim() === ""
      //   || formData.message === undefined || formData.message.trim() === ""
      // );
      return (
        formData.email === undefined || formData.email.trim() === ""
        || formData.objet === undefined || formData.objet.trim() === ""
        || formData.message === undefined || formData.message.trim() === ""
      );
    }, [formData]);
    const containerName = "contact";
  
    async function sendMessage(e) {
      let datetmp = Date.now();
      let date = new Date(datetmp);
  
      let folder = String(date.getYear() % 100).padStart(2, "0") + String(date.getMonth() + 1).padStart(2, '0') + String(date.getDate()).padStart(2, "0")
      if (formData.file !== undefined && formData.file !== null && formData.file.name !== "") {
        await uploadFile(containerName, folder + "/" + userData.email_nickname + "/" + datetmp.toString() + "/" + formData.file.name , formData.file, 'Diane');
      }

      const payload = {
        "Firstname": formData.prenom,
        "LastName": formData.name,
        "Email": formData.email,
        "Subject": formData.objet,
        "Message": formData.message,
        "HasAttachment": formData.file !== null,
        "BlobFolder": `${folder}/${userData.email_nickname}/${datetmp}`,
        "AttachmentName": formData.file ? formData.file.name : ""
      };
      
      if (setLoadingMessage !== undefined) {
        setLoadingMessage("Envoi du message en cours ...");
      }
      
      if (setIsLoading !== undefined) {
        setIsLoading(true);
      }

      let [status_code, result] = await launch_request(
        config.apiContactUri,
        payload
      );
      
      if (setIsLoading !== undefined) {
        setIsLoading(false);
      }
  
      if (status_code === 200)
      {
        Alert("Message envoyé !", undefined, {
          closeEvent: closeEvent
        });
      }
    };

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        if (e.target.type === "file") {
          value = e.target.files[0];
        }
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };
  
    return (
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-4 pb-4 text-left text-3xl font-semibold">
          <Icon icon="wpf:message-outline" />
          <span>Contactez-nous</span>
        </div>
        <div className="flex flex-row gap-10">
          <div className="form-container flex-auto">
            <form className="flex flex-col gap-4" onSubmit={sendMessage}>
              <GlassInput
                label="Nom"
                name="name"
                // labelPlacement="outside"
                placeholder="Entrez votre nom"
                value={formData?.name}
                onChange={handleInputChange}
                isInvalid={formData?.name === undefined || formData?.name.trim() === ""}
                type="text"
              />
              <GlassInput
                label="Prénom"
                name="prenom"
                // labelPlacement="outside"
                placeholder="Entrez votre prénom"
                value={formData?.prenom}
                onChange={handleInputChange}
                isInvalid={formData?.prenom === undefined || formData?.prenom.trim() === ""}
                type="text"
              />
              <GlassInput
                isRequired
                label="Email"
                name="email"
                // labelPlacement="outside"
                placeholder="Entrez votre email"
                value={formData?.email}
                onChange={handleInputChange}
                isInvalid={formData?.email === undefined || formData?.email.trim() === ""}
                type="email"
              />
              <GlassInput
                name="objet"
                label="Objet"
                // labelPlacement="outside"
                placeholder="Objet"
                value={formData?.objet}
                onChange={handleInputChange}
                isInvalid={formData?.objet === undefined || formData?.objet.trim() === ""}
                type="text"
              />
              <GlassTextarea
                isRequired
                label="Message"
                // labelPlacement="outside"
                name="message"
                placeholder="Entrez votre message"
                value={formData?.message}
                isInvalid={formData?.message === undefined || formData?.message.trim() === ""}
                onChange={handleInputChange}
              />
              <GlassInput
                name="file"
                label="Envoyer un fichier"
                placeholder="Ajoutez un fichier"
                onChange={handleInputChange}
                type="file"
              />
    
              <Button
                color="danger"
                variant="flat"
                // className="glass validation"
                onPress={sendMessage}
                isDisabled={sendDisabled}
              >
                Envoyer
              </Button>
            </form>
          </div>
          <div className="map-container">
            <p className="text-center mb-4">
              <span className="flex items-center justify-center mb-2">
                <Icon icon="lucide:map-pin" className="scale-150" />
                <span><span className="font-bold">DIANE</span><br />6 place du Colonel Bourgoin, <br /> 75012 Paris <br />{" "}
                Email : <a href="mailto:support.diane@vinci-energies.com" className="underline" title="support.diane@vinci-energies.com">support.diane@vinci-energies.com</a></span>
              </span>
            </p>
            <iframe
              title="map"
              width="350"
              height="300"
              id="gmap_canvas"
              src="https://maps.google.com/maps?width=488&amp;height=417&amp;hl=en&amp;q=Leonard,%206%20place%20du%20Colonel%20Bourgoin%20PARIS+(L%C3%A9onard%20)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </div>
        </div>
        <div className="flex justify-center">
          {showHome && 
            <Button onClick={(e) => {e.preventDefault(); navigate("/");}} variant="flat" className="w-[50%]">
              Retour à l'accueil
            </Button>}
        </div>
      </div>);
  };