import { combineReducers } from "redux";

import VEFTIDFReducer from "./features/VEFTIDF/index";
import C2CReducer from "./features/C2C/index";
import FileReducer from "./features/PDF/FileSlice";
import AuthReducer from "./features/AuthSlice";
import PYMReducer from "./features/PYM/index";
import ComptageReducer from "./features/Comptage";
import OMIAReducer from "./features/OMIA";
import ClauseControlReducer from "./features/ClauseControl";
import ImageSlice from "./components/PolygonEditor/ImageSlice";
import CanvasSlice from "./components/PolygonEditor/CanvasSlice";
import PDF2RevitReducer from "./features/PDF/PDF2Revit";

const rootReducer = combineReducers({
  Auth: AuthReducer,
  VEFTIDF: VEFTIDFReducer,
  C2C: C2CReducer,
  file: FileReducer,
  PYM: PYMReducer,
  OMIA: OMIAReducer,
  ClauseControl: ClauseControlReducer,
  image: ImageSlice,
  canvas: CanvasSlice,
  Comptage: ComptageReducer,
  PDF2Revit: PDF2RevitReducer,
});

export default rootReducer;
