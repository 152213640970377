import { Icon } from "@iconify/react/dist/iconify.js";
import {Badge, Button, Card, CardFooter, Chip, Image, Spacer, Tooltip} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

export default function ServiceCard({
    title,
    description=undefined,
    isFavorite=false,
    imageSrc="/images/comparaison_indice_plan.png",
    href="/",
    className="",
    allowFavorite=false,
    setFavorite = (item) => {}
}) {
  let navigate = useNavigate();
  
  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Card
      isPressable
      isFooterBlurred
      radius="md"
      isBlurred={true}
      className={`border-none min-w-fit bg-transparent w-[23%] justify-around items-center shadow-xl active:shadow-lg cursor-pointer max-h-[20vh] ${className}`}
      style={{
        background: "rgba(255, 255, 255, 0.25)",
        backdropFilter: "none",
        border: "1px solid rgba(255, 255, 255, 0.1)",
      }}
      onPress={() => handleNavigate(href)}
    >
      {description !== undefined && <Tooltip
        key={title}
        placement="right"
        content={description}
        color="danger"
        showArrow
        size="sm"
        className="max-w-[200px] text-center"
        classNames={{
          base: [
            // arrow color
            "before:bg-white dark:before:bg-white",
          ],
          content: [
            "py-2 px-4 shadow-xl",
            "text-black bg-white",
          ],
        }}>
        <Chip
          size="sm"
          variant="shadow"
          className="absolute scale-120 top-2 right-2 cursor-help z-10"
          classNames={{
            base: "bg-white border-small border-white/50 shadow-black/30",
            content: "drop-shadow shadow-black text-black",
          }}>i</Chip>
      </Tooltip>}
      {allowFavorite && (<Tooltip
        placement="top"
        content={isFavorite ? "Retirer des favoris" : "Ajouter aux favoris"}
        color="danger"
        showArrow
        size="sm"
        className="max-w-[200px] text-center"
        classNames={{
          base: [
            // arrow color
            "before:bg-white dark:before:bg-white",
          ],
          content: [
            "py-2 px-4 shadow-xl",
            "text-black bg-white",
          ],
      }}>
        <Button 
          isIconOnly
          className="absolute top-2 left-2 cursor-pointer scale-150 w-fit min-w-0 h-fit min-h-0 bg-transparent"
        >
          <Icon
            icon={isFavorite ? "solar:star-bold" : "solar:star-line-duotone"}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          />
        </Button>
      </Tooltip>)}
      <Image
        alt="/images/comparaison_indice_plan.png"
        className="pt-6 px-6 object-contain mx-auto w-full max-w-full max-h-[15vh] z-1"
        src={imageSrc}
      />
      <CardFooter
        className="text-small justify-around backdrop-filter-none bg-transparent"
      >
        <span title={title} className="font-bold overflow-hidden break-before-right whitespace-nowrap">{title}</span>
      </CardFooter>
    </Card>
  );
}