import { createSlice } from "@reduxjs/toolkit";

const C2CSlice = createSlice({
  name: "C2C",
  initialState: {
    C2C: [],
  },
  reducers: {
    submitC2C(state, action) {
      state.C2C.push(action.payload);
    },
  },
});

export const { submitC2C } = C2CSlice.actions;
export default C2CSlice.reducer;
