import React, { useMemo, useState } from "react";
import EditableTable from "../../../../components/table/editable-table";
import { Spacer, Tab, Tabs, Image } from "@nextui-org/react";
import { useSelector } from "react-redux";
import { getPolylineLength, intersectionLengthLinePolygon, isPointInPolygon } from "../../../../utils/geometry";
import { useOutletContext } from "react-router-dom";

export default function Nomenclature () {
  const {isLoading, setIsLoading, loadingMessage, setLoadingMessage} = useOutletContext();

  const symbols = useSelector((state) => state.Comptage.Configuration.symbols);
  const linears = useSelector((state) => state.Comptage.Configuration.linears);
  const hits = useSelector((state) => state.Comptage.Decompte.symbols);
  const metres = useSelector((state) => state.Comptage.Decompte.metres);
  const zones = useSelector((state) => state.Comptage.Configuration.zones);
  const calibration = useSelector((state) => state.image.calibration);
  const selectedPages = useSelector((state) => state.Comptage.Configuration.pages.map((x, i)=>{ return [i, x.selected];}).filter(x=>x[1] === true).map(x=>x[0]));

  const groupBy = (arr, properties) => {
    const groups = [];
    const groupMap = new Map();
  
    arr.forEach(item => {
      let currentLevelMap = groupMap;
  
      properties.forEach((property, index) => {
        let key = item[property];
  
        let nextLevelMap = currentLevelMap.get(key);
  
        if (!nextLevelMap) {
          nextLevelMap = index === properties.length - 1 ? [] : new Map();
          currentLevelMap.set(key, nextLevelMap);
        }
  
        if (index === properties.length - 1) {
          nextLevelMap.push(item);
          // Only push to the final result array if it's the last property
          if (!groups.includes(nextLevelMap)) {
            groups.push(nextLevelMap);
          }
        } else {
          currentLevelMap = nextLevelMap;
        }
      });
    });
  
    return groups;
  };
  const dataSymbols = useMemo(() => {
    const itemsInZones = hits.map((x, idx) => {
      let zone = zones.find(z => z.page === x.page && isPointInPolygon(z.points, x));
      return {...x, zone: zone ? zone.name : undefined};
    });
    let groupedHits = groupBy(itemsInZones, ["crop_id", "page", "zone"]);
    let nomenclature = groupedHits.map((x, idx) => {
      let symb = symbols.find(s=>s.crop_id === x[0].crop_id);
      if (symb) {
        let confidenceRate = symb?.confidence_rate ?? [.3, 1];
        return ({
          id: idx,
          photo: symb.src,
          crop_id: x[0].crop_id,
          name: symb.name,
          page: x[0].page,
          zone: x[0].zone,
          quantity: x.filter(y => y["confidence_rate"] >= confidenceRate[0] && y["confidence_rate"] <= confidenceRate[1]).length
        });
      } else {
        return {};
      };
    }).filter(x => selectedPages.includes(x.page - 1));

    return nomenclature;
  }, [symbols, hits, zones, selectedPages]);

  const [filtersSymbols, setFiltersSymbols] = useState([]);

  const columnsSymbols = useMemo(() => [
    {
      uid: 'photo',
      name: '',
      isEditable: false,
      type: 'image',
      renderCell: (item) => {
        return <Image className="flex rounded-none cursor-pointer max-h-[50px] max-w-[200px]"
                      src={item.photo}
                />
      }
    },
    {
      uid: 'name',
      name: 'Nom du symbole',
      isEditable: false,
      type: 'text',
    },
    {
      uid: 'page',
      name: 'Page',
      isEditable: false,
      type: 'number',
    },
    {
      uid: 'zone',
      name: 'Zone',
      isEditable: false,
      type: 'text',
    },
    {
      uid: 'quantity',
      name: 'Quantité',
      isEditable: false,
      type: 'number',
      agg: "sum",
      sortable: false
    }
  ], []);

  const dataLinears = useMemo(()=> {
    let metresInZones = metres.map(m => {
      let itemsInZones = zones.filter(z => z.page == m.page).map((x, idx) => {
        return {
          linear_id: m.linear_id,
          page: m.page,
          zone: x.name,
          length: intersectionLengthLinePolygon(m.points, x.points)
        };
      });
      itemsInZones = itemsInZones.concat([{
        linear_id: m.linear_id,
        page: m.page,
        zone: "",
        length: getPolylineLength(m.points) - itemsInZones.map(x => x.length).reduce((partialSum, a) => partialSum + a, 0)
      }]);
      
      return itemsInZones;
    }).flat().map(x=>({linear_id: x.linear_id, page: x.page, zone: x.zone, length: x.length}))

    let groupedMetres = groupBy(metresInZones, ["linear_id", "page", "zone"]);
    let nomenclature = groupedMetres.map((x, idx)=>({
        id: idx,
        name: linears.find(s=>s.linear_id === x[0].linear_id).name,
        page: x[0].page,
        zone: x[0].zone,
        length: `${(x.map(x => x.length).reduce((partialSum, a) => partialSum + a, 0) * (calibration.find(c => c.page === x[0].page)?.scale ?? 1)).toFixed(2)} m`
      })).filter(x=>parseFloat(x.length) > 0).filter(x => selectedPages.includes(x.page - 1));
    return nomenclature;
  }, [metres, linears, zones, selectedPages]);

  const [filtersLinears, setFiltersLinears] = useState([]);

  const columnsLinears = useMemo(() => [
    {
      uid: 'name',
      name: 'Nom du linéaire',
      isEditable: false,
      type: 'text',
    },
    {
      uid: 'page',
      name: 'Page',
      isEditable: false,
      type: 'number',
    },
    {
      uid: 'zone',
      name: 'Zone',
      isEditable: false,
      type: 'text',
    },
    {
      uid: 'length',
      name: 'Longueur',
      isEditable: false,
      type: 'number',
      agg: "sum",
      sortable: false
    }
  ], []);

  return (
      <div className="flex flex-col items-center justify-start py-6 w-full">
        <Tabs
          classNames={{
            cursor: "group-data-[selected=true]:bg-white group-data-[selected=false]:bg-gray-200",
            panel: "h-full overflow-y-auto"
          }}
          radius="md"
          size="lg"
        >
          <Tab
            key="Symboles"
            aria-label="Symboles"
            title={
              <div className="flex items-center gap-2">
                <p>Symboles</p>
              </div>
            }
          >
            <EditableTable 
              columns={columnsSymbols}
              data={dataSymbols}
              filters={filtersSymbols}
              showTopRibbon={true}
              groupable={true}
              emptyContent={"Aucun symbole n'a été compté dans les pages sélectionnées"}
              isHeaderSticky={true}
              removeWrapper
            />
          </Tab>
          <Tab
            key="Linears"
            aria-label="Linears"
            title={
              <div className="flex items-center gap-2">
                <p>Métrés</p>
              </div>
            }
          >
            <EditableTable 
              columns={columnsLinears}
              data={dataLinears}
              filters={filtersLinears}
              showTopRibbon={true}
              emptyContent={"Aucun linéaire n'a été compté"}
              isHeaderSticky={true}
              removeWrapper
            />
          </Tab>
        </Tabs>
      </div>
  );
};
