import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";
import EditableTable from "../../../../components/table/editable-table";
import { useContext, useMemo, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useDrawShape } from "../../../../components/polygon-editor/contexts/draw-context";
import { CanvasContext } from "../../../../components/polygon-editor/contexts/canvas-context";

const QuantitativeModal = ({
    resultComputations,
    ...props
}) => {
    const [filters, setFilters] = useState([]);
    const data = useMemo(() => {
        if (!resultComputations) {
            return [];
        }
        
        return resultComputations.map((x, idx) => ({...x, id: idx}));
    }, [resultComputations]);
    
    const { getAllObjects } = useContext(CanvasContext);
    const { zoomObjects } = useDrawShape();
    
    const zoomZone = (zone_id) => {
        const zones = getAllObjects().filter(x => x.zone_id === zone_id);
        zoomObjects(zones.map(x=>x.id), 1.3);
    };

    const columns = [{
        uid: "valid",
        name: "",
        type: "image",
        renderCell: (item) => { 
            return <Icon
                icon={item.nb_min_items <= item.nb_items ? "fe:check" : "fe:close"}
                className={`scale-[2] text-${item.nb_min_items <= item.nb_items ? "success" : "danger"}`}
            />;
        }
    }, {
        uid: "zone_name",
        name: "Zone",
        type: "text"
    }, {
        uid: "crop_name",
        name: "Symbole",
        type: "text"
    }, {
        uid: "nb_items",
        name: "Nombre d'éléments",
        type: "text"
    }, {
        uid: "nb_min_items",
        name: "Minimum requis",
        type: "text"
    }, {
        uid: "action",
        name: "",
        type: "text",
        renderCell: (item) => { return <Button isIconOnly={true} variant="bordered" color="danger" onPress={()=>{zoomZone(item.zone_id);}}><Icon icon="tabler:zoom" /></Button> }
    }];

    return <Modal 
        {...props}
    >
        <ModalContent>
        {(onClose) => (
            <>
            <ModalHeader className="flex flex-col gap-1">Résultats de l'analyse quantitative</ModalHeader>
            <ModalBody>
                <EditableTable 
                    data={data}
                    columns={columns}
                    filters={filters}
                    showTopRibbon={false}
                    pagination={true}
                    rowsPerPage={10}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onPress={onClose}>Quitter</Button>
            </ModalFooter>
            </>
        )}
        </ModalContent>
    </Modal>
};

export default QuantitativeModal;