import React from "react";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import L from "leaflet";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Luminaire = () => {
  const { source } = useParams();
  const data = useSelector((state) => state.PYM.Map.dataDashboard);
  const features = data[0].lightsData.features;
  // Définir les couleurs prédéfinies
  const predefinedColors = ["red", "black", "green", "yellow", "purple"];

  // Créer un objet qui associe chaque source à une couleur
  const sourceColors = {};
  const sources = Array.from(
    new Set(features.map((feature) => feature.properties.source))
  );
  sources.forEach((source, index) => {
    sourceColors[source] = predefinedColors[index % predefinedColors.length];
  });

  var geojsonMarkerOptions = {
    radius: 2,
    weight: 1,
    opacity: 1,
    fillOpacity: 0.8,
  };
  const pointToLayer = (feature, latlng) => {
    return L.circleMarker(latlng, {
      color: sourceColors[feature.properties.source],
    });
  };
  const setPoint = ({ properties }, latlng) => {
    return L.circleMarker(latlng, geojsonMarkerOptions);
  };
  const filteredFeatures = features.filter(
    (feature) => feature.properties.source === source
  );

  const filteredData = {
    ...data[0].lightsData,
    features: filteredFeatures,
  };

  return (
    <MapContainer
      style={{ height: "100vh", width: "100%" }}
      center={[51.505, -0.09]}
      zoom={13}
    >
      <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
      <GeoJSON data={filteredData} pointToLayer={pointToLayer} />
    </MapContainer>
  );
};

export default Luminaire;
