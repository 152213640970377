import { createSlice } from "@reduxjs/toolkit";

const ClauseControlSlice = createSlice({
    name: "clausesToReview",
    initialState: {
        data: {},
        id: "",
        filename: "",
        url: ""
    },
    reducers: {
        setClauses: (state, action) => {
            let tmp = action.payload.map((item, index) => {
                return { id: index, ...item };
              });
            state.data = tmp;
        },
        setId: (state, action) => {
            state.id = action.payload;
        },
        setFileName: (state, action) => {
            state.filename = action.payload;
        },
        setUrl: (state, action) => {
            state.url = action.payload;
        }
    }
});

export const { setClauses, setId, setFileName, setUrl } = ClauseControlSlice.actions;
export default ClauseControlSlice.reducer;