import React, { useContext, useEffect, useState } from "react";
import PDFEditor from "../../../../components/polygon-editor/pdf-editor";
import { useDispatch, useSelector } from "react-redux";
import SideMenu, { Functions } from "./sideMenu";
import { CanvasContext, CanvasProvider } from "../../../../components/polygon-editor/contexts/canvas-context";
import { ZoneProvider, useZones } from "./provider";
import { DrawShapeProvider, useDrawShape } from "../../../../components/polygon-editor/contexts/draw-context";
import { ShapeType } from "../../../../components/polygon-editor/hooks/draw";
import { addZoneStore, changeZoneColorStore, removeZoneStore } from "../../../../store/features/Comptage/configuration";
import { setFillColor, setOpacity, setStrokeColor } from "../../../../store/components/PolygonEditor/CanvasSlice";
import { fabric } from 'fabric';
import Colors, { LightColors } from "../color";
import { addObjectInLayer, setPagePointsCalibration, setPageRealDistanceCalibration } from "../../../../store/components/PolygonEditor/ImageSlice";
import YesNoAlert from "../../../../components/modals/AlertYesNo";

export default function Zones () {
  fabric.Group.prototype.hasControls = false;
  fabric.Group.prototype.lockMovementX = false;
  fabric.Group.prototype.lockMovementY = false;
  fabric.Group.prototype.lockRotation = false;

  return (
    <CanvasProvider className="overflow-hidden h-[99vh]">
      <ZonesComponent />
    </CanvasProvider>
  );
};

export function ZonesComponent () {
  const dispatch = useDispatch();
  const [activeFunction, setActiveFunction] = useState(Functions.NONE);
  const { angle, setMaxPoints } = useContext(CanvasContext);
  const [page, setPage] = useState(0);
  const scaleLayerId = useSelector((state) => {
    let scale = state.image.layers.find(x=>x.name === "scale_foreground_vpf");
    if (scale) {
      return scale.layerId;
    }
    else {
      return -1;
    }
  });
  const zoneLayerId = useSelector((state) => {
    let zone = state.image.layers.find(x=>x.name === "zone");
    if (zone) {
      return zone.layerId;
    }
    else {
      return -1;
    }
  });
  
  const addZone = (shape) => {
    setActiveFunction(Functions.NONE);

    if (shape.isType("polyline")) {
      shape.selectable = false;
      shape.hasControls = false;
      shape.hasBorders = false;
      shape.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: false,
          br: false,
          tl: false,
          tr: false,
          mtr: false
        });        
      shape.lockRotation = true;
      shape.lockMovementX = true;
      shape.lockMovementY = true;
      shape.lockScalingX = true;
      shape.lockScalingY = true;
      shape.layer = scaleLayerId;
      shape.calibration_line = true;
      setMaxPoints(Number.MAX_SAFE_INTEGER);

      let pts = shape.points;
      
      if (angle !== 0) {
        var [originX, originY] = [0, 0];

        pts = Array.from(pts).map(x=>{return {
            x: (x.x - originX) * Math.cos(-angle * Math.PI / 180) - (x.y - originY) * Math.sin(-angle * Math.PI / 180) + originX,
            y: (x.x - originX) * Math.sin(-angle * Math.PI / 180) + (x.y - originY) * Math.cos(-angle * Math.PI / 180) + originY
        }});
      }

      shape.stroke = "#000000";

      dispatch(addObjectInLayer({layerId: scaleLayerId, object: shape.id})); // Add the object's ID to Redux store
      dispatch(setPagePointsCalibration({
        id: shape.id,
        page: page,
        points: pts,
      }));
    } else{
      shape.selectable = true;
        shape.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            bl: false,
            br: false,
            tl: false,
            tr: false,
            mtr: false
          });        
        shape.lockRotation = true;
        shape.lockMovementX = true;
        shape.lockMovementY = true;
        shape.lockScalingX = true;
        shape.lockScalingY = true;
        shape.layer = zoneLayerId;

      let pts = (shape.isType("rect") ? [{
            x: shape.left,
            y: shape.top
        }, {
            x: shape.left + shape.width,
            y: shape.top
        }, {
            x: shape.left + shape.width,
            y: shape.top + shape.height
        }, {
            x: shape.left,
            y: shape.top + shape.height
        }] : (shape.isType("polygon") ? shape.points : []));

      
      if (angle !== 0) {
        var [originX, originY] = [0, 0];

        pts = Array.from(pts).map(x=>{return {
            x: (x.x - originX) * Math.cos(-angle * Math.PI / 180) - (x.y - originY) * Math.sin(-angle * Math.PI / 180) + originX,
            y: (x.x - originX) * Math.sin(-angle * Math.PI / 180) + (x.y - originY) * Math.cos(-angle * Math.PI / 180) + originY
        }});
      }
      let zone = {
        "id": shape.id,
        "zone_id": shape.id,
        "page": page,
        "points": pts,
        "color": shape.fill,
        "name": `Zone`
      };

      dispatch(addObjectInLayer({layerId: zoneLayerId, object: shape.id})); // Add the object's ID to Redux store
      dispatch(addZoneStore(zone));
    }
  };

  const removeZone = (shape) => {
    dispatch(removeZoneStore(shape.id));
  };

  return (
    <ZoneProvider
      addZone={addZone}
      removeZone={removeZone}
    >
      <DrawShapeProvider>
        <ZoneEditor
          page={page}
          setPage={setPage}
          activeFunction={activeFunction}
          setActiveFunction={setActiveFunction}
        />
      </DrawShapeProvider>
    </ZoneProvider>
  );
};

const ZoneEditor = ({ page, setPage, activeFunction, setActiveFunction }) => {
  const projectFiles = useSelector((state) => state.Comptage.ProjectInfos.project_files);
  const currentFile = useSelector((state) => state.Comptage.Configuration.current_file);
  const currentFilePages = useSelector((state) => state.Comptage.Configuration.pages);
  const selectedPages = useSelector((state) => state.Comptage.Configuration.pages.map((x, i)=>{ return [i, x.selected];}).filter(x=>x[1] === true).map(x=>x[0]));

  const currentFileData = projectFiles.find(x =>x.name === currentFile);
  const { canvasRef, setIsDrawing, setIsDeleting, setShapeType, getAllObjects, removeObjects, getObjectById, setMaxPoints } = useContext(CanvasContext);
  const { startDrawing, insertPolygon, zoomObjects } = useDrawShape();
  const zones = useSelector((state) => state.Comptage.Configuration.zones);
  const calibrationData = useSelector((state) => state.image.calibration?.find(c=>c.page === page) ?? undefined );
  const dispatch = useDispatch();
  const zoneLayerId = useSelector((state) => {
    let zone = state.image.layers.find(x=>x.name === "zone");
    if (zone) {
      return zone.layerId;
    }
    else {
      return -1;
    }
  });
  const scaleLayerId = useSelector((state) => {
    let scale = state.image.layers.find(x=>x.name === "scale_foreground_vpf");
    if (scale) {
      return scale.layerId;
    }
    else {
      return -1;
    }
  });

  const restoreZones = (page) => {
    for(let zone of zones.filter(x=>x.page === page)) {
      let shape = insertPolygon(zone["points"], {
        id: zone["id"],
        zone_id: zone["zone_id"],
        stroke: zone["color"],
        fill: zone["color"],
        opacity: .4,
        selectable: true,
        strokeWidth: 2,
        objectCaching: false,
        visible: true,
        layer: zoneLayerId,
        lockRotation: true,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true
      });
      
      shape.selectable = true;
      shape.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: false,
          br: false,
          tl: false,
          tr: false,
          mtr: false
        });        
      shape.lockRotation = true;
      shape.lockMovementX = true;
      shape.lockMovementY = true;
      shape.lockScalingX = true;
      shape.lockScalingY = true;
    }
  };

  const addPolygonalZone = () => {
    setActiveFunction(Functions.POLY);
    setIsDeleting(false);
    dispatch(setFillColor(LightColors[zones.length % LightColors.length].value));
    dispatch(setOpacity(.4));
    setShapeType(ShapeType.POLYGON);
    startDrawing();
  };
  const addRectangularZone = () => {
    setActiveFunction(Functions.RECT);
    setIsDeleting(false);
    dispatch(setFillColor(LightColors[zones.length % LightColors.length].value));
    dispatch(setOpacity(.4));
    setShapeType(ShapeType.RECT);
    startDrawing();
  };
  const deleteZone = () => {
    setActiveFunction(Functions.DELETE);
    setIsDeleting(true);
  };
  const deleteAllZones = () => {
    setActiveFunction(Functions.DELETE_ALL);
    YesNoAlert({
      title: "Supprimer",
      body: "Voulez-vous vraiment supprimer toutes les zones ?",
      onYes: () => {
        setIsDrawing(false);
        setIsDeleting(false);
        let objs = getAllObjects().filter(x=>!x.isBackground);
        objs.forEach(sh => {
          sh.definitiveDelete = true;
        });
        removeObjects(objs.map(x => x.id));
        setActiveFunction(Functions.NONE);
      },
      onNo: () => {
        setIsDrawing(false);
        setIsDeleting(false);
        setActiveFunction(Functions.NONE);
      },
      onClose: () => {
        setIsDrawing(false);
        setIsDeleting(false);
        setActiveFunction(Functions.NONE);
      }
    })
  };

  const zoomItem = (id) => {
    zoomObjects([id], 1.3);
  };

  const deleteItem = (id) => {
    let objs = getAllObjects().filter(x=>x.id === id);
    objs.forEach(sh => {
      sh.definitiveDelete = true;
    });
    removeObjects(objs.map(x => x.id));
  };

  const changeZoneColor = (id, newColor) => {
    let obj = getObjectById(id);
    if (obj) {
      obj.stroke = newColor;
      obj.fill = newColor;
    }
    canvasRef.current.renderAll();

    dispatch(changeZoneColorStore({id, newColor}));
  };

  return (
          <PDFEditor
            initialLayers={["0", "background", "zone"]}
            showScale={true}
            page={page}
            setPage={setPage}
            toolsPanel={
              <div className="h-full top-0 absolute overflow-hidden right-0 z-50">
                <SideMenu
                  activeFunction={activeFunction}
                  setActiveFunction={setActiveFunction}
                  addPolygonalZone={addPolygonalZone}
                  addRectangularZone={addRectangularZone}
                  deleteZone={deleteZone}
                  deleteAllZones={deleteAllZones}
                  zoomItem={zoomItem}
                  deleteItem={deleteItem}
                  changeZoneColor={changeZoneColor}
                  page={page}
                  selectedPages={selectedPages}
                />
              </div>}
            visiblePages={selectedPages}
            pageCount={currentFileData === undefined ? 1 : currentFileData.pages.length}
            getImages={(p) => {
              return currentFilePages[p]["patches"];
          } }
            restoreShapes={restoreZones}
          />);
}

