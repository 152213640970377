import React, { useEffect,useState } from "react";
import FilesDisplayList from "./files-display-list";
import FileListWindow from "./file-list-window";
import { useSelector } from "react-redux";
import FileFocusModal from './file-focus-modal'
import config from "../../../../config";
import {  useNavigate, Navigate, useOutletContext } from "react-router-dom";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";

export default function FileExplorer() {
  const [isLoading, setIsLoading, loadingMessage, setLoadingMessage] = useOutletContext();
  const { launch_request } = useAzureFuncApp();

  const navigate = useNavigate();
  const projectInfos = useSelector((state) => state.PYM.ProjectInfos);
  const [folderList, setFolderList] = React.useState([]);
  const [folderSelected, setFolderSelected] = React.useState([]);

  const [showModal,setShowModal] = useState(false);

  const [srcPath,setSrcPath] = useState('');
  const [nameModal,setNameModal] = useState('');
  const [blobPath,setBlobPath] = useState('');

  useEffect(() => {
    getSavedFiles().then((result) => setFolderList(result));
  }, [])
        
async function handleConfirmModal(needMap,fileName,blobPath) {
  if(needMap){
    navigate('/solux-map/accueil', {
      state: { needPlot: true, plotPath:blobPath }
    });
    }
  else{
    const response = await fetch(blobPath);
    const blob = await response.blob();

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}`
    link.click();
  }
}

const handleFolderSelected = (event) => {
  let selectedId = event.target.dataset.key;
  let folders = [... folderList];
  setFolderSelected(Array(folders[selectedId]));
}
const handleImageClick = (event,name,imageSrc)=>{
  setSrcPath(event.target.src)
  setNameModal(name)
  setBlobPath(imageSrc)
  setShowModal(true)
  }
  async function getSavedFiles() {
    const payload = {
      functionName: "getFoldersAndFiles",
      projectPath: projectInfos.project_path || "",
    };

    setLoadingMessage("Récupération des fichiers...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
    setIsLoading(false);
    if (status_code === 200) {
      return result.filesList
    }
  }

  return (
    <>
      <FilesDisplayList folderList={folderList} className="lg:col-span-6 xl:col-span-4 flex flex-col py-4" onFolderSelected={handleFolderSelected} />
      <FileListWindow folderSelected={folderSelected} handleImageClick={handleImageClick} className="lg:col-span-6 xl:col-span-5 flex flex-col w-full h-full" />
      <FileFocusModal isOpen = {showModal} onConfirm = {handleConfirmModal}  onDecline={()=>{setShowModal(false);}} nameModal={nameModal} srcPath={srcPath} blobPath={blobPath}/>
    </>
  )
}