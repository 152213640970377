import { combineReducers } from "redux";
import ProjectInfoSlice from "./ProjectInfoSlice";
import GeoSlice from "./GeoSlice";

const PYMReducer = combineReducers({
  ProjectInfos: ProjectInfoSlice,
  GeoData: GeoSlice,
});

export default PYMReducer;
