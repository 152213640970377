// enterpriseSlice.js
import { createSlice } from "@reduxjs/toolkit";

const enterpriseSlice = createSlice({
  name: "enterprise",
  initialState: {
    enterprises: [],
  },
  reducers: {
    addEnterprise(state, action) {
      state.enterprises.push(action.payload);
    },
    updateEntreprise(state, action) {
      state.enterprises.push(action.payload);
    },
    // Ajoutez d'autres reducers au besoin
  },
});

export const { addEnterprise, updateEntreprise } = enterpriseSlice.actions;
export default enterpriseSlice.reducer;
