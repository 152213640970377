import React from "react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "@nextui-org/react";

const DianeModal = ({
    // Core modal content
    children,                 // Custom content for the modal (optional)
    title,                    // Title text for the modal header
    titleProps,               // Additional props for the ModalHeader component
    body,                     // Content for the modal body
    bodyProps,                // Additional props for the ModalBody component
    footer,                   // Footer content for the modal (usually buttons)
    footerProps,              // Additional props for the ModalFooter component

    // Size & style props
    size = "md",              // Modal size: "xs" to "full" (default: "md")
    radius = "lg",            // Border radius: "none" to "lg" (default: "lg")
    shadow = "lg",            // Box shadow intensity: "none" to "lg" (default: "lg")
    backdrop = "opaque",      // Backdrop type: "transparent", "opaque", or "blur" (default: "opaque")
    scrollBehavior = "normal",// Scrolling behavior: "normal", "inside", or "outside" (default: "normal")
    placement = "auto",       // Modal position: "auto", "top", "center", or "bottom" (default: "auto")

    // Behavior & control props
    isOpen,                   // Controlled state: true if modal is open
    defaultOpen,              // Uncontrolled state: true if modal is open by default
    onOpenChange,             // Callback when the modal's open state changes
    onClose,                  // Callback when modal is closed
    
    // Dismiss & interaction props
    isDismissable = true,     // Allows clicking outside or pressing ESC to close modal (default: true)
    isKeyboardDismissDisabled = false, // Disable ESC key to close the modal (default: false)
    shouldBlockScroll = true, // Prevents page scrolling when modal is open (default: true)
    hideCloseButton = false,  // Hides the close button in the modal (default: false)
    closeButton,              // Custom close button node (optional)

    // Motion and portal props
    motionProps,              // Custom motion animation for the modal
    disableAnimation = false, // Disable animations (default: false)

    // Custom class names
    classNames,               // Custom class names for modal styling (e.g., header, body, footer)
}) => {
  return (
    <Modal
      isOpen={isOpen}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      onClose={onClose}
      size={size}
      radius={radius}
      shadow={shadow}
      backdrop={backdrop}
      scrollBehavior={scrollBehavior}
      placement={placement}
      isDismissable={isDismissable}
      isKeyboardDismissDisabled={isKeyboardDismissDisabled}
      shouldBlockScroll={shouldBlockScroll}
      hideCloseButton={hideCloseButton}
      closeButton={closeButton}
      motionProps={motionProps}
      disableAnimation={disableAnimation}

      classNames={{ // exemples
        backdrop: "", //"bg-[#292f46]/50 backdrop-opacity-40",
        base: "", //"border-[#292f46] bg-[#19172c] dark:bg-[#19172c] text-[#a8b0d3]",
        header: "flex flex-col gap-1 bg-[#f9f9f9] border-b-[1px]", //" border-[#292f46]",
        body: "", //"py-4",
        footer: "", //"border-t-[1px] border-[#292f46]",
        closeButton: "", //"hover:bg-white/5 active:bg-white/10",
        ...classNames,  // Allows overriding default class names
      }}
    >
      {React.Children.toArray(children).some(child => child.type === ModalContent) ? (
        children
      ) : (
        <ModalContent>
          {children || (
            <>
              <ModalHeader {...titleProps}>{title}</ModalHeader>
              <ModalBody {...bodyProps}>{body}</ModalBody>
              <ModalFooter {...footerProps}>{footer}</ModalFooter>
            </>
          )}
        </ModalContent>
      )}

    </Modal>
  );
};

export default DianeModal;
