import { createSlice } from "@reduxjs/toolkit";

const ComparedEquipmentsSlice = createSlice({
    name: "comparedEquipments",
    initialState: {
        data: {},
        base: []
    },
    reducers: {
        setComparisonTable: (state, action) => {
            let tmp = action.payload.map((item, index) => {
                return { id: index, ...item };
              });
            state.data = tmp;
        },
        setBase: (state, action) => {
            let tmp = action.payload.reduce((acc, string, index) => {
                acc[index] = { key: string, value: string };
                return acc;
              }, [])
            state.base = tmp;
        }
    }
});

export const { setComparisonTable, setBase } = ComparedEquipmentsSlice.actions;
export default ComparedEquipmentsSlice.reducer;