const environment = process.env.REACT_APP_ENVIRONMENT.trim();

const API_DENOMBREMENT_URI      = environment === "production" || environment === "local" ? process.env.REACT_APP_API_DENOMBREMENT_URI : process.env.REACT_APP_API_DENOMBREMENT_URI_TEST;
const API_DIANE_PLATEFORME_URI  = environment === "production" || environment === "local" ? process.env.REACT_APP_API_DIANE_PLATEFORME_URI : process.env.REACT_APP_API_DIANE_PLATEFORME_URI_TEST;
const API_DIANE_INTERNAL_URI    = environment === "production" || environment === "local" ? process.env.REACT_APP_API_DIANE_INTERNAL_URI : process.env.REACT_APP_API_DIANE_INTERNAL_URI_TEST;
const API_OMIA_URI              = environment === "production" || environment === "local" ? process.env.REACT_APP_API_OMIA_URI : process.env.REACT_APP_API_OMIA_URI_TEST;
const API_PYM_URI               = environment === "production" || environment === "local" ? process.env.REACT_APP_API_PYM_URI : process.env.REACT_APP_API_PYM_URI_TEST;
const API_ALLYSIA_URI           = environment === "production" || environment === "local" ? process.env.REACT_APP_API_ALLYSIA_URI : process.env.REACT_APP_API_ALLYSIA_URI_TEST;
const API_SEGMENTATION_URI      = environment === "production" || environment === "local" ? process.env.REACT_APP_API_SEGMENTATION_URI : process.env.REACT_APP_API_SEGMENTATION_URI_TEST;

const config = {
  isLocal: environment === "local",
  isDebug: environment === "local" || environment === "development",

  apiBlobManagementUri: "/api/blobs",
  apiCredentialsUri: "/api/credentials",
  apiErrorReportUri: "/api/errorreport",
  apiAccessUri:"/api/pushaccess",
  apiToolsUri:"/api/gettools",
  apiGetRolesUri:"/api/getroles",
  apiFileUrlUri:"/api/getblobfileurl",

  apiCountItemsUri: `${API_DENOMBREMENT_URI}/CountItems`,
  apiUxellOGSUri: `${API_DENOMBREMENT_URI}/Fabecrea`,
  apiMetreUri: `${API_DENOMBREMENT_URI}/Metre`,
  apiSegmentationUri: `${API_SEGMENTATION_URI}/fastsam_count`,
  apiDockerPdfUri: `${API_SEGMENTATION_URI}/PDF`,

  apiApproUxelloUri: `${API_DIANE_PLATEFORME_URI}/appro`,
  apiC2CUri: `${API_DIANE_PLATEFORME_URI}/c2c`,
  apiChatbotUri: `${API_DIANE_PLATEFORME_URI}/Chatbot`,
  apiDifferenceIndexUri: `${API_DIANE_PLATEFORME_URI}/DifferenceIndexBlueprint`,
  apiElioveUri: `${API_DIANE_PLATEFORME_URI}/eliove`,
  apiPDF2RevitUri: `${API_DIANE_PLATEFORME_URI}/Pdf2Revit`,
  apiPdfTextDifferenceUri: `${API_DIANE_PLATEFORME_URI}/PdfTextDifference`,
  apiPdfUri: `${API_DIANE_PLATEFORME_URI}/Pdf`,
  apiVtidf: `${API_DIANE_PLATEFORME_URI}/Vtidf`,
  apiClauseControl: `${API_DIANE_PLATEFORME_URI}/ClauseControl`,
  
  apiContactUri: `${API_DIANE_PLATEFORME_URI}/Contact`,
  apiFeedbackUri: `${API_DIANE_PLATEFORME_URI}/Feedback`,
  apiWhatsNextUri: `${API_DIANE_PLATEFORME_URI}/WhatsNext`,
  apiContracts: `${API_DIANE_INTERNAL_URI}/contrats`,
  
  apiVfMaintenance: `${API_OMIA_URI}/VFOffreMaintenance`,

  pymParseFile: `${API_PYM_URI}/parse_files`,
  pymGetLights: `${API_PYM_URI}/parse_files`,
  pymLoadSave: `${API_PYM_URI}/load_save`,
  pymDeleteSave: `${API_PYM_URI}/delete_save`,
  pymSimulation: `${API_PYM_URI}/simulation`,
  pymOptimisation: `${API_PYM_URI}/optimisation`,
  apiPymUri: `${API_PYM_URI}`,

  apiAllysiaUri: `${API_ALLYSIA_URI}/`

};

export const helpUrls = {
  Allysia: undefined,
  ClauseControl: "https://www.youtube.com/embed/dupD19V_VNo?si=JgGNmaY6coFqRlKj",
  ComparaisonPlan: undefined,
  ComparaisonTexte: undefined,
  Comptage: undefined,
  Contractualisation: undefined,
  OMIA: "https://www.youtube.com/embed/wG2QVc2Okhc?si=fx0BBUwa1MMuDyqL",
  PDF2Revit: undefined,
  PYM: [{
    title: "Initialisation",
    url: "https://www.youtube.com/embed/lOvuBmkPDGw?si=pa4C_koBs8E8Uqi5"
  }, {
    title: "Simulation",
    url: "https://www.youtube.com/embed/xoppDSA5N54?si=D0aYkALvkDHolPtR"
  }, {
    title: "Tronçon type",
    url: "https://www.youtube.com/embed/hcl9uJHFKso?si=KAKt7QTSH1Qwk2sX"
  }, {
    title: "Paramètres",
    url: "https://www.youtube.com/embed/bdOXZhql9aw?si=_iC8qTMPsMvyKNON"
  }],
  UxelloAppro: "https://www.youtube.com/embed/4VhMynZN5SM?si=juLX0ke-p9nTn2yq",
};

export default config;
