import { useContext } from 'react';
import { CanvasContext } from '../contexts/canvas-context';
import { fabric } from 'fabric';
import { changeLayerVisibility } from '../../../store/components/PolygonEditor/ImageSlice';

const useExtract = () => {
  const { canvasRef, getAllObjects } = useContext(CanvasContext);

  function extractContentWithinShape(shape, layerIds=undefined) {
    // Get the shape's position and size
    const width = shape.width * shape.scaleX;
    const height = shape.height * shape.scaleY;

    // Draw the main canvas content onto the offscreen canvas, cropping to the shape's bounding box
    let zoom = canvasRef.current.getZoom();

    let objects = getAllObjects().filter(x => layerIds !== undefined && x.hasOwnProperty("layer") && !layerIds.includes(x.layer));
    for (let obj of objects) {
      obj["temp_visibility"] = obj.visible ?? true;
      obj.visible = false;
    }

    const localPoint = shape.getCenterPoint();
    const viewportTransform = canvasRef.current.viewportTransform;
    const canvasPoint = fabric.util.transformPoint(localPoint, viewportTransform);

    const dataURL = canvasRef.current.toDataURL({
        left: canvasPoint.x - width * zoom / 2 - 1,
        top: canvasPoint.y - height * zoom / 2 - 1,
        width: width*zoom + 2,
        height: height*zoom + 2
    });

    for (let obj of objects) {
      obj["visible"] = obj.temp_visibility ?? true;
    }

    return dataURL;
}

  // Return methods and state variables for drawing shapes
  return { extractContentWithinShape };
};

export default useExtract;