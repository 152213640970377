import { createContext, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const SignatureContext = createContext();
const functionName = { name: "signature_pdf" };
export const SignatureProvider = ({
    children
}) => {
    const pdfFiles = useSelector((state) => state.file.files);
    
    const [instanceId, setInstanceId] = useState(Date.now().toString(36) + Math.random().toString(36).substring(2));
    let blobNamePrefix = useMemo(() => {
        return `${functionName.name}/${instanceId}/`;
    }, [instanceId]);

    const [signature, setSignature] = useState(null);
    const [signatureItems, setSignatureItems] = useState([]);
    const [paraphe, setParaphe] = useState(null);
    const [parapheItems, setParapheItems] = useState([]);
    const [signatureDataUrl, setSignatureData] = useState(null);
    const [parapheDataUrl, setParapheData] = useState(null);
  
    const pdfBlobPath = useMemo(() => {
        if (pdfFiles.length === 0) {
          return [""];
        }
    
        return pdfFiles[0];
      }, [pdfFiles]);

    return (
    <SignatureContext.Provider
        value={{
            pdfBlobPath,
            signature,
            setSignature,
            signatureItems,
            setSignatureItems,
            parapheItems,
            setParapheItems,
            paraphe,
            setParaphe,
            signatureDataUrl,
            setSignatureData,
            parapheDataUrl,
            setParapheData,
            blobNamePrefix
        }}
    >
        {children}
    </SignatureContext.Provider>
    );
};

export const useSignature = () => {
    return useContext(SignatureContext);
};