import React from "react";
import {
  Accordion,
  AccordionItem,
  Checkbox,
  CheckboxGroup,
  Divider,
  ScrollShadow,
  Switch,
  Tab,
  Tabs,
} from "@nextui-org/react";

import {cn} from "../../../utils/cn.js";
import {FilterTypeEnum} from "./filters-types.js";

import FilterSlider from "./filter-slider.js";
import TagGroupItem from "./tag-group-item.js";

const FiltersWrapper = React.forwardRef(
  (
    {
      items,
      setFilters,
      title = "Filters",
      showTitle = true,
      className,
      scrollShadowClassName,
    },
    ref,
  ) => {
    const handleTabsChange = (filterKey, tabKey) => {
      setFilters(f => {
        let newFilters = [...f].filter(x => x["key"] !== filterKey);
        newFilters.push({
          key: filterKey,
          value: tabKey,
          type: FilterTypeEnum.Tabs
        });
        return newFilters;
      })
    };
    const handleRangeChange = (filterKey, minValue, maxValue) => {
      setFilters(f => {
        let newFilters = [...f].filter(x => x["key"] !== filterKey);
        newFilters.push({
          key: filterKey,
          min: minValue,
          max: maxValue,
          type: FilterTypeEnum.Range
        });
        return newFilters;
      });
    };

    const handleTagGroupChange = (filterKey, tags) => {
        setFilters(f => {
          let newFilters = [...f].filter(x => x["key"] !== filterKey);

          if (tags.length > 0) {
            newFilters.push({
              key: filterKey,
              values: tags,
              type: FilterTypeEnum.TagGroup
            });
          }

          return newFilters;
        });
    };

    const handleCheckboxGroupChange = (filterKey, tags) => {
        setFilters(f => {
          let newFilters = [...f].filter(x => x["key"] !== filterKey);

          if (tags.length > 0) {
            newFilters.push({
              key: filterKey,
              values: tags,
              type: FilterTypeEnum.CheckboxGroup
            });
          }

          return newFilters;
        });
    };

    
    const renderFilter = React.useCallback((filter) => {
      switch (filter.type) {
        case FilterTypeEnum.Tabs:
          return (
            <Tabs fullWidth aria-label={filter.title} onSelectionChange={(tabKey) => { handleTabsChange(filter["key"], tabKey) }}>
              {filter.options?.map((option) => (
                <Tab key={option.value} title={option.title} value={option.value} />
              ))}
            </Tabs>
          );
        case FilterTypeEnum.Range:
          return <FilterSlider aria-label={filter.title} range={filter.range} showPips={filter.showPips} onRangeChange={(minValue, maxValue) => { handleRangeChange(filter["key"], minValue, maxValue) }} />;
        case FilterTypeEnum.CheckboxGroup:
          return (
            <Accordion
              className="px-0"
              defaultExpandedKeys={filter?.defaultOpen ? ["options"] : []}
            >
              <AccordionItem
                key="options"
                classNames={{
                  title: "text-medium font-medium leading-8 text-default-600",
                  trigger: "p-0",
                  content: "px-1",
                }}
                title={filter.title}
              >
                <CheckboxGroup aria-label={filter.title} onChange={ (checks) => handleCheckboxGroupChange(filter["key"], checks) }>
                  {filter.options?.map((option) => (
                    <Checkbox key={option.value} value={option.value}>
                      {option.title}
                    </Checkbox>
                  ))}
                </CheckboxGroup>
              </AccordionItem>
            </Accordion>
          );
        case FilterTypeEnum.TagGroup:
          return (
            <Accordion
              className="px-0"
              defaultExpandedKeys={filter?.defaultOpen ? ["options"] : []}
            >
              <AccordionItem
                key="options"
                classNames={{
                  title: "text-medium font-medium leading-8 text-default-600",
                  trigger: "p-0",
                  content: "px-1",
                }}
                title={filter.title}
              >
                <CheckboxGroup aria-label="Select amenities" className="gap-1" orientation="horizontal" onChange={ (tags) => handleTagGroupChange(filter["key"], tags) }>
                  {filter.options?.map((option) => (
                    <TagGroupItem key={option.value} icon={option.icon} value={option.value}>
                      {option.title}
                    </TagGroupItem>
                  ))}
                </CheckboxGroup>
              </AccordionItem>
            </Accordion>
          );
          default:
          return (
            <></>
          );
        }
    }, []);

    return (
      <div
        ref={ref}
        className={cn("h-full max-h-fit w-full max-w-sm rounded-medium bg-content1 p-6", className)}
      >
        {showTitle && (
          <>
            <h2 className="text-large font-medium text-foreground">{title}</h2>
            <Divider className="my-3 bg-default-100" />
          </>
        )}

        <ScrollShadow
          className={cn(
            "-mx-6 h-full px-6",
            scrollShadowClassName,
          )}
        >
          <div className="flex flex-col gap-6">
            {items.map((filter) => (
              <div key={filter.title} className="flex flex-col gap-3">
                {filter.type !== FilterTypeEnum.CheckboxGroup && filter.type !== FilterTypeEnum.TagGroup ? (
                  <div>
                    <h3 className="text-medium font-medium leading-8 text-default-600">
                      {filter.title}
                    </h3>
                    <p className="text-small text-default-400">{filter.description}</p>
                  </div>
                ) : null}
                {renderFilter(filter)}
              </div>
            ))}
          </div>
        </ScrollShadow>

      </div>
    );
},
);

FiltersWrapper.displayName = "FiltersWrapper";

export default FiltersWrapper;