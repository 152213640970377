import LightsDispositionCard from "./lights-disposition-card";
import ResultImgCard from "./result-img-card";

export default function ResultDisplay({data,...props})
{
    return (
        // <div className=" flex flex-row h-full gap-4">
            <LightsDispositionCard data={data}/>
            // {/* <ResultImgCard data={data}/> */}
        // </div>
    )
}