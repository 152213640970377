import React, { Component } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";

const imagesPath = {
  isopen: "/images/solar--close.png",
  isclose: "/images/solar--cursor.png"
}

class Description extends Component {
  selectionDiv = null;
  imgDiv = null;
  drawControl = null;
  state = {
    drawControlVisible: false,
    open: false,
  };
  toggleImage = () => {
    this.setState(state => ({ open: !state.open }))
  }
  getImageName = () => this.state.open ? 'isopen' : 'isclose'

  createButtonControl() {
    const imageName = this.getImageName();
    
    const MapHelp = L.Control.extend({
      onAdd: (map) => {
        const selectionDiv = L.DomUtil.create("div", "leaflet-bar leaflet-control leaflet-control-custom bg-white h-[34px] w-[34px] ");
        this.selectionDiv = selectionDiv;

        selectionDiv.innerHTML = `<button class="leaflet-bar-part leaflet-bar-part-single"><img src="${imagesPath[imageName]}" alt="Cursor" /></button>`;
        selectionDiv.addEventListener("click", () => {
          this.toggleImage(); 
          this.toggleDrawControl(map);
        });
        this.imgDiv = selectionDiv.querySelector("img");

        return selectionDiv;
      }
    });

    return new MapHelp({ position: "topleft" });
  }

  toggleDrawControl(map) {
    if (this.state.drawControlVisible) {
      map.removeControl(this.drawControl);
      this.setState({ drawControlVisible: false , open:false });
      if (this.imgDiv) {
        this.imgDiv.src = imagesPath.isclose;
      }
      this.props.onSelection([]);
    } 
    else {
      this.setState({ buttonImage:"/images/solar--close.png" });
      this.drawControl = new L.Control.Draw({
        draw: {
            marker   : false,
            polygon  : false,
            polyline : false,
            rectangle: true,
            circle: false,
            // Ne marche pas
            // circle   : {
            //     metric: 'metric'
            // },
            circlemarker:false,
        },
        edit: false
          });
      map.addControl(this.drawControl);

      map.on(L.Draw.Event.CREATED, (e) => {
        const layer = e.layer;
        const intersectingLayers = [];
        map.eachLayer((existingLayer) => {
            if (existingLayer instanceof L.Polyline) {
                if (layer.getBounds().intersects(existingLayer.getBounds())) {
                    intersectingLayers.push(existingLayer.options.id);
                }
            }
        });
        if (this.props.onSelection) {
            this.props.onSelection(intersectingLayers);
          }
        });

      this.setState({ drawControlVisible: true,open:true });
      if (this.imgDiv) {
        this.imgDiv.src = imagesPath.isopen;
      }
    }
  }

  componentDidMount() {
    const { map } = this.props;
    const control = this.createButtonControl();
    control.addTo(map);
  }

  componentWillUnmount() {
    if (this.selectionDiv) {
      this.selectionDiv.remove();
    }
  }

  render() {
    return null;
  }
}

function withMap(Component) {
  return function WrappedComponent(props) {
    const map = useMap();
    return <Component {...props} map={map} />;
  };
}

export default withMap(Description);