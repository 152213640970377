import { Icon } from "@iconify/react/dist/iconify.js";
import * as React from "react"
import { CanvasContext } from "../../../components/polygon-editor/contexts/canvas-context";
import { useDrawShape } from "../../../components/polygon-editor/contexts/draw-context";

export default function ReactPanZoom ({zoomStep = 100}) {

  const { canvasRef } = React.useContext(CanvasContext);
  const { changeZoom, zoomBackground, rotateLeft, rotateRight } = useDrawShape();
 
  return (
    <div className="rounded-xl overflow-hidden" style={{
      border: "1px solid lightgray",
      boxShadow: "2px 2px 3px lightgray"
    }}>
      <Icon onClick={()=>{
        let center = canvasRef.current.getCenter();
        changeZoom(-zoomStep, center.left, center.top);
      }} className="w-[40px] h-[40px] p-[6px] cursor-pointer my-[1px]  bg-white" icon="ic:baseline-plus" />
      <Icon onClick={()=>{
        let center = canvasRef.current.getCenter();
        changeZoom(zoomStep, center.left, center.top);
      }} className="w-[40px] h-[40px] p-[6px] cursor-pointer my-[1px]  bg-white" icon="ic:baseline-minus" />
      <Icon onClick={rotateLeft} className="w-[40px] h-[40px] p-[6px] cursor-pointer my-[1px]  bg-white" icon="fa6-solid:arrow-rotate-left" />
      <Icon onClick={rotateRight} className="w-[40px] h-[40px] p-[6px] cursor-pointer my-[1px]  bg-white" icon="fa6-solid:arrow-rotate-right" />
      {/* <Icon onClick={clearCanvas} className="w-[40px] h-[40px] p-[6px] cursor-pointer my-[1px]  bg-white" icon="fluent:arrow-move-20-regular" /> */}
      <Icon onClick={() => {
        zoomBackground();
      }} className="w-[40px] h-[40px] p-[6px] cursor-pointer my-[1px]  bg-white" icon="hugeicons:arrow-expand" />
    </div>
  );
}
