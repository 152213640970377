import ComingSoon from "../../../../components/coming-soon";

export default function Verification () {
  return (
    <>
      <ComingSoon
        className="w-[70%] h-[70%] m-auto"
        fill="black"
        fillOpacity=".5"
        stroke="black"
        strokeWidth="10px"
        filter="drop-shadow(0px 3px 4px rgb(0 0 0 / 0.1))"
        backdropFilter="blur(5px)"
      />
    </>
  );
};
