import { useContext } from "react";
import { CanvasContext } from "../../../../components/polygon-editor/contexts/canvas-context";
import { CanvasEventContext } from "../../../../components/polygon-editor/contexts/canvas-event-context";

export const LinearsProvider = ({ 
  addLinear,
  removeLinear,
  children
}) => {
    const { canvasRef } = useContext(CanvasContext);

    const onShapeAdded = (shape) => {
      shape.visible = true;
      canvasRef?.current?.renderAll();

      if (shape && !shape.isBackground) {
        shape.bringToFront();
        addLinear(shape);
      }
    };
    
    const onShapeRemoved = (shape) => {

      if (shape && !shape.isBackground) {
        removeLinear(shape);
      }
    };
    
    const onShapeSelected = (shape) => {
      if (shape && !shape.isBackground) {
        // shape.forEach(sh => {
        //   sh.hasControls = false;
        //   sh.hasBorders = false;
        //   sh.stroke = "#FF0000";
        //   sh.strokeWidth = 5;
        // });
      }
    };
    
    const onShapeDeselected = (shape) => {
      if (shape && !shape.isBackground) {
        // shape.forEach(sh => {
        //   sh.hasControls = true;
        //   sh.hasBorders = true;
        //   sh.stroke = shape.fill;
        //   sh.strokeWidth = 1;
        // });
      }
    };
    
    const onShapeClicked = (shape) => {

    };

    return (
      <CanvasEventContext.Provider
        value={{
            onShapeAdded,
            onShapeRemoved,
            onShapeSelected,
            onShapeDeselected,
            onShapeClicked
        }}
      >
        {children}
      </CanvasEventContext.Provider>
    );
  };