// project imports
import { lazy } from "react";

// project imports
const Page403 = lazy(() => import("../pages/errors/403"));
const Page404 = lazy(() => import("../pages/errors/404"));
const ErrorRoutes = {
  path: "/",
  element: "",
  children: [
    {
      path: "/403",
      element: <Page403 />,
    },
    {
      path: "/*",
      element: <Page404 />,
    },
  ],
};
export default ErrorRoutes;
