import React from "react";
import Widget from "./widget";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@nextui-org/react";
import FeedbackModal from "../feedbacks/modal";
import HelpModal from "../help/modal";
import ContactModal from "../contact/modal";

export const HelpWidget = ({
  tutoVideoUrl=undefined,
  tooltipPlacement="left",
  ...props}) => {
    const {isOpen, onOpen, onClose} = useDisclosure();

    return <>
      <Widget
        icon="ci:help"
        tooltip="Aide"
        tooltipPlacement={tooltipPlacement}
        action={onOpen}
        {...props}
        />
      <HelpModal
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        videoUrl={tutoVideoUrl}
      />
    </>
};

export const HomeWidget = ({
  homeUrl = "/",
  tooltipPlacement="left",
  ...props}) => {
    const navigate = useNavigate();
    return <Widget
      icon="solar:home-linear"
      tooltip="Accueil"
      tooltipPlacement={tooltipPlacement}
      action={() => { navigate(homeUrl); }}
      {...props}
    />
};

export const PdfWidget = ({
  homeUrl: pdfUrl = "/pdf",
  tooltipPlacement="left",
  ...props}) => {
    const navigate = useNavigate();
    return <Widget
      icon="teenyicons:pdf-outline"
      tooltip="PDF"
      tooltipPlacement={tooltipPlacement}
      action={() => { navigate(pdfUrl); }}
      {...props}
    />
};

export const FeedbackWidget = ({
  tooltipPlacement="left",
  ...props}) => {
    const {isOpen, onOpen, onOpenChange, onClose} = useDisclosure();

    return <>
      <Widget
        icon="ooui:feedback-rtl"
        tooltip="Donnez votre avis"
        tooltipPlacement={tooltipPlacement}
        action={onOpen}
        {...props}
      />
      <FeedbackModal
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        onClose={onClose}
      />
    </>
};

export const ContactWidget = ({
  tooltipPlacement="left",
  ...props}) => {
    const {isOpen, onOpen, onOpenChange, onClose} = useDisclosure();

    return <>
      <Widget
        icon="tabler:message"
        tooltip="Contact"
        tooltipPlacement={tooltipPlacement}
        action={onOpen}
        {...props}
      />
      <ContactModal
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        onClose={onClose}
      />
    </>
};


export const SettingsWidget = ({
  tooltipPlacement="left",
  ...props}) => {
    return <Widget
      icon="solar:settings-linear"
      tooltip="Paramètres"
      tooltipPlacement={tooltipPlacement}
      {...props}
    />
};
