import React, { lazy } from "react";
import ProtectedRoute from "../components/login/protected-route";
const OMIA = lazy(() =>
  import("../pages/services/OMIA")
);
const SelectionPagesFichier = lazy(() =>
  import("../pages/services/OMIA/SelectionPagesFichier")
);
const TableExtraction = lazy(() =>
  import("../pages/services/OMIA/TableExtraction")
);
const BaseComparison = lazy(() =>
  import("../pages/services/OMIA/BaseComparison")
);

const OmiaRoutes = {
  path: "/",
  element: "",
  children: [
    {
      path: "/OMIA",
      element: (
        <ProtectedRoute roles={["Plateforme.Service16", "Plateforme.FullAccess"]}>
          <OMIA />
        </ProtectedRoute>
      ),
    },
    {
      path: "/OMIA/selectionPages",
      element: (
        <ProtectedRoute roles={["Plateforme.Service16", "Plateforme.FullAccess"]}>
          <SelectionPagesFichier />
        </ProtectedRoute>
      ),
    },
    {
      path: "/OMIA/extraction",
      element: (
        <ProtectedRoute roles={["Plateforme.Service16", "Plateforme.FullAccess"]}>
          <TableExtraction />
        </ProtectedRoute>
      ),
    },
    {
      path: "/OMIA/comparaison",
      element: (
        <ProtectedRoute roles={["Plateforme.Service16", "Plateforme.FullAccess"]}>
          <BaseComparison />
        </ProtectedRoute>
      ),
    },
  ],
};

export default OmiaRoutes;
