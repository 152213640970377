
export const unitMapping = {
    "U": "unité",
    "H": "heure",
    "KG": "kg",
    "ENS": "ensemble",
    "M": "m",
    "M2": "m²",
    "M3": "m³",
    "MLI": "mL"
};

export const unitList = [
    {key: "U", label: "unité", value: "U"},
    {key: "H", label: "heure", value: "H"},
    {key: "KG", label: "kilogramme", value: "KG"},
    {key: "ENS", label: "ensemble", value: "ENS"},
    {key: "M", label: "mètre", value: "M"},
    {key: "M3", label: "mètre cube", value: "M3"},
    {key: "MLI", label: "millilitre", value: "MLI"},
    {key: "M2", label: "mètre carré", value: "M2"}
]

// const INTELIA_API_URL = "http://localhost:80"
export const INTELIA_API_URL = "https://intelia-app-dev.orangemoss-7c38a393.francecentral.azurecontainerapps.io"
