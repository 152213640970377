import { Button, Divider, Textarea } from "@nextui-org/react";

export default function ReportEditor({ ticket, setTicket, analyseTicket, isAnalyzingTicket }) {

    const handleInputChange = (name) => (e) => {
        setTicket((prev) => ({ ...prev, [name]: e.target.value }));
    };

    const handleAnalyseTicket = () => {
        analyseTicket(ticket);
    }

    return (
        <>
            <h2 className="text-2xl font-bold">Compte rendu</h2>
            <Divider className="mb-4" />
            <Textarea
                placeholder="Compte rendu"
                value={ticket.ticketTechnicianRemarks}
                onChange={handleInputChange("ticketTechnicianRemarks")}
                minRows={10}
                maxRows={20}
                size='md'
            />
            <Button onClick={handleAnalyseTicket} variant='flat' isLoading={isAnalyzingTicket}>
                {isAnalyzingTicket ? "Analyse en cours..." : "Analyse IntelIA"}
            </Button>
        </>
    )
}