import { combineReducers } from "redux";
import C2CSlice from "./C2CSlice";
import C2CAdminSlice from "./C2CAdminSlice";

const C2CReducer = combineReducers({
  C2C: C2CSlice,
  C2CAdmin: C2CAdminSlice,
});

export default C2CReducer;
