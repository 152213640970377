
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  strokeColor: '#ff0000',
  strokeWidth: 2,
  textColor: '#00ff00',
  fontSize: 17,
  pointSize: 17,
  fillColor: '#0000ff',
  opacity: 1,
  styleChangedIncrement: 0,
  imageUrl: "",
  svgUrl: "",
};

const canvasSlice = createSlice({
  name: 'canvas',
  initialState,
  reducers: {
    setStrokeColor(state, action) {
      state.strokeColor = action.payload;
    },
    setStrokeWidth(state, action) {
      state.strokeWidth = action.payload;
    },
    setTextColor(state, action) {
      state.textColor = action.payload;
    },
    setFontSize(state, action) {
      state.fontSize = action.payload;
    },
    setPointSize(state, action) {
      state.pointSize = action.payload;
    },
    setFillColor(state, action) {
      state.fillColor = action.payload;
    },
    setOpacity(state, action) {
      state.opacity = action.payload;
    },
    setStyleChanged(state, action) {
      state.styleChangedIncrement = state.styleChangedIncrement + 1;
    },
    setImageUrl(state, action) {
      state.imageUrl = action.payload;
    },
    setSVGUrl(state, action) {
      state.svgUrl = action.payload;
    },
  },
});

export const { setStrokeColor, setStrokeWidth, setFontSize, setPointSize, setTextColor, setFillColor, setOpacity, setStyleChanged, setImageUrl, setSVGUrl } = canvasSlice.actions;

export const selectStrokeColor = (state) => state.canvas.strokeColor;
export const selectStrokewidth = (state) => state.canvas.strokeWidth;
export const selectTextColor = (state) => state.canvas.textColor;
export const selectFontSize = (state) => state.canvas.fontSize;
export const selectFillColor = (state) => state.canvas.fillColor;
export const selectOpacity = (state) => state.canvas.opacity;
export const selectPointSize = (state) => state.canvas.pointSize;
export const selectStyleChanged = (state) => state.canvas.styleChangedIncrement;
export const selectImageUrl = (state) => state.canvas.imageUrl;
export const selectSVGUrl = (state) => state.canvas.svgUrl;

export default canvasSlice.reducer;