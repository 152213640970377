export const introSentences = [
    "Transformez votre quotidien avec DIANE : l'IA au service de votre efficacité !",
    "Explorez les services IA de DIANE et transformez vos défis en opportunités.",
    "Avec DIANE, l'intelligence artificielle devient votre meilleure alliée.",
    "Libérez votre potentiel avec les outils IA de DIANE.",
    "Avec DIANE, l'innovation est à votre portée.",
    "Avec DIANE, l'innovation est au cœur de votre quotidien.",
    "Boostez votre productivité grâce à l'IA de DIANE, votre alliée au travail.",
    "Simplifiez vos tâches quotidiennes avec l'intelligence artificielle de DIANE.",
    "Avec DIANE, chaque collaborateur a accès à l'intelligence artificielle.",
    "Avec DIANE, l'innovation est à portée de main : explorez nos services IA !",
    "Faites le choix de l'innovation avec DIANE, votre partenaire IA.",
    "Révolutionnez votre façon de travailler avec les solutions IA de DIANE.",
    "DIANE : l'IA qui rend votre travail plus simple et plus rapide.",
    "DIANE : l'IA qui comprend vos besoins et optimise votre travail.",
    "Réinventez votre façon de travailler avec les services IA de DIANE.",
    "DIANE : l'intelligence artificielle qui vous fait gagner du temps.",
    "DIANE : l'IA qui rend votre travail plus agréable et productif.",
    "DIANE : votre porte d'entrée vers un futur intelligent et connecté.",
    "Optimisez vos projets avec l'assistance IA de DIANE.",
    "DIANE : l'IA qui vous aide à atteindre vos objectifs plus rapidement.",
    "Découvrez le pouvoir de l'IA avec DIANE et révolutionnez votre travail.",
    "L'IA de DIANE : une nouvelle dimension pour votre travail.",
    "DIANE : l'intelligence artificielle qui s'adapte à votre rythme.",
    "Faites un pas vers l'avenir avec DIANE et ses solutions intelligentes.",
    "Transformez vos idées en réalité avec l'aide de DIANE.",
    "DIANE : votre partenaire pour une collaboration optimisée.",
    "Avec DIANE, chaque jour est une nouvelle opportunité d'innover.",
    "Transformez vos processus avec l'intelligence artificielle de DIANE."
];