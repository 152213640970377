import React from "react";
import ProtectedRoute from "../components/login/protected-route";

const Contracts = React.lazy(() => import("../pages/services/Contractualisation/index"));
const AdminContracts = React.lazy(() => import("../pages/services/Contractualisation/admin-contracts"));
const AdminFormations = React.lazy(() => import("../pages/services/Contractualisation/admin-formations"));
const AskContract = React.lazy(() => import("../pages/services/Contractualisation/ask-contract"));
const AskFormation = React.lazy(() => import("../pages/services/Contractualisation/ask-formation"));
const Catalog = React.lazy(() => import("../pages/services/Contractualisation/catalog"));
const MyContracts = React.lazy(() => import("../pages/services/Contractualisation/contracts"));
const MyFormations = React.lazy(() => import("../pages/services/Contractualisation/formations"));

{/* <ProtectedRoute roles={["baseauth", "Plateforme.Service17", "Plateforme.FullAccess"]}> */}
const router = {
  path: "/",
  element: "",
  children: [
    {
      path: "/contrats",
      element: (
        <ProtectedRoute roles={["Plateforme.FullAccess"]}>
            <Contracts />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "administrateur_contrats",
          element: (
            <ProtectedRoute roles={["Plateforme.FullAccess"]}>
              <AdminContracts />
            </ProtectedRoute>
          )
        },
        {
          path: "administrateur_formations",
          element: (
            <ProtectedRoute roles={["Plateforme.FullAccess"]}>
              <AdminFormations />
            </ProtectedRoute>
          )
        },
        {
          path: "mes_contrats",
          element: <MyContracts />
        },
        {
          path: "mes_formations",
          element: <MyFormations />
        },
        {
          path: "catalogue",
          element: <Catalog />
        },
        {
          path: "demande_solution",
          element: <AskContract
            specific={false}
          />
        },
        {
          path: ":id/demande_solution",
          element: <AskContract
            specific={true}
          />
        },
        {
          path: "demande_formation",
          element: <AskFormation
            specific={false}
          />
        },
        {
          path: ":id/demande_formation",
          element: <AskFormation
            specific={true}
          />
        }
      ]
    }
  ],
};

export default router;
