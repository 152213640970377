import React, { useEffect, useMemo, useState } from "react";
import PageSelector from "../../../../components/file/page-selector";
import { useDispatch, useSelector } from "react-redux";
import { changeStatus, setCurrentSelectedPages, setSasPdf } from "../../../../store/features/Comptage/project-info-slice";
import { useOutletContext } from "react-router-dom";
import { setCurrentFileSelectedPages, setSegmentationReady, updatePages } from "../../../../store/features/Comptage/configuration";
import { Button } from "@nextui-org/react";
import YesNoAlert from "../../../../components/modals/AlertYesNo";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";
import config from "../../../../config";
import Alert from "../../../../components/modals/Alert";

const containerName = "comptagev2";
export default function Pages () {
  const {isLoading, setIsLoading, loadingMessage, setLoadingMessage, pagesReady, setPagesReady} = useOutletContext();
  const { launch_request } = useAzureFuncApp();

  const [selectedPages, setSelectedPages] = useState([]);
  const [lastSelectedPagesValidated, setLastSelectedPagesValidated] = useState([]);
  const pages = useSelector((state) => state.Comptage.Configuration.pages);
  const user = useSelector((state) => state.Comptage.ProjectInfos.creator_name);
  const projectName = useSelector((state) => state.Comptage.ProjectInfos.project_name);
  
  const [previewFiles, setPreviewFiles] = useState([]);
  const currentFile = useSelector((state) => state.Comptage.Configuration.current_file);
  const projectFiles = useSelector((state) => state.Comptage.ProjectInfos.project_files);
  const dispatch = useDispatch();

  useEffect(() => {
    let pFile = projectFiles.find((pf) => pf.name === currentFile);
    if (pFile !== undefined) {
      retrieve_pdf(pFile);
      setSelectedPages(pFile.pages.map((x, i)=>{ return [i, x.selected];}).filter(x=>x[1] === true).map(x=>x[0]));
      setLastSelectedPagesValidated(pFile.pages.map((x, i)=>{ return [i, x.selected];}).filter(x=>x[1] === true).map(x=>x[0]));
    }
  }, []);
  
  const haveSameElements = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
  
    return true;
  };

  useEffect(() => {
    setPagesReady(haveSameElements(selectedPages, lastSelectedPagesValidated));
    dispatch(setCurrentSelectedPages({
      filename: currentFile,
      selectedPages: selectedPages
    }));
    dispatch(setCurrentFileSelectedPages(selectedPages));
  }, [selectedPages, lastSelectedPagesValidated]);

  const prepageSegmentationPages = async (pagesToConvert) => {
    await Promise.all(pagesToConvert.map(async (item) => {
      const result = await prepareSegmentation(item, currentFile);
    }));
    Alert("Fichier prêt",
      `Les fonctions de légende et comptage automatique sont à présent disponibles pour les pages ${selectedPages.map(x => x+1).join(", ")} du fichier ${currentFile} (projet ${projectName}).`
      );
  }

  const prepareSegmentation = async (page, filename) => {
    let segmentationReady = pages.find(x => x.page === page)?.segmentation_ready;
    if (!segmentationReady || segmentationReady === "not_started") {
      dispatch(setSegmentationReady({ page: page, segmentation: "ongoing"}))

      let payload = {
          "function": "segment_items",
          "username": user,
          "project_name": projectName,
          "container_name": containerName,
          "pdf_name": filename,
          "page_number": page+1
      };

      let [status_code, result] = await launch_request(config.apiSegmentationUri, payload, {alertError: false});
       
      if (status_code === 200) {
        segmentationReady = result.segmentation;
      } else if (status_code === 504) {
          segmentationReady = "ongoing";
      } else {
          segmentationReady = "failed";
      }

      if (segmentationReady === "ready" || segmentationReady === "failed") {
          dispatch(setSegmentationReady({ page: page, segmentation: segmentationReady}));
      }
    }

    if (segmentationReady === "ready" || segmentationReady === "failed") {
      return true;
    } else {
      return false;
    }
  };

  const preparePdfImages = async (pages) => {
    let payload = {
        "function": "pdf2img",
        "container_name": containerName,
        "project_name": projectName,
        "username": user,
        "file": currentFile,
        "all_pages": false,
        "selected_pages_numbers": pages
    };

    let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);

    if (status_code === 200) {
        console.log(result);
        let url_pdf = result.url_pdf;
        let previews = result.previews;
        let pages = result.pages;
        dispatch(setSasPdf({
            filename: currentFile,
            url: url_pdf,
            previews: previews
        }));
        dispatch(updatePages(pages));
        dispatch(changeStatus({
            filename: currentFile,
            status: "ready",
            pages: pages
        }));

        return true;
    }

    return false;
  };

  const runSegmentation = async () => {
    setIsLoading(true);
    setLoadingMessage("Préparation des pages...");

    let pagesToConvert = selectedPages.filter(p => p >= pages.length || !pages[p].segmentation_ready || pages[p].segmentation_ready === "not_started");
    if (pagesToConvert.length > 0) {
      prepageSegmentationPages(pagesToConvert);
      Alert(
        "Fichier en cours de préparation",
        `Les fonctions de légende automatique et comptage sur l'ensemble des symboles sont en cours de préparation pour ${pagesToConvert.length > 1 ? `les pages ${pagesToConvert.map(x => x+1).join(", ")}` : `la page ${pagesToConvert.map(x => x+1).join(", ")}`}. Cela peut prendre quelques minutes. Nous vous préviendrons lorsque le fichier sera prêt.`
      );
    }
    
    let needPatch  = selectedPages.some(p => p >= pages.length || !pages[p]?.patches.length > 0);
    const succeeded = needPatch ? await preparePdfImages(selectedPages) : true;
    setIsLoading(false);

    if (!succeeded) {
      return;
    }

    setPagesReady(true);
    setLastSelectedPagesValidated(selectedPages);
    };

  return (
    <div className="flex flex-col flex-grow items-center w-full p-4 gap-2">
      <div className="flex-grow-1 overflow-hidden">
        <PageSelector
          pdfName={currentFile}
          pagePreviews={previewFiles}
          selectedPages={selectedPages}
          setSelectedPages={setSelectedPages}
        />
      </div>
      <Button className="flex-shrink-0" color="danger" onPress={runSegmentation}>Valider les pages</Button>
    </div>
  );

  async function retrieve_pdf(pFile) {
    setIsLoading(true);
    const filePreviews = pFile.previews;
    setPreviewFiles(filePreviews);
    setIsLoading(false);
  }
};
