import * as turf from "@turf/turf";

export const isPointInPolygon = function (polygon, point) {
    //A point is in a polygon if a line from the point to infinity crosses the polygon an odd number of times
    let odd = false;
    //For each edge (In this case for each point of the polygon and the previous one)
    for (let i = 0, j = polygon.length - 1; i < polygon.length; i++) {
        //If a line from the point into infinity crosses this edge
        if (((polygon[i].y > point.y) !== (polygon[j].y > point.y)) // One point needs to be above, one below our y coordinate
            // ...and the edge doesn't cross our Y corrdinate before our x coordinate (but between our x coordinate and infinity)
            && (point.x < ((polygon[j].x - polygon[i].x) * (point.y - polygon[i].y) / (polygon[j].y - polygon[i].y) + polygon[i].x))) {
            // Invert odd
            odd = !odd;
        }
        j = i;

    }
    //If the number of crossings was odd, the point is in the polygon
    return odd;
};

export const getPolylineLength = function (points) {
    return points.reduce((length, point, i) => {
        if (i === 0) return length;
        const dx = point.x - points[i - 1].x;
        const dy = point.y - points[i - 1].y;
        return length + Math.sqrt(dx * dx + dy * dy);
    }, 0);
};

export const intersectionLengthLinePolygon = function (linePoints, polygonPoints) {
    // Create a LineString from the array of points
    let line = turf.lineString(linePoints.map(x => [x.x, x.y]));

    // Ensure the polygon is closed (first point == last point)
    let polygonCoords = polygonPoints.map(x => [x.x, x.y]);
    polygonCoords.push([polygonPoints[0].x, polygonPoints[0].y]);  // Closing the polygon

    // Create a Polygon from the array of points
    let polygon = turf.polygon([polygonCoords]);

    // Check if the line is fully within the polygon
    if (turf.booleanWithin(line, polygon)) {
        // If the line is fully inside the polygon, just calculate the length of the line
        let totalLength = getPolylineLength(linePoints);
        return totalLength;
    }

    // Split the line by the polygon boundary
    let splitLine = turf.lineSplit(line, polygon);

    // Check for intersection and return the intersecting line segments
    let intersectingLines = splitLine.features.filter(segment =>
        turf.booleanWithin(segment, polygon)
    );

    if (intersectingLines.length > 0) {
        // Calculate the total length of all intersecting line segments
        let totalLength = intersectingLines
            .map(feature => feature.geometry.coordinates.map(x => ({"x": x[0], "y": x[1]})))
            .map(i => getPolylineLength(i))
            .reduce((partialSum, a) => partialSum + a, 0);

        return totalLength;  // Return the total length of intersecting lines
    } else {
        return 0;
    }
};