import * as React from "react"
const BrokenPageImage = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={600} height={600} {...props}>
    <defs>
      <clipPath id="a" clipPathUnits="userSpaceOnUse">
        <path d="M2954.7 1552.69H1778.17v101.41h1057.55v1099.12h107.87l11.11-1200.53" />
      </clipPath>
    </defs>
    <path
      d="M994.871 1066.49s-364.73 164.95-229.551 457.18c135.149 292.34 405.41 741.05 270.3 969.82-135.151 228.76-148.925 961.61 628.18 1073.85 619.75 89.55 808.11-279.01 904.26-530.53 126.74-331.89 13.47-937.65 606.11-1017.6 592.64-79.88 1148.91-558.97 581.19-993.98-233.96-179.269-2760.489 41.26-2760.489 41.26"
      style={{
        fill: "#efeefe",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1529.37 1389.89c-334.75-159.14-646.847-118.87-591.237 86 55.683 204.91-9.668 213.83-98.606 253.55-88.902 39.71-295.757 159.68-171.004 373.34 124.723 213.62-12.781 235.15-88.488 326.11-220.902 265.47 121.883 421.81 261.438 216.15 139.59-205.59-32.539-393.99 146.668-463.68 179.199-69.66 222.989-118.23 246.539-250.4 23.55-132.14 34.65-422.12 189.66-373.86 154.87 48.16 276.72-85.59 105.03-167.21"
      style={{
        fill: "#edb23b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1110.69 1338.12c-69.38 0-126.506 27.05-111.717 123.55 43.647 284.61-174.453 333.59-241.614 360.56-67.132 27-125.953 151.32-25.875 331.07 77.485 139.14-57.796 239.63-130.226 323.75-23.582 27.38-91.942 157.67-2.528 181.59 7.954 2.13 15.778 3.14 23.43 3.14 78.461 0 139.168-105.83 140.633-196.42 1.148-66.84 23.375-154.44 53.605-220.63 19.809-75.14 69.5-139.69 120.848-189.47 7.859-43.01-9.031-87.37-44.023-151.71 0 0-27.961-82.34 72.496-140.38 60.551-34.98 90.981-128.86 128.351-200.56-25.04-92.06 19.67-164.95 111.22-212.15-30.45-6.99-63.73-12.34-94.6-12.34"
      style={{
        fill: "#f1c147",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1893.66 1343.55c-494.48-134.95-909.453-4-783.77 261.26 125.74 265.34 38.96 293.47-72.36 369.25-111.358 75.71-363.604 289.5-141.71 549.88 221.93 260.32 39.957 323.17-40.879 465.53-235.804 415.25 623.579 634.68 408.649 230.4-161.32-303.41-140.51-528.34 86.48-666.91 226.92-138.6 274.67-215.36 274.46-400.99-.17-185.7-55.78-583.04 166.93-555.22 222.62 27.79 355.78-183.99 102.2-253.2"
      style={{
        fill: "#d086e5",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1403.55 1367.97c-131.28 0-271.69 35.12-214.4 202.73 7.28 21.27 13.21 41.64 17.91 61.16v-79.17h214.57c8.21-5.41 17.14-9.68 26.91-12.64 138.39-41.87 220.46-142.05 127.97-156.55-32.65-5.11-101.46-15.53-172.96-15.53m-196.49 477.13c-48.5 158.83-206.05 221.04-258.74 275.32-69.691 71.81-134.566 236.73 45.528 456.94 139.352 170.49-20.133 340.23-98.231 472.44-25.371 42.91-41.969 258.53 167.783 258.53h.65c128.07-.27 70.65-178.14 48.64-313.87-22.04-135.69 8.37-345.42 66.34-429.54 10.55-15.31 19.88-29.78 28.03-43.56v-249.97c-14.5-22.58-32.56-46.71-54-73.46 0 0-54.68-99.13 54-199.53v-153.3"
      style={{
        fill: "#d99eea",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="m1227.64 1807.36 21.5 30.39c-482.995 340.29-510.617 861.19-510.82 866.59l-37.257-1.67c.375-5.3 28.203-544.14 526.577-895.31"
      style={{
        fill: "#f95f6b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M726.906 2675.19s17.465 210.31-99.328 197.11c-49.629-5.57-70.82-101.41 99.328-197.11M738.523 2597.94s-125.675 185.63-193.351 92.49c-28.848-39.82 20.062-133.92 193.351-92.49"
      style={{
        fill: "#f95f6b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M729.059 2579.28s-27.789 214.92 89.894 177.25c50.176-15.99 76.254-116.99-89.894-177.25M787.984 2404.76s-37.871 214.82 80.938 182.76c50.586-13.61 81.109-113.62-80.938-182.76"
      style={{
        fill: "#f95f6b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M846.293 2265.13s-37.051 214.65 81.656 182.35c50.692-13.6 80.901-113.78-81.656-182.35"
      style={{
        fill: "#f95f6b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M945.758 2107.36s-72.735 209.08 46.859 198.75c50.993-4.44 97.313-99.49-46.859-198.75"
      style={{
        fill: "#f95f6b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1041.83 1991.22s-67.092 210.58 52.85 196.53c50.75-6.02 94.68-102.06-52.85-196.53"
      style={{
        fill: "#f95f6b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1187.2 1860.79s-118.46 190.31-3.83 211.16c48.85 8.85 115.29-74.17 3.83-211.16M772.125 2449.8s-125.852 185.84-193.527 92.43c-28.778-39.65 20.031-133.61 193.527-92.43"
      style={{
        fill: "#f95f6b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M830.844 2305.02s-126.051 185.66-193.625 92.39c-28.781-39.62 20.058-133.68 193.625-92.39M908.23 2172.85s-159.894 156.13-203.164 46.45c-18.496-46.59 48.43-129.34 203.164-46.45M1008.82 2035.41s-172.777 139.83-204.09 24.61c-13.191-49.12 60.602-125.06 204.09-24.61M1127.97 1915.37s-187.068 114.88-201.56-6.22c-5.844-51.54 76.49-116.82 201.56 6.22"
      style={{
        fill: "#f95f6b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M3856.7 1028.61c0-60.661-678.91-109.68-1516.41-109.68-837.4 0-1516.317 49.019-1516.317 109.68 0 60.57 678.917 109.62 1516.317 109.62 837.5 0 1516.41-49.05 1516.41-109.62"
      style={{
        fill: "#d8d8d8",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1207.06 1552.69H2954.7v1633.55H1207.06Z"
      style={{
        fill: "#d3d5fd",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1326.07 1654.1h1509.65V2899H1326.07ZM2954.7 3006.05H1207.06v180.19H2954.7v-180.19"
      style={{
        fill: "#bcc0ed",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1382.4 3051.06h-99.53v95.77h99.53v-95.77M2869.53 3051.06h-99.57v95.77h99.57v-95.77M2734.31 3051.06h-99.53v95.77h99.53v-95.77M2599.13 3051.06h-99.53v95.77h99.53v-95.77M1645.18 2805.31h-234.61v26.08h234.61v-26.08"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1621.73 2727.07h-93.86v26.15h93.86v-26.15M1746.83 2727.07h-93.79v26.15h93.79v-26.15"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1778.17 2727.07h93.816v26.148h-93.816ZM1903.23 2727.07h93.86v26.148h-93.86ZM2020.61 2727.07h93.79v26.148h-93.79Z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1660.87 2648.9h-93.9v26.08h93.9v-26.08M1692.1 2648.9h93.828v26.082H1692.1Z"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1817.27 2648.9h93.79v26.082h-93.79ZM1942.37 2648.9h93.86v26.082h-93.86ZM2059.67 2648.9h93.86v26.082h-93.86ZM2216.05 2648.9h93.89v26.082h-93.89ZM1645.18 2570.7h-234.61v26.08h234.61v-26.08"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1621.73 2492.49h-93.86v26.05h93.86v-26.05M1746.83 2492.49h-93.79v26.05h93.79v-26.05"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1778.17 2492.49h93.816v26.05h-93.816ZM1903.23 2492.49h93.86v26.05h-93.86ZM2020.61 2492.49h93.79v26.05h-93.79Z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1660.87 2414.26h-93.9v26.11h93.9v-26.11M1692.1 2414.26h93.828v26.11H1692.1Z"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1817.27 2414.26h93.79v26.11h-93.79ZM1942.37 2414.26h93.86v26.11h-93.86ZM2059.67 2414.26h93.86v26.11h-93.86ZM2216.05 2414.26h93.89v26.11h-93.89ZM1684.28 2336.09h234.641v26.082H1684.28Z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1801.58 2257.85h93.86v26.117h-93.86ZM1926.75 2257.85h93.86v26.117h-93.86Z"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2051.85 2257.85h93.848v26.117h-93.848ZM2176.94 2257.85h93.898v26.117h-93.898ZM2294.28 2257.85h93.89v26.117h-93.89Z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1840.72 2179.65h93.82v26.082h-93.82ZM1965.85 2179.65h93.82v26.082h-93.82Z"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2090.98 2179.65h93.86v26.082h-93.86ZM2216.05 2179.65h93.89v26.082h-93.89ZM2333.38 2179.65h93.86v26.082h-93.86ZM2489.79 2179.65h93.86v26.082h-93.86ZM1684.28 2062.38h234.641v26.043H1684.28Z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1801.58 1984.14h93.86v26.05h-93.86ZM1926.75 1984.14h93.86v26.05h-93.86Z"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2051.85 1984.14h93.848v26.05h-93.848ZM2176.94 1984.14h93.898v26.05h-93.898ZM2294.28 1984.14h93.89v26.05h-93.89Z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1840.72 1905.94h93.82v26.11h-93.82ZM1965.85 1905.94h93.82v26.11h-93.82Z"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2090.98 1905.94h93.86v26.11h-93.86ZM2216.05 1905.94h93.89v26.11h-93.89ZM2333.38 1905.94h93.86v26.11h-93.86ZM2489.79 1905.94h93.86v26.11h-93.86ZM1645.18 1866.84h-234.61v26.08h234.61v-26.08"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1621.73 1788.6h-93.86v26.15h93.86v-26.15M1746.83 1788.6h-93.79v26.15h93.79v-26.15"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1778.17 1788.6h93.816v26.148h-93.816ZM1903.23 1788.6h93.86v26.148h-93.86ZM2020.61 1788.6h93.79v26.148h-93.79Z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1660.87 1710.43h-93.9v26.08h93.9v-26.08M1692.1 1710.43h93.828v26.082H1692.1Z"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1817.27 1710.43h93.79v26.082h-93.79ZM1942.37 1710.43h93.86v26.082h-93.86ZM2059.67 1710.43h93.86v26.082h-93.86ZM2216.05 1710.43h93.89v26.082h-93.89Z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <g clipPath="url(#a)" transform="matrix(.13333 0 0 -.13333 0 600)">
      <path
        d="M1778.17 1552.69H2954.7v1200.53H1778.17Z"
        style={{
          fill: "#a9aaca",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
    </g>
    <path
      d="M2835.72 1654.1H1778.17v56.33h7.76v26.08h-7.76v52.09h93.82v26.15h-93.82v247.63h140.75v26.04h-140.75v247.67h140.75v26.08h-140.75v52.09h7.76v26.11h-7.76v52.12h93.82v26.05h-93.82v130.36h7.76v26.08h-7.76v52.09h93.82v26.15h31.24v-26.15h93.86v26.15h23.52v-26.15h93.79v26.15h721.32V1654.1m-619.67 82.41v-26.08h93.89v26.08h-93.89m-156.38 0v-26.08h93.86v26.08h-93.86m-117.3 0v-26.08h93.86v26.08h-93.86m-125.1 0v-26.08h93.79v26.08h-93.79m203.34 78.24v-26.15h93.79v26.15h-93.79m-117.38 0v-26.15h93.86v26.15h-93.86m586.56 117.3v-26.11h93.86v26.11h-93.86m-156.41 0v-26.11h93.86v26.11h-93.86m-117.33 0v-26.11h93.89v26.11h-93.89m-125.07 0v-26.11h93.86v26.11h-93.86m-125.13 0v-26.11h93.82v26.11h-93.82m-125.13 0v-26.11h93.82v26.11h-93.82m453.56 78.14v-26.05h93.89v26.05h-93.89m-117.34 0v-26.05h93.9v26.05h-93.9m-125.09 0v-26.05h93.85v26.05h-93.85m-125.1 0v-26.05h93.86v26.05h-93.86m-125.17 0v-26.05h93.86v26.05h-93.86m688.21 195.54v-26.08h93.86v26.08h-93.86m-156.41 0v-26.08h93.86v26.08h-93.86m-117.33 0v-26.08h93.89v26.08h-93.89m-125.07 0v-26.08h93.86v26.08h-93.86m-125.13 0v-26.08h93.82v26.08h-93.82m-125.13 0v-26.08h93.82v26.08h-93.82m453.56 78.24v-26.12h93.89v26.12h-93.89m-117.34 0v-26.12h93.9v26.12h-93.9m-125.09 0v-26.12h93.85v26.12h-93.85m-125.1 0v-26.12h93.86v26.12h-93.86m-125.17 0v-26.12h93.86v26.12h-93.86m414.47 156.4v-26.11h93.89v26.11h-93.89m-156.38 0v-26.11h93.86v26.11h-93.86m-117.3 0v-26.11h93.86v26.11h-93.86m-125.1 0v-26.11h93.79v26.11h-93.79m203.34 78.17v-26.05h93.79v26.05h-93.79m-117.38 0v-26.05h93.86v26.05h-93.86m312.82 156.44v-26.08h93.89v26.08h-93.89m-156.38 0v-26.08h93.86v26.08h-93.86m-117.3 0v-26.08h93.86v26.08h-93.86m-125.1 0v-26.08h93.79v26.08h-93.79"
      style={{
        fill: "#969abe",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1778.17 2727.07h93.816v26.148h-93.816ZM1903.23 2727.07h93.86v26.148h-93.86ZM2020.61 2727.07h93.79v26.148h-93.79Z"
      style={{
        fill: "#ccc",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1778.17 2648.9h7.758v26.082h-7.758z"
      style={{
        fill: "#c06a1b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1817.27 2648.9h93.79v26.082h-93.79ZM1942.37 2648.9h93.86v26.082h-93.86ZM2059.67 2648.9h93.86v26.082h-93.86ZM2216.05 2648.9h93.89v26.082h-93.89ZM1778.17 2492.49h93.816v26.05h-93.816ZM1903.23 2492.49h93.86v26.05h-93.86ZM2020.61 2492.49h93.79v26.05h-93.79Z"
      style={{
        fill: "#ccc",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1778.17 2414.26h7.758v26.11h-7.758z"
      style={{
        fill: "#c06a1b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1817.27 2414.26h93.79v26.11h-93.79ZM1942.37 2414.26h93.86v26.11h-93.86ZM2059.67 2414.26h93.86v26.11h-93.86ZM2216.05 2414.26h93.89v26.11h-93.89ZM1778.17 2336.09h140.75v26.082h-140.75z"
      style={{
        fill: "#ccc",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1801.58 2257.85h93.86v26.117h-93.86ZM1926.75 2257.85h93.86v26.117h-93.86Z"
      style={{
        fill: "#c06a1b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2051.85 2257.85h93.848v26.117h-93.848ZM2176.94 2257.85h93.898v26.117h-93.898ZM2294.28 2257.85h93.89v26.117h-93.89Z"
      style={{
        fill: "#ccc",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1840.72 2179.65h93.82v26.082h-93.82ZM1965.85 2179.65h93.82v26.082h-93.82Z"
      style={{
        fill: "#c06a1b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2090.98 2179.65h93.86v26.082h-93.86ZM2216.05 2179.65h93.89v26.082h-93.89ZM2333.38 2179.65h93.86v26.082h-93.86ZM2489.79 2179.65h93.86v26.082h-93.86ZM1778.17 2062.38h140.75v26.043h-140.75z"
      style={{
        fill: "#ccc",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1801.58 1984.14h93.86v26.05h-93.86ZM1926.75 1984.14h93.86v26.05h-93.86Z"
      style={{
        fill: "#c06a1b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2051.85 1984.14h93.848v26.05h-93.848ZM2176.94 1984.14h93.898v26.05h-93.898ZM2294.28 1984.14h93.89v26.05h-93.89Z"
      style={{
        fill: "#ccc",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1840.72 1905.94h93.82v26.11h-93.82ZM1965.85 1905.94h93.82v26.11h-93.82Z"
      style={{
        fill: "#c06a1b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2090.98 1905.94h93.86v26.11h-93.86ZM2216.05 1905.94h93.89v26.11h-93.89ZM2333.38 1905.94h93.86v26.11h-93.86ZM2489.79 1905.94h93.86v26.11h-93.86ZM1778.17 1788.6h93.816v26.148h-93.816ZM1903.23 1788.6h93.86v26.148h-93.86ZM2020.61 1788.6h93.79v26.148h-93.79Z"
      style={{
        fill: "#ccc",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1778.17 1710.43h7.758v26.082h-7.758z"
      style={{
        fill: "#c06a1b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M1817.27 1710.43h93.79v26.082h-93.79ZM1942.37 1710.43h93.86v26.082h-93.86ZM2059.67 1710.43h93.86v26.082h-93.86ZM2216.05 1710.43h93.89v26.082h-93.89Z"
      style={{
        fill: "#ccc",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M3588.33 1050.05H1840.72v1633.58h1747.61V1050.05"
      style={{
        fill: "#f5feff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M3469.38 1151.42H1959.73v1244.89h1509.65V1151.42M3588.33 2503.37H1840.72v180.26h1747.61v-180.26"
      style={{
        fill: "#bcc0ed",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2016.06 2548.45h-99.56v95.73h99.56v-95.73M3503.15 2548.45h-99.57v95.73h99.57v-95.73M3367.93 2548.45h-99.53v95.73h99.53v-95.73M3232.75 2548.45h-99.53v95.73h99.53v-95.73M2278.8 2302.7h-234.58v26.04h234.58v-26.04"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2255.39 2224.46h-93.89v26.11h93.89v-26.11M2380.45 2224.46h-93.76v26.11h93.76v-26.11"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2505.62 2224.46h-93.83v26.11h93.83v-26.11M2630.78 2224.46h-93.89v26.11h93.89v-26.11M2748.05 2224.46h-93.82v26.11h93.82v-26.11"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2294.49 2146.26h-93.89v26.08h93.89v-26.08M2419.59 2146.26h-93.86v26.08h93.86v-26.08"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2544.68 2146.26h-93.75v26.08h93.75v-26.08M2669.85 2146.26h-93.86v26.08h93.86v-26.08M2787.15 2146.26h-93.85v26.08h93.85v-26.08M2943.59 2146.26h-93.89v26.08h93.89v-26.08M2278.8 2068.02h-234.58v26.15h234.58v-26.15"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2255.39 1989.85h-93.89v26.08h93.89v-26.08M2380.45 1989.85h-93.76v26.08h93.76v-26.08"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2505.62 1989.85h-93.83v26.08h93.83v-26.08M2630.78 1989.85h-93.89v26.08h93.89v-26.08M2748.05 1989.85h-93.82v26.08h93.82v-26.08"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2294.49 1911.64h-93.89v26.09h93.89v-26.09M2419.59 1911.64h-93.86v26.09h93.86v-26.09"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2544.68 1911.64h-93.75v26.09h93.75v-26.09M2669.85 1911.64h-93.86v26.09h93.86v-26.09M2787.15 1911.64h-93.85v26.09h93.85v-26.09M2943.59 1911.64h-93.89v26.09h93.89v-26.09M2552.58 1833.44h-234.65v26.05h234.65v-26.05"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2529.1 1755.21h-93.86v26.11h93.86v-26.11M2654.23 1755.21h-93.86v26.11h93.86v-26.11"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2779.36 1755.21h-93.86v26.11h93.86v-26.11M2904.46 1755.21h-93.86v26.11h93.86v-26.11M3021.8 1755.21h-93.86v26.11h93.86v-26.11"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2568.2 1677.04h-93.86v26.07h93.86v-26.07M2693.3 1677.04h-93.79v26.07h93.79v-26.07"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2818.46 1677.04h-93.86v26.07h93.86v-26.07M2943.59 1677.04h-93.89v26.07h93.89v-26.07M3060.9 1677.04h-93.89v26.07h93.89v-26.07M3217.27 1677.04h-93.82v26.07h93.82v-26.07M2552.58 1559.7h-234.65v26.11h234.65v-26.11"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2529.1 1481.5h-93.86v26.07h93.86v-26.07M2654.23 1481.5h-93.86v26.07h93.86v-26.07"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2779.36 1481.5h-93.86v26.07h93.86v-26.07M2904.46 1481.5h-93.86v26.07h93.86v-26.07M3021.8 1481.5h-93.86v26.07h93.86v-26.07"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2568.2 1403.33h-93.86v26.04h93.86v-26.04M2693.3 1403.33h-93.79v26.04h93.79v-26.04"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2818.46 1403.33h-93.86v26.04h93.86v-26.04M2943.59 1403.33h-93.89v26.04h93.89v-26.04M3060.9 1403.33h-93.89v26.04h93.89v-26.04M3217.27 1403.33h-93.82v26.04h93.82v-26.04M2278.8 1364.22h-234.58v26.05h234.58v-26.05"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2255.39 1285.99h-93.89v26.08h93.89v-26.08M2380.45 1285.99h-93.76v26.08h93.76v-26.08"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2505.62 1285.99h-93.83v26.08h93.83v-26.08M2630.78 1285.99h-93.89v26.08h93.89v-26.08M2748.05 1285.99h-93.82v26.08h93.82v-26.08"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2294.49 1207.78h-93.89v26.08h93.89v-26.08M2419.59 1207.78h-93.86v26.08h93.86v-26.08"
      style={{
        fill: "#f08422",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2544.68 1207.78h-93.75v26.08h93.75v-26.08M2669.85 1207.78h-93.86v26.08h93.86v-26.08M2787.15 1207.78h-93.85v26.08h93.85v-26.08M2943.59 1207.78h-93.89v26.08h93.89v-26.08"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2364.28 2565.74c0-229.92-186.42-416.38-416.38-416.38s-416.44 186.46-416.44 416.38c0 230 186.48 416.45 416.44 416.45s416.38-186.45 416.38-416.45"
      style={{
        fill: "#f95f6b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="m2154.83 2431.69-57.97-62.41-355.88 330.65 57.94 62.38 355.91-330.62"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="m1751.75 2416.28 62.58-57.8 329.7 356.83-62.58 57.84-329.7-356.87"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2774.2 1522.82c10.83-152.68 67.44-390.26 75.33-518.71.61-8.919 1.92-10.598-30.62-18.149-37.67-8.723-8.69-26.629 50.31-26.152 55.33.48 80.08 241.071 85.41 368.351 1.58 35.79-12.27 143.93 6.6 245.89 2.33-38.14 5.6-81.52 10.36-129.09 14.15-139.25 180.29-321.81 192.84-438.94.92-9.051-.28-12.54-29.4-21.629-29.25-8.993-.92-25.84 49.67-24.512 50.62 1.23 19.68 243.841-6.57 357.381-23.03 99.33-36.63 193.02-66.07 455.45l.35.1-.35-.07c-.23 2.43-.51 4.51-.78 6.97-5.16 92.32-12.2 151.38 33.8 233.04 66.07 117.1-26.86 152.82-83.05 175.89-56.16 23.21-212.63 44.74-229.28-45.8-14.01-76.5 54.31-189.08 10.53-306.8-2.43-6.77-81.66-137.03-69.08-313.22"
      style={{
        fill: "#ee9d7f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2925.72 2178.69c-9.92 11.35-37.22 27-68.57-1.84-42.04-38.52-97.92-151.56-175.34-168.37-77.45-16.78-113.47 22.69-141.06 46.04-27.62 23.41-27.75 45.63-45.28 57.38-17.54 11.83-30.73-9.53 4.57-57.45 16.99-22.9 86.65-146.19 182.56-143.25 125.85 3.9 290.97 213.38 243.12 267.49"
      style={{
        fill: "#ee9d7f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2828.24 1805.83c100.72-74.07 230.37-51.07 285.23-39.38 14.86-132.07 101.58-626.96 111.76-695.01-44.29-16.03-59.02-19.55-71.81-19.55-236.38 347.51-189.86 484.02-192.19 522.16-8.07-43.92 19.14-310.21-27.07-522.16-15.52-10.97-62.38-25.22-95.91 0-1.64 13.57-63.16 458.45-64.05 470.93-9.23 129.51 30.97 233.93 54.04 283.01"
      style={{
        fill: "#c86cdb",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M3158.28 2040.3s53.55 151.62-117.48 10.01c-25.26-20.74-14.29-120.89-14.29-120.89l70.96-37.39 60.81 148.27"
      style={{
        fill: "#e67052",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M3074.06 2183.72s-68.53-144.82-169.16-122.85c-30.18 6.6-54 49.22 38.87 152.38l-33.54-7.49s-89.34-111.39-118.15-263.15c-28.89-151.72-.38-221.04-.38-221.04s30.59-26.14 169.46-38.62c61.97-5.54 114.5-1.2 160.23 13.16 0 0-19.41 174.04-8.23 233.31 0 0-82.24.14-73.66 110.09 7.18 90.78 61.42 133.75 61.42 133.75l-26.86 10.46"
      style={{
        fill: "#fcdcda",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M3048.7 2268.96s-32.41-41.73-5.06-73.86c27.89-32.98-118.33-73.11-109-46.69 12.06 34.25 34.62 51.03 26.52 95.36l87.54 25.19"
      style={{
        fill: "#e67052",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2994.22 2373.92c-36.14-21.46-96.02-138.25-70.72-172.53 11-14.94 70.34-14.15 118.12 32.3 19.62 19 47.2 56.91 33.94 104.18-5.94 21.43-39.13 61.01-81.34 36.05"
      style={{
        fill: "#f3b294",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M3021.8 1755.21c7.35 22.42-3.87 36.16-41.09 41.21-55.23 7.25-140.95 45.5-94.81 36.78 46.11-8.71 109.65-10.69 161.98 0 52.39 10.7 65.28 96.22 65.28 96.22-11.18-59.27 8.23-233.31 8.23-233.31-45.73-14.36-98.26-18.7-160.23-13.16-138.87 12.48-169.46 38.62-169.46 38.62 183.68-27.44 223.81 15.01 230.1 33.64"
      style={{
        fill: "#efbebd",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2960.51 2365.79c-10.11-14.04-8.99-18.12 36.27-35.62 45.05-17.66 53.69-16.36 46.48-26.45-21.16-29.67 13.5-30.69-12.34-65.93-25.74-35.31 18.8-20.27 43.14 7.76 5.05 5.71 31.61 32.74 19.68 86.4-13.87 62.76-96.07 85.39-133.23 33.84"
      style={{
        fill: "#aa3c3c",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M3030.92 2237.79s-9.33-9.81-32.67 0c-23.42 9.74-55.31 41.77-68.36 40.43-13.23-1.23-29.64-69.14-5.95-85.51 23.65-16.24 94.99-.21 118.67 28.6 5.16 6.19-11.69 16.48-11.69 16.48"
      style={{
        fill: "#aa3c3c",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2963.04 2227.98s-5.84 21.81-21.53 26.38c-15.66 4.59-16.99-15-15.08-22.21 1.99-7.08 23.59-25.12 36.61-21.06 13.06 4.11 2.94 9.27 0 16.89"
      style={{
        fill: "#f3b294",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M3093.74 2331.95c13.78-50.07-14.63-80.69-19.68-86.4-18.05-20.78-36.34-36.68-66.82-47.68-3.18-1.61 19.27 12.64 16.33 22.18-3.38 11.45 27.42 8.61 25.13 38.01-2.26 29.36 17.29 31.17 18.21 56.02 1.2 24.95-17.8 24.37-68.66 44.29-20.75 8.07-23.04 17.33-18.46 25.44 40.84 25.7 99.53.92 113.95-51.86"
      style={{
        fill: "#aa3c3c",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M2900.22 988.801s-44.02-19.281-71.16 0c0 0-49.53-7.422-18.39-20.692 31.27-13.289 91.46-24.328 89.55 20.692M3206.2 984.391s-44.03-19.18-71.17 0c0 0-49.63-7.422-18.35-20.68 31.14-13.262 91.36-24.27 89.52 20.68"
      style={{
        fill: "#7585f8",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
    <path
      d="M3168.29 2120.21s-30.76 30.66-68.22-3.62c-43.65-39.89-70.99-179.86-145.16-215.03-74.14-34.96-119.84-3.65-153.06 13.43-33.36 17.09-38.69 39.41-59.17 47.21-20.37 7.79-28.67-16.82 18.16-56.88 22.42-19.14 121.71-127.14 217.82-101.65 125.89 33.46 250.57 273.17 189.63 316.54"
      style={{
        fill: "#ee9d7f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 0 600)"
    />
  </svg>
)
export default BrokenPageImage;
