import { Button,  Modal, ModalBody, ModalContent, ModalFooter, ModalHeader,Image} from "@nextui-org/react";
import { Icon } from "@iconify/react";

export default function ConfirmationSavedModal({ isOpen,onConfirm,onDecline }) {
    return (
        <Modal 
        isOpen = {isOpen}
        onClose={onDecline}
        backdrop='blur'
        size="s"
        placement = "center"> 
        <ModalContent>
            <ModalHeader className="flex items-center gap-1">
                <Icon icon="solar:folder-cloud-line-duotone"  style={{ color: '#006fee' }} />
                Sauvegarde effectuée avec succès
            </ModalHeader>
            <ModalBody>
                <p>
                     Le profil de la rue a été correctement sauvegardé.
                </p>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onConfirm} >
                    Quitter la page
                </Button>
            </ModalFooter>
    </ModalContent>
    </Modal>
    )
}