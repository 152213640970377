import { useState } from "react";
import Alert from "../../../../../components/modals/Alert";
import { INTELIA_API_URL } from "../../const";

export default function useBPU() {
    const [bpu, setBPU] = useState([]);

    // Loading state
    const [isUpdatingBPU, setIsUpdatingBPU] = useState(false);
    const [isGettingBPU, setIsGettingBPU] = useState(false);

    const updateBPU = async (file, code_entreprise) => {
        setIsUpdatingBPU(true);
        try {
            const formData = new FormData();
            formData.append("file", file);

            let url = `${INTELIA_API_URL}/v2/bpu`;
            if (code_entreprise) {
                url += `/${code_entreprise}`;
            }

            const response = await fetch(url, {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || "Erreur inconnue");
            }
            const data = await response.json();
            console.log("BPU data: ", data);
            Alert("Information", "Les BPU ont été mis à jour avec succès.");
            return data;
        } catch (error) {
            console.error(error);
            Alert(
                "Erreur",
                error.message ||
                    "Une erreur s'est produite lors de l'envoi du fichier Excel. Veuillez contacter le support."
            );
        } finally {
            setIsUpdatingBPU(false);
        }
    };

    const getBPU = async (code_entreprise) => {
        setIsGettingBPU(true);
        try {
            const response = await fetch(
                `${INTELIA_API_URL}/v2/bpu/${code_entreprise}`,
                {
                    method: "GET",
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || "Erreur inconnue");
            }
            let data = await response.json();
            data = data.map((article, index) => ({id: index, label: article.description, value: article}));
            setBPU(data);
            console.log("Retrieved BPU data: ", data);
            return data;
        } catch (error) {
            setBPU([]);
            Alert(
                "Erreur",
                error.message ||
                    "Une erreur s'est produite lors de la récupération des données. Veuillez contacter le support."
            );
        } finally {
            setIsGettingBPU(false);
        }
    };

    return {
        bpu,
        setBPU,

        updateBPU,
        getBPU,
        
        isUpdatingBPU,
        isGettingBPU,
    };
}