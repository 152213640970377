import React, { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  ScrollShadow,
} from "@nextui-org/react";
import { Icon } from "@iconify/react";

import FileExplorerHeader from "./file-explorer-header";
import FileItem from "./file-list-item";
import { cn } from "../../../../utils/cn";

import JSZip from "jszip";

const FileListWindow = React.forwardRef(
  ({ selectedFile, deleteFile, ...props }, ref) => {

    return (
      <div ref={ref} {...props}>
        {selectedFile !== undefined && <div className="w-full h-full overflow-auto">
          <div className="w-full grid grid-cols-2 items-center">
          <Image
            src={selectedFile.url}
            className="min-w-48 min-h-48 rounded-xl w-full h-fit"
          />

          <div className="grid grid-cols-2 gap-y-2 py-4">
            <span>Nom</span>
            <span title={selectedFile.name}>{selectedFile.name}</span>

            <span>Nom du fichier</span>
            <span title={selectedFile.filename}>{selectedFile.filename}</span>
            
            <span>Fabricant</span>
            <span title={selectedFile.manufacturer}>{selectedFile.manufacturer}</span>
            
            <span>Test</span>
            <span title={selectedFile.test}>{selectedFile.test}</span>
            
            <span>Catalogue Luminaire</span>
            <span title={selectedFile.lightCatalog}>{selectedFile.lightCatalog}</span>
            
            <span>Type de lampe</span>
            <span title={selectedFile.lampType}>{selectedFile.lampType}</span>
            
            <span>Catalogue Lampe</span>
            <span title={selectedFile.lampCatalog}>{selectedFile.lampCatalog}</span>
            
            <span>Flux lumineux total</span>
            <span title={`${selectedFile.totalLuminousFlux} lm`}>{selectedFile.totalLuminousFlux} lm</span>
            
            <span>Puissance</span>
            <span title={`${selectedFile.power} W`}>{selectedFile.power} W</span>
            
            <span>Nombre de lampes</span>
            <span title={selectedFile.lampStandardNumber}>{selectedFile.lampStandardNumber}</span>
            
            <span>Nombre de sources lumineuses</span>
            <span title={selectedFile.lampNumber}>{selectedFile.lampNumber}</span>
            
            <span>Température de couleur des lampes</span>
            <span title={selectedFile.temperature}>{selectedFile.temperature}</span>
            
            <span>Dimensions</span>
            {selectedFile.type === "LDT" && <span>{selectedFile.width} x {selectedFile.length} {selectedFile.height === undefined || selectedFile.height.replaceAll("0", "") === "" ? "" : `x ${selectedFile.height}`}</span>}
            {selectedFile.type === "IES" && <span>{selectedFile.width * 1000} x {selectedFile.length * 1000} {selectedFile.height === undefined || selectedFile.height === 0 ? "" : `x ${selectedFile.height * 1000}`}</span>}

            <Button className="col-span-2 lg:w-[50%] mx-auto" onPress={() => {deleteFile(selectedFile.blobPath)}}>Supprimer</Button>
          </div>
          </div>
        </div> }
      </div>
    );
  },
);

FileListWindow.displayName = "FileListWindow";

export default FileListWindow;
