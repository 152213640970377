import React, { useEffect, useMemo, useState } from 'react';
import styles from '../../../../components/polygon-editor/components/sideMenu/sideMenu.module.css';
import EditableTable from '../../../../components/table/editable-table';
import classNames from 'classnames';
import { Button, Popover, PopoverContent, PopoverTrigger, Spacer, Tooltip } from '@nextui-org/react';
import {Icon} from "@iconify/react";
import { ActualizeIcon } from "../../../../components/Icons";
import { useSelector } from 'react-redux';
import { updateLinears, updateSymbols } from '../../../../store/features/Comptage/configuration';
import { HexColorPicker } from "react-colorful";

export const Functions = {
  NONE: 0,
  POLY: 1,
  RECT: 2,
  DELETE: 3,
  DELETE_ALL: 4,
  LEGEND: 5,
};

export default function SideMenu({
  activeFunction,
  addLegend,
  extractLegend,
  addPolygonalSymbol,
  addRectangularSymbol,
  deleteSymbol,
  deleteAllSymbols,
  zoomItem,
  deleteItem,
  changeSymbolColor,
  page,
  addLinear,
  removeLinear,
  changeLinearColor,
  checkChangeName,
  setCheckChangeName,
  checkSegmentationReady
}) {

  // State to manage whether the menu is opened or closed
  const [openedSymbols, setOpenedSymbols] = useState(true);
  const [openedLinears, setOpenedLinears] = useState(false);
  
  const symbols = useSelector((state) => state.Comptage.Configuration.symbols.filter(x =>x.page === page));
  const segmentationReady = useSelector((state) => state.Comptage.Configuration.pages[page-1]?.segmentation_ready);
  const [data, setData] = useState(symbols);
  
  const linears = useSelector((state) => state.Comptage.Configuration.linears);
  const [dataLinears, setDataLinears] = useState(linears);
  
  const [filters, setFilters] = useState([]);
  const [checkChangeColor, setCheckChangeColor] = useState(false);

  useEffect(() => {
    if (symbols.length !== data.length || checkChangeColor || checkChangeName) {
      setCheckChangeColor(false);
      setCheckChangeName(false);
      setData(symbols);
    }
  }, [symbols.length, checkChangeColor, checkChangeName]);

  useEffect(() => {
    if (linears.length !== dataLinears.length || checkChangeColor) {
      setCheckChangeColor(false);
      setDataLinears(linears);
    }
  }, [linears.length, checkChangeColor]);

  // Function to toggle the opened and visible states
  const toggleMenuSymbols = () => {
    setOpenedSymbols(!openedSymbols); // Toggle the opened state
    setOpenedLinears(false);
  };

  // Function to toggle the opened and visible states
  const toggleMenuLinears = () => {
    setOpenedLinears(!openedLinears); // Toggle the opened state
    setOpenedSymbols(false);
  };

  const columnsSymbols = useMemo(() => [{
    uid: "show",
    name: "",
    renderCell: (item) => {
      return (<Tooltip color='danger' content="Voir sur le PDF" placement='top'>
          <span className="cursor-pointer"><Icon icon="tabler:zoom" onClick={() => {
            zoomItem(item.id);
          }} /></span>
      </Tooltip>)
    }
  }, {
    uid: "name",
    name: "Nom",
    type: "text",
    isEditable : true
  }, {
    uid: "color",
    name: "",
    renderCell: (item) => {
      return (<Popover placement='bottom' onClose={() => {
        setCheckChangeColor(true);
      }}>
        <PopoverTrigger>
          <Button isIconOnly className='shadow-lg rounded-full' style={{backgroundColor: item["color"]}}></Button>
        </PopoverTrigger>
        <PopoverContent>
          <HexColorPicker color={item["color"]} onChange={(newColor) => {
            changeSymbolColor(item.id, newColor);
          }} />
        </PopoverContent>
      </Popover>);
    }
  }, {
    uid: "delete",
    name: "",
    renderCell: (item) => {
      return (<Tooltip color='danger' content="Supprimer" placement='top'>
          <span className="cursor-pointer"><Icon onClick={()=>{
            deleteItem(item.id);
          }} icon="radix-icons:cross-2" color='danger'/></span>
      </Tooltip>)
    }
  }], []);

  
  const columnsLinears = useMemo(() => [{
    uid: "name",
    name: "Nom",
    type: "text",
    isEditable : true
  }, {
    uid: "color",
    name: "",
    renderCell: (item) => {
      return (<Popover placement='bottom' onClose={() => {
        setCheckChangeColor(true);
      }}>
        <PopoverTrigger>
          <Button isIconOnly className='shadow-lg rounded-full' style={{backgroundColor: item["color"]}}></Button>
        </PopoverTrigger>
        <PopoverContent>
          <HexColorPicker color={item["color"]} onChange={(newColor) => {
            changeLinearColor(item.id, newColor);
          }} />
        </PopoverContent>
      </Popover>);
    }
  }, {
    uid: "delete",
    name: "",
    renderCell: (item) => {
      return (<Tooltip color='danger' content="Supprimer" placement='top'>
          <span className="cursor-pointer"><Icon onClick={()=>{
            removeLinear(item.id);
          }} icon="radix-icons:cross-2" color='danger'/></span>
      </Tooltip>)
    }
  }], []);

  return (
    <div className={classNames(styles.parent, openedSymbols || openedLinears ? styles.opened : styles.closed, "h-full")}>
      <div className='flex flex-row h-full'>
        {/* Button to toggle the menu */}
        <div className={styles.openButton}>
          <div className={`${styles.arrowParent} bg-white/80`} style={{
            padding: "20px 0px 20px 5px",
            borderRadius: "10px 0px 0px 10px",
            marginTop: "50px",
            writingMode: "tb",
            width: "100%",
            backgroundColor: "#f2f2f2"
          }} onClick={toggleMenuSymbols}>
            {/* Arrow icon to indicate open/close state */}
            <label className={`rotate-180 text-center cursor-pointer block ${openedSymbols ? "font-bold" : ""}`}>Symboles</label>
          </div>
          <div className={`${styles.arrowParent} bg-white/80`} style={{
            padding: "20px 0px 20px 5px",
            borderRadius: "10px 0px 0px 10px",
            marginTop: "10px",
            writingMode: "tb",
            width: "100%",
            backgroundColor: "#f2f2f2"
          }} onClick={toggleMenuLinears}>
            {/* Arrow icon to indicate open/close state */}
            <label className={`rotate-180 text-center cursor-pointer block ${openedLinears ? "font-bold" : ""}`}>Linéaires</label>
          </div>
        </div>

        {/* Conditionally render menu contents based on the visible state */}
        <div style={{
          width:    openedSymbols || openedLinears ? "300px" : "",
          padding:  openedSymbols || openedLinears ? "" : "0",
          minWidth: openedSymbols || openedLinears ? "300px" : "",
          overflowY: "hidden",
          backgroundColor: "#f2f2f2"
        }} className='w-0 min-w-0 transition-all duration-700 linear h-full px-4 py-3'>
          <div className='flex flex-col h-full' style={{
            display: openedSymbols ? "" : "none"
          }}>
            <div>
              <div className={`${styles.header} h4`}>Légende automatique</div>
              {(!segmentationReady || segmentationReady === "ongoing" ||  segmentationReady === "not_started") && <div className="flex flex-row items-center my-2">
                  <div className={`${styles.header} h5 subtitle pb-2 !text-red-600`}>La fonction de légende automatique sera disponible dans quelques minutes.</div>
                  <Button isIconOnly onClick={() => checkSegmentationReady({alert : true})} className='glass validation'>
                    <ActualizeIcon />
                  </Button>
                </div>}
              {segmentationReady === "failed" && <div className={`${styles.header} h5 subtitle !text-red-600`}>La fonction de légende automatique n'est pas disponible pour ce plan.</div>}
              <div className='flex flex-row mb-1 gap-2 w-full'>
                <Button className='w-full shadow-lg glass validation' onClick={addLegend} isDisabled={segmentationReady !== "ready"}>
                  <Icon icon="geo:turf-extent" className='scale-[1.5] flex-5' />
                  <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Détourer légende</span>
                </Button>
                <Button className='w-full shadow-lg glass validation' onClick={extractLegend} isDisabled={segmentationReady !== "ready"}>
                  <Icon icon="fluent:search-sparkle-16-filled" className='scale-[1.5] flex-5' />
                  <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Extraire légende IA</span>
                </Button>
              </div>
              <Spacer y={8}/>

              <div className={`${styles.header} h4`}>Ajouter des symboles</div>
              <div className='flex flex-row mb-1 gap-2 w-full'>
                <Button className='shadow-lg' onClick={addPolygonalSymbol} color={activeFunction === Functions.POLY ? "danger" : "default"}>
                  <Icon icon="geo:turf-explode" className='scale-150' />
                  <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Polygonal</span>
                </Button>
                <Button className='shadow-lg' onClick={addRectangularSymbol} color={activeFunction === Functions.RECT ? "danger" : "default"}>
                  <Icon icon="geo:turf-extent" className='scale-150' />
                  <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Rectangulaire</span>
                </Button>
              </div>
              <Spacer y={4} />
              <div className='flex flex-row mb-1 gap-2'>
                <Button className='shadow-lg' onClick={deleteSymbol} color={activeFunction === Functions.DELETE ? "danger" : "default"}>
                  <Icon icon="jam:rubber" className='scale-[1.5] flex-5' />
                  <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Supprimer</span>
                </Button>
                <Button className='shadow-lg' onClick={deleteAllSymbols} color={activeFunction === Functions.DELETE_ALL ? "danger" : "default"}>
                  <Icon icon="material-symbols:delete-outline" className='scale-[1.5] flex-5' />
                  <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Supprimer tout</span>
                </Button>
              </div>

            </div>
            <Spacer y={8}/>
            <div className='flex flex-col overflow-hidden'>
              <div className={`${styles.header} h4`}>Symboles</div>
              {symbols.filter(x=>!x.hasOwnProperty("src") || x.src === undefined || x.src === "").length > 0 && <div className={`${styles.header} h5 subtitle !text-red-600`}>N'oubliez pas de sauvegarder pour pouvoir accéder au dénombrement !</div>}
              <div className='p-1 rounded-lg min-h-[300px] overflow-auto'>
                <EditableTable
                  data={data}
                  setData={setData}
                  filters={filters}
                  columns={columnsSymbols}
                  showTopRibbon={false}
                  pagination={false}
                  removeWrapper
                  reduxTableSaveAction={updateSymbols}
                  className="overflow-y-auto"
                  tableBackground=""
                />
              </div>
              <Spacer y={16}/>
            </div>
          </div>

          <div className='flex flex-col h-full' style={{
            display: openedLinears ? "" : "none"
          }}>
            <div>
              <div className={`${styles.header} h4`}>Ajouter des linéaires</div>
              <Button className='shadow-lg w-full' onClick={addLinear}><Icon icon="teenyicons:curved-connector-solid" className='scale-150' />Ajouter</Button>
            </div>
            <Spacer y={4}/>
            <div className='flex flex-col overflow-hidden'>
              <div className={`${styles.header} h4`}>Linéaires</div>
              <div className='p-1 rounded-lg min-h-[300px] overflow-auto'>
                <EditableTable
                  data={dataLinears}
                  setData={setDataLinears}
                  filters={filters}
                  columns={columnsLinears}
                  showTopRibbon={false}
                  pagination={false}
                  removeWrapper
                  reduxTableSaveAction={updateLinears}
                  className="overflow-y-auto"
                  tableBackground=""
                />
              </div>
              <Spacer y={16}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
