
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  imagePreview: null,
  layers: [{ id: 1, name: "0", objects: [], visible: true, grouped: false }],
  activeLayer: 1,
  imageHeight: 0,
  imageWidth: 0,
  imageType: 'png',
  requestUngroupActiveGroup: true,
  calibration: []
};

const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    setImagePreview(state, action) {
      state.imagePreview = action.payload;
    },
    setImageHeight(state, action) {
      state.imageHeight = action.payload;
    },
    setImageWidth(state, action) {
      state.imageWidth = action.payload;
    },
    setImageType(state, action) {
      state.imageType = action.payload;
    },

    setLayers(state, action) {
      state.layers = action.payload;
      if (!action.payload.find((l) => l.id === state.activeLayer)) {
        if (state.layers.length) {
          state.activeLayer = state.layers[0].id;
        } else {
          state.activeLayer = null;
        }
      }
    },
    addLayer(state, action) {
      let name = action.payload;

      let layer = state.layers.find(x=>x.name === name);
      if (layer && name !== undefined) {
        state.activeLayer = layer.id;
        return;
      }

      let isBackground = name.toLowerCase().includes("background");
      let isForeground = name.toLowerCase().includes("foreground");
      let isViewportFixed = name.toLowerCase().includes("vpf");

      let maxLayer = state.layers.reduce((a, b) => Math.max(a, b.id), -Infinity);
      if (state.layers.length === 0) maxLayer = 0;
      
      let id = uuidv4();
      state.layers = [...state.layers, { id: maxLayer + 1, layerId: id, name: name === undefined ? `Layer ${maxLayer + 1}` : name, objects: [], visible: true, grouped: false, isForeground: isForeground, isBackground: isBackground, isViewportFixed: isViewportFixed }];
      state.activeLayer = state.layers[state.layers.length - 1].id;
    },
    removeLayer(state, action) {
      state.layers = [...state.layers.filter((l) => l.id !== action.payload)];
      if (!state.layers.find((l) => l.id === state.activeLayer)) {
        if (state.layers.length) {
          state.activeLayer = state.layers[0].id;
        } else {
          state.activeLayer = null;
        }
      }
    },
    moveLayerUp(state, action) {
      let index = action.payload;
      if (index > 0) {
        let newLayers = [...state.layers];
        let temp = newLayers[index - 1];
        newLayers[index - 1] = newLayers[index];
        newLayers[index] = temp;
        state.layers = newLayers;
      }
    },
    moveLayerDown(state, action) {
      let index = action.payload;
      if (index < state.layers.length - 1) {
        let newLayers = [...state.layers];
        let temp = newLayers[index + 1];
        newLayers[index + 1] = newLayers[index];
        newLayers[index] = temp;
        state.layers = newLayers;
      }
    },
    setActiveLayer(state, action) {
      const layer = state.layers.find((l) => l.id === action.payload);
      if (layer && !layer.isBackground && !layer.isForeground) {
        state.activeLayer = action.payload;
      }
    },
    setRequestUngroupActiveGroup(state, action) {
      state.requestUngroupActiveGroup = action.payload;
    },
    addObject(state, action) {
      let activeLayer = state.layers.find((l) => l.id === state.activeLayer);
      if (activeLayer) {
        activeLayer.objects = [...activeLayer.objects, action.payload];
      }
    },
    removeObject(state, action) {
      let objectId = action.payload;
      for (let layer of state.layers) {
        layer.objects = [...layer.objects].filter(x => x.id !== objectId);
      }
    },
    addObjectInLayer(state, action) {
      const { layerId, object } = action.payload;
      let layerIndex = state.layers.findIndex((l) => l.layerId === layerId);
      if (layerIndex !== -1) {
        let updatedLayer = {
          ...state.layers[layerIndex],
          objects: [...state.layers[layerIndex].objects, object], // Immutable update
        };
        state.layers = [
          ...state.layers.slice(0, layerIndex),
          updatedLayer,
          ...state.layers.slice(layerIndex + 1),
        ]
      }
    },
    changeLayerVisibility(state, action) {
      let layer = state.layers.find((l) => l.id === action.payload.id);
      if (layer) {
        layer.visible = action.payload.visible;
      }
    },
    changeLayerGroup(state, action) {
      let layer = state.layers.find((lay) => lay.id === action.payload.layerId);
      if (layer) {
        layer.grouped = action.payload.grouped;
        layer.objects = layer.objects.filter((object) => !action.payload.removed.find((re) => re === object));
        layer.objects = [].concat(layer.objects, action.payload.added);
      }
    },

    setCalibration: (state, action) => {
      state.calibration = action.payload ?? [];
    },

    setPagePointsCalibration: (state, action) => {
      if (state.calibration === undefined) {
        state.calibration = [];
      }
      const { id, page, points } = action.payload;
      let p = state.calibration?.find(x => x.page === page);
      let editor_distance = ((points[0].x - points[1].x)**2+(points[0].y - points[1].y)**2)**.5;

      if (p) {
        state.calibration = [...state.calibration.filter(x=>x.page !== page), {...p, id: id, points: points, scale: p.real_distance / editor_distance}];
      } else {
        state.calibration = [...state.calibration, {
          page: page,
          id: id,
          points: points,
          real_distance: 1,
          scale: 1 / editor_distance
        }];
      }
    },

    setPageRealDistanceCalibration: (state, action) => {
      if (state.calibration === undefined) {
        state.calibration = [];
      }
      const { page, realDistance } = action.payload;
      let p = state.calibration?.find(x => x.page === page);

      if (p) {
        let editor_distance = p.points.length >= 2 ? ((p.points[0].x - p.points[1].x)**2+(p.points[0].y - p.points[1].y)**2)**.5 : realDistance;
        state.calibration = [...state.calibration.filter(x=>x.page !== page), {...p, real_distance: realDistance, scale: realDistance / editor_distance}];
      } else {
        state.calibration = [...state.calibration, {
          page: page,
          points: [],
          real_distance: realDistance,
          scale: 1
        }];
      }
    },

    setAllPagesScale: (state, action) => {
      if (state.calibration === undefined) {
        state.calibration = [];
      }
      const { page, pagesNumbers } = action.payload;
      let currentPage = state.calibration?.find(x => x.page === page);
      let scale = currentPage ? currentPage.scale : 1;
      let points = currentPage ? currentPage.points : [];
      let real_distance = currentPage ? currentPage.real_distance : 1;
        for (const p of pagesNumbers) {
          let newPage = state.calibration?.find(x => x.page === p);
          if (newPage) {
            state.calibration = [...state.calibration.filter(x=>x.page !== p), {...newPage, scale: scale}];
          } else {
            state.calibration = [...state.calibration, {
              page: p,
              points: points,
              real_distance: real_distance,
              scale: scale
            }];
          };
        };
    },

  }
});

export const {
  setImagePreview,
  setImageHeight,
  setImageWidth,
  setImageType,
  setLayers,
  setActiveLayer,
  addObject,
  addObjectInLayer,
  removeObject,
  changeLayerVisibility,
  addLayer,
  removeLayer,
  moveLayerUp,
  moveLayerDown,
  changeLayerGroup,
  setRequestUngroupActiveGroup,
  setCalibration,
  setPagePointsCalibration,
  setPageRealDistanceCalibration,
  setAllPagesScale
} = imageSlice.actions;

export const selectImagePreview = (state) => state.image.imagePreview;
export const selectImageHeight = (state) => state.image.imageHeight;
export const selectImageType = (state) => state.image.imageType;
export const selectImageWidth = (state) => state.image.imageWidth;
export const selectLayers = (state) => state.image.layers;
export const selectActiveLayer = (state) => state.image.activeLayer;
export const selectRequestUngroupActiveGroup = (state) => state.image.requestUngroupActiveGroup;

export default imageSlice.reducer;