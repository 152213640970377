import React, { createContext, useContext } from 'react';
import useDrawShapeHook from '../hooks/draw';

const DrawContext = createContext();

export const useDrawShape = () => {
  return useContext(DrawContext);
};

export const DrawShapeProvider = ({ children }) => {
  const drawShapeHook = useDrawShapeHook();

  return (
    <DrawContext.Provider value={drawShapeHook}>
      {children}
    </DrawContext.Provider>
  );
};
